import React, { useEffect, useState } from 'react';
// import './blog_review_1.css';
import { useLocation, useNavigate } from 'react-router-dom';
import blog_img from '../../assets/images/degree.jpg';


export const Blog_review_4 = () => {
    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);

    return (
        <>
            <div className='main_Blog_review_1_div'>
                <div className='inner_Blog_review_1_div'>

                    <div className='main_show_blog_review_page'>
                        <div className='inner_show_blog_review_page1'>

                            <div className='review_blog_page_heading'>
                                <h1>What is more important: Learning skills or getting a degree?</h1>
                            </div>

                            <p>
                                The debate surrounding the value of skills compared to a degree is a significant issue in the United States. While approximately two-thirds of job openings in the country require a degree, around one-third call for vocational training and skill certification. This situation has led to a pressing employment crisis, with over 8 million jobs that demand relevant credentials remaining unfilled across the US. The primary reason for this substantial gap between job vacancies and qualified applicants is that employers do not adequately acknowledge credentials acquired solely through skills, training, and experience, as opposed to those obtained through a degree.
                            </p>

                            <div className='review_blog_page_heading'>
                                <img src="https://res.cloudinary.com/dabkyosdo/image/upload/v1707044287/web_assets/degree.jpeg_0549bf04-5297-4953-958a-bfa21450e798.jpg" alt="Image 2" className='blog_image' />
                            </div>
                            <p>
                                However, perceptions are rapidly changing in today's fast-paced world, particularly in the era of disruptive technology. A degree is no longer seen as an obligatory prerequisite for success in many of today's job opportunities. Increasingly, companies like IBM, Google, and Tesla are opting to hire applicants who possess the necessary skills, credentials, training, and experience. It is predicted that in the post-pandemic future, job seekers with the right credentials will experience greater acceptance in the job market compared to individuals who solely hold a four-year degree.
                            </p>

                            <p>
                                In the current job market, numerous prominent employers have already relaxed their educational requirements due to historically tight labor conditions. As a result, nearly half of all job openings nationwide now consider candidates without a bachelor's degree, as reported by labor market data firm Burning Glass Technologies, which tracks millions of job postings. This trend has remained consistent over the past five years, and it is projected that an increasing number of employers will prioritize candidates with skills and training over recent college graduates in the post-pandemic economy.
                            </p>

                            <p>
                                A study conducted by the National Bureau of Economic Research (NBER) supports the notion that acquiring skills through on-the-job training is equally valuable as obtaining a bachelor's degree when it comes to bridging the skills gap.
                            </p>

                            <p>
                                Consequently, in the United States and Western countries, the acquisition of skills coupled with work experience is swiftly emerging as the new employment currency and is considered just as valuable as a bachelor's degree.
                            </p>


                            <p>
                                If you have taken any course in an IT related field, you can have it evaluated by American Skills Evaluation Institute
                                <b>
                                    (
                                    <a href="https://skillsevaluation.org/">https://skillsevaluation.org/</a>
                                    ).
                                </b>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
