import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import { UserAuth } from '../../services/api/auth'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ErrorMessage(props: any) {
  return (
    <Text color={'red'} fontSize={12} mt={1}>
      {props.message}
    </Text>
  )
}

export default function Verification() {
  const [loginFailure, setLoginFailure] = React.useState<boolean>(false)
  const navigate = useNavigate();
  const email = localStorage.getItem('email')
  const toast_msg = localStorage.getItem('toast_msg')


  var msg = ''

  if (toast_msg === null) {
    msg = ''
  } else {
    msg = toast_msg
    toast(msg);
  }



  const onSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    setLoginFailure(false);

    var user_email = ''

    if (email === null) {
      user_email = ''
    } else {
      user_email = email
    }

    const body = {
      email: user_email,
      auth_code: values.verification_code,
    };


    try {
      const res = await UserAuth.verification_code(body);

      if (res.data.status === "200") {

        navigate('/New_password')
      }

    } catch (error) {
      console.error(error);
      setLoginFailure(true);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <>
      <ToastContainer />
      <div className='main_faq_page_div'>
        <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              <Text
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                maxW={'3xl'}
              >
                8 Digits Verification Code
              </Text>

              <Formik
                initialValues={{ verification_code: '' }}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl id="verification_code">
                      <FormLabel
                        color={'gray.600'}
                        maxW={'3xl'}
                        fontWeight={400}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Verification Code
                      </FormLabel>
                      <Input
                        type="text"
                        name="verification_code"
                        value={values.verification_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.verification_code && touched.verification_code && (
                      <ErrorMessage message={errors.verification_code} />
                    )}
                    <Stack spacing={6}>
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}
                      >
                      </Stack>
                      <Button
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                        rounded={'full'}
                        px={6}
                        colorScheme={'orange'}
                        bg={'#68c2e8'}
                        _hover={{ bg: '#0e1e40' }}
                      >
                        Verification
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image alt={'Login Image'} objectFit={'cover'} src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681567766-study.webp" />
          </Flex>
        </Stack>
      </div>
    </>
  )
}
