import React, { useEffect, useState, useRef } from 'react';
import './certificate.css';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo/logoRight.png';
import javid from '../../assets/images/javaid.png';
import imran from '../../assets/images/imran.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import { AxiosClient } from '../../services/client';
import back_end_base_url from '../../services/back_end_url';
import { StarIcon } from '@chakra-ui/icons'
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";


export const Certificate = () => {

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);

    const certificateRef = useRef(null);
    const token = localStorage.getItem('access-token')
    const resume = localStorage.getItem('resume')

    const [registration_no, setregistration_no] = useState('')
    const [cert_issue_date, setcert_issue_date] = useState('')
    const [username, setusername] = useState('')
    const [dob, setdob] = useState('')
    const [complexity, setcomplexity] = useState('')
    const [course_name, setcourse_name] = useState('')
    const [institute_name, setinstitute_name] = useState('')
    const [institute_country, setinstitute_country] = useState('')
    const [started_date, setstarted_date] = useState('')
    const [ending_date, setending_date] = useState('')

    const certificate_data_function = async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            var badgeId = queryParams.get('badge_Id');

            const response = await axios.get(back_end_base_url + `certificate/download/?badge_Id=${badgeId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const data = response.data.msg;

                setregistration_no(`${badgeId}`);
                setcert_issue_date(data.cert_issue_date);
                setusername(data.username);
                setdob(data.dob);
                setcomplexity(data.complexity);
                setcourse_name(data.course_name);
                setinstitute_name(data.institute_name);
                setinstitute_country(data.institute_country);
                setstarted_date(data.started_date);
                setending_date(data.ending_date);

                setTimeout(() => {
                    generatePDF();
                    // printDocument();
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // useEffect(() => {
    //     certificate_data_function();
    // }, [])

    const generatePDF = () => {
        if (certificateRef.current) {
            html2canvas(certificateRef.current, { scale: 2, logging: true, scrollY: -window.scrollY }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');

                // Create a PDF in landscape orientation
                const pdf = new jsPDF({
                    orientation: "landscape", // Set orientation to landscape
                    unit: "mm",
                    format: "a4",
                });

                const imgWidth = 297; // A4 width in mm for landscape
                const imgHeight = 180;

                // Calculate the center position to place the image on the A4 page
                const x = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
                const y = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

                pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
                pdf.save('Certificate.pdf');
            });
        }
    };


    const navigate = useNavigate();

    useEffect(() => {
        validate_session_function();
    }, [])

    const validate_session_function = async () => {

        if (token === null) {

            localStorage.clear()
            navigate('/');

        } else {
            // if (resume === null) {
            //     navigate('/settings');
            // } else {


            try {
                const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token)
                if (res.data.status === "200") {
                    certificate_data_function();
                }
            } catch (error) {
                localStorage.clear()
                navigate('/');
            }
            // }

        }

    }

    const current = new Date();
    const date_options = current.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

    return (
        <>
            <div className='main_certificate_section_div' ref={certificateRef} id="divToPrint" >

                <div className='inner_certificate_section_div1'>
                    <div className='inner_certificate_section_div1_web_logo_div'>
                        <img src={logo} alt="" />
                    </div>
                    <div className='skillsevaluation_url_div'>
                        www.skillsevaluation.org
                    </div>
                    <div className='main_registration_and_date_number_div'>
                        <div>
                            <label htmlFor="">Registration No:</label> <span><b> {registration_no} </b></span>
                        </div>
                        <div>
                            <label htmlFor="">Date:</label><span><b> {cert_issue_date} </b></span>
                        </div>
                    </div>
                    <div className='inner_certificate_section_div1_certificate_heading_div blue_text'>
                        Certificate of Evaluation
                    </div>
                    <div className='inner_certificate_section_div1_certificate_description_div blue_text'>
                        {/* sm_text */}
                        This is to certify that <span className=''> {username}  (DOB: {dob}) </span> has attained the level of <span className=''> {complexity} </span> in <span className=''> {course_name}</span>. This evaluation is based on certification from <span className=''> {institute_name}</span>, <span className=''> {institute_country} </span>completed during the time period from <span className=''> {started_date} to {ending_date}</span>.
                    </div>
                    <div className='super_main_show_star_div'>
                        <div className='text'>
                            <div>
                                Levels
                            </div>
                            {/* <hr /> */}
                        </div>
                        <div className='main_show_star_div'>
                            <div className='inner_show_star_div1'>
                                <div> Beginner </div>
                                <div> Novice </div>
                                <div> Intermediate </div>
                                <div> Advance </div>
                                <div> Expert </div>
                            </div>
                        </div>
                    </div>
                    <div className='inner_certificate_section_div1_certificate_seal_and_signature_div'>
                        <div className='main_signature_div'>

                            <div className='inner_main_signature_div'>
                                <div className='inner_signature_div1'>
                                    <img src={javid} alt="" />
                                </div>
                                <div className='signature_heading'>President</div>
                            </div>

                            <div className='inner_main_signature_div'>
                                <div className='inner_signature_div2'>
                                    <img src={imran} alt="" />
                                </div>
                                <div className='signature_heading'>Executive Director</div>
                            </div>

                        </div>
                        <div className='main_seal_div blue_text'></div>
                    </div>
                    <div className='inner_certificate_section_div1_certificate_footer_div'>
                        ASEI makes no representations as to the authenticity of the information provided by the candidate
                    </div>
                </div>

            </div>
        </>
    );
};
