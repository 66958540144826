import React, { useEffect, useState } from 'react';
// import './blog_review_1.css';
import { useLocation, useNavigate } from 'react-router-dom';



export const Blog_review_2 = () => {

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);
    return (
        <>
            <div className='main_Blog_review_1_div'>
                <div className='inner_Blog_review_1_div'>

                    <div className='main_show_blog_review_page'>
                        <div className='inner_show_blog_review_page1'>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h1>Top 10 In-Demand Skills You Can Learn Online</h1>
                                    </div>

                                    <p>If you want to boost your resume and stand out from the crowd, online learning is a great option. You can access a variety of courses and programs that teach you valuable skills for the modern workplace. Here are some of the most in-demand skills that you can learn online:</p>

                                </div>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712680790327-blog.jpg" alt="Image 1" />
                                    </div>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681489181-programming evolution.jpg" alt="Image 1" />
                                    </div>
                                </div>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            1- Coding and Programming:
                                        </h2>
                                    </div>

                                    <p>
                                        Whether you want to create websites, apps, games, or software, coding and programming are essential skills for today's digital world. You can learn popular languages like Python, Java, C#, HTML, CSS, and JavaScript from online platforms like Codecademy, Coursera, Udemy, and edX.
                                    </p>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            2- Graphic Design:
                                        </h2>
                                    </div>

                                    <p>
                                        Graphic design is the art of creating visual content that communicates a message or an idea. You can use graphic design skills to create logos, flyers, posters, brochures, infographics, social media graphics, and more. You can learn graphic design tools like Adobe Photoshop, Illustrator, and InDesign from online courses like Skillshare, Lynda, and Udemy.
                                    </p>
                                </div>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://res.cloudinary.com/dabkyosdo/image/upload/v1707041055/web_assets/blog_two.jpeg_3530985c-9c5b-4633-8f94-cdd53f836edb.jpg" alt="Image 1" />
                                    </div>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681092242-Digital Marketing.jpg" alt="Image 1" />
                                    </div>
                                </div>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            3- Digital Marketing:
                                        </h2>
                                    </div>
                                    <p>
                                        Digital marketing is the practice of promoting products or services using online channels like websites, social media, email, search engines, and ads. You can learn digital marketing skills like SEO, SEM, SMM, email marketing, content marketing, and analytics from online courses like Google Digital Garage, HubSpot Academy, Udemy, and Coursera.
                                    </p>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            4- Data Analysis:
                                        </h2>
                                    </div>
                                    <p>
                                        Data analysis is the process of collecting, organizing, and interpreting data to find patterns, trends, and insights. You can use data analysis skills to make better decisions and solve problems in various fields like business, finance, health care, education, and science. You can learn data analysis tools like Excel, SQL, R, Python, Tableau, and Power BI from online courses like DataCamp, Udemy, Coursera, and edX.
                                    </p>
                                </div>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681011304-Data Analysis.jpg" alt="Image 1" />
                                    </div>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681527508-Project Management.png" alt="Image 1" />
                                    </div>
                                </div>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            5- Project Management:
                                        </h2>
                                    </div>
                                    <p>
                                        Project management is the skill of planning, executing, and controlling projects of different sizes and scopes. You can use project management skills to lead teams, manage resources, meet deadlines, and deliver quality results. You can learn project management methodologies like Agile, Scrum, Kanban, Waterfall, and PRINCE2 from online courses like Udemy, Coursera, edX, and PMI.
                                    </p>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            6- Language Learning:
                                        </h2>
                                    </div>
                                    <p>
                                        Language learning is the skill of acquiring a new language or improving your existing language skills. You can use language learning skills to communicate with people from different cultures, travel to new places, and expand your horizons.
                                        <br />
                                        You can learn languages like Spanish, French, German, Chinese, Japanese, and more from online platforms like Duolingo, Babbel, Rosetta Stone, and Memrise.
                                    </p>
                                </div>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681372591-Language Learning.png" alt="Image 1" />
                                    </div>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681453110-Photography.jpg" alt="Image 1" />
                                    </div>
                                </div>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            7- Photography:
                                        </h2>
                                    </div>
                                    <p>
                                        Photography is the skill of capturing images that tell a story or express an emotion. You can use photography skills to document your life, share your perspective, and unleash your creativity.
                                        <br />
                                        You can learn photography techniques like composition, lighting, exposure, and editing from online courses like Udemy, Skillshare, Lynda, and CreativeLive.
                                    </p>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            8- Video Editing:
                                        </h2>
                                    </div>
                                    <p>
                                        Video editing is the skill of manipulating video footage to create engaging and professional-looking videos. You can use video editing skills to produce videos for YouTube, social media, marketing, education, and entertainment.
                                        <br />
                                        You can learn video editing tools like Adobe Premiere Pro, Final Cut Pro, DaVinci Resolve, and iMovie from online courses like Udemy, Skillshare, Lynda, and Coursera.
                                    </p>
                                </div>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681687883-Video Editing.jpg" alt="Image 1" />
                                    </div>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681136839-Financial Management.png" alt="Image 1" />
                                    </div>
                                </div>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            9- Financial Management:
                                        </h2>
                                    </div>
                                    <p>
                                        Financial management is the skill of managing your personal or business finances effectively. You can use financial management skills to budget, save, invest, and plan for your future goals.
                                        You can learn financial management topics like accounting, bookkeeping, taxes, financial statements,
                                        and financial planning from online courses like Udemy, Coursera, edX, and Khan Academy.
                                    </p>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>
                                            10- Personal Development:
                                        </h2>
                                    </div>
                                    <p>
                                        Personal development is the skill of improving yourself in various aspects of your life such as health, wellness, productivity, happiness, and relationships.
                                        <br />
                                        You can use personal development skills to achieve your potential and live a fulfilling life.
                                        <br />
                                        You can learn personal development topics like mindfulness, meditation, goal setting, time management, communication, and leadership from online courses like Udemy, Coursera, edX and Mindvalley.
                                    </p>
                                </div>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681417468-Personal Development.jpg" alt="Image 1" />
                                    </div>
                                </div>
                            </div>

                            <p>
                                These are some of the top 10 in-demand skills that you can learn online. By investing in yourself and learning new skills you can enhance your career opportunities and personal growth. If you have taken any course in an IT related field, you can have it evaluated by American Skills Evaluation Institute
                                <b> (
                                    <a href="https://skillsevaluation.org/">https://skillsevaluation.org/</a>
                                    ).
                                </b>
                            </p>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
