import { Container, Flex, Stack, Text, Box, Image } from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from "react";
import Hero from './hero'
import CounterSection from '../CounterSection'
import CaptionCarousel from '../Couseral'
import './style.css';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import question_mark_1 from "../../assets/images/question_mark_2.gif";
import SKILL_TESTING_SERVICES from "../../assets/images/SKILL-TESTING-SERVICES.jpg";
import ENDORSED_AND_AFFILIATED_MEMBERS from "../../assets/images/ENDORSED-AND-AFFILIATED-MEMBERS.jpg";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

// import videoUrl from '../../assets/home_page_video/high_KWC32SJ.mp4';

interface FeatureProps {
  image: string
  alternate: boolean
  details: {
    title: string
    body: string
  }
}
const Feature = (props: FeatureProps) => (
  <Flex w="100%" direction={{ base: 'column-reverse', md: 'row' }} gap={30}>
    {props.alternate ? (
      <React.Fragment>
        <Box w={{ md: '100%' }}>
          <Image src={props.image} alt="The team" />
        </Box>
        <Container mt={20}>
          <Text
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
            maxW={'3xl'}
          >
            {props.details.title}
          </Text>
          <Text
            color={'gray.500'}
            maxW={'3xl'}
            fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
          >
            {props.details.body}
          </Text>
        </Container>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Container mt={20}>
          <Text
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
            maxW={'3xl'}
          >
            {props.details.title}
          </Text>
          <Text
            color={'gray.500'}
            maxW={'3xl'}
            fontSize={{ base: 'md', sm: 'md', md: 'lg' }}
          >
            {props.details.body}
          </Text>
        </Container>
        <Box w={{ md: '100%' }}>
          <Image src={props.image} alt="The team" />
        </Box>
      </React.Fragment>
    )}
  </Flex>
)

export const Landing = (props: any) => {


  // const navigate = useNavigate();


  // const pathnamel = localStorage.getItem('pathname');

  // React.useEffect(() => {
  //   if (pathnamel === '/Account-Activate/') {
  //     navigate('/dashboard')
  //   } else {
  //   }
  // }, [pathnamel])


  const videoRef = useRef<HTMLVideoElement>(null);
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setPlayVideo(true);
    }, 1000); // 10 seconds in milliseconds

    return () => clearTimeout(timerId); // Clear the timeout on component unmount
  }, []); // Empty dependency array ensures the effect runs only once on mount


  useEffect(() => {
    if (playVideo && videoRef.current) {
      videoRef.current.currentTime = 7;
    }
  }, [playVideo]);



  const testimonials_slider_div = [
    <div className='testimonials_main_card_div' key={1}>
      <video loop controls>
        <source src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681600144-testimonial_video1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  ];


  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const goToPreviousText = () => {
    if (currentTextIndex > 0) {
      setCurrentTextIndex(currentTextIndex - 1);
    }
  };

  const goToNextText = () => {
    if (currentTextIndex < testimonials_slider_div.length - 1) {
      setCurrentTextIndex(currentTextIndex + 1);
    } else {
      setCurrentTextIndex(0);
    }
  };


  return (
    <div className="main_home_page_section">
      <React.Fragment >
        {/* <Hero auth={props.auth} /> */}

        <div className='main_home_banner_div'>
          <div className='inner_home_banner_div'>
            <div className='heading_home_banner_div'>
              Providing Skill and Credentials Evaluation and Testing Globally
            </div>
            <div className='sub_heading_home_banner_div'>
              Receive an Accredited and Recognized Certificate Instantly
            </div>
            <div className='contant_home_banner_div'>
              The <b> American Skills Evaluation Institute</b> (ASEI) is a globally recognized accredited institute and a US leader in credential evaluation, testing and institutional accreditation. We as an accredited institute ensure your certification, diploma, degree or expertise are recognized in the US education and job sectors, and around the world. Our rigorous AI based evaluation and testing process, led by experts and world class evaluators, provides detailed reports on your US equivalence, aiding your admission, credit transfer, freelancing opportunities  or job prospects. Contact us now to begin your journey. ASEI is based in Houston, USA.
            </div>
            <div className='button_home_banner_div'>
              <a href="/register">
                <button className="button"><span> Unlock Your Potential </span></button>
              </a>
            </div>
          </div>
        </div>

        <div className='home_inner_sections_div1'>
          <div className='inner_home_inner_sections_div1'>

            <div className='left_home_inner_sections_div1'>
              <div className='left_heading_home_inner_sections_div1'>
                Unlock Your Certificates, Diplomas and Degrees
              </div>

              <div className='left_contant_home_inner_sections_div1'>
                <b>American Skills Evaluation Institute</b> (ASEI) is currently offering evaluation of certificate courses, diplomas and degrees using our state-of-the-art proprietary AI/ML engine to verify the skill level, evaluate credentials as per US standards and provides an accredited equivalent globally recognized certificate to perspective students and professionals, and accreditation to programs, institutes, colleges and universities worldwide. ASEI is a world leader in credential evaluation and testing, and our proprietary algorithm verifies your institute and compares your courses, certificate, diploma or degree with industry leader institutions and top tier US universities to evaluate the level of the skills you have attained in your course. Skills and credentials are globally transferrable and recognizable, and job seekers, migrants, students and freelancers worldwide can capitalize on the ASEI recognition and evaluation of your skills to grow their careers and economic prospects.
              </div>

              <div className='left_button_home_inner_sections_div1'>
                <a href="/register">
                  <button className="button"><span> Unlock Your Potential </span></button>
                </a>
              </div>
            </div>

            <div className='right_home_inner_sections_div1'>
              <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681214764-free_evaluation.webp" alt="" />
            </div>

          </div>
        </div>

        <div className='home_inner_sections_div1' style={{ padding: "0" }}>
          <div className='main_certificates_div '>

            <div className='certificates_img_div'>
              <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712680927882-certificates_img.jpg" alt="" />
            </div>

          </div>
        </div>

        <div className='home_inner_sections_div2'>
          <div className='inner_home_inner_sections_div2'>


            <div className='right_home_inner_sections_div2'>
              <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681336191-it_skills.gif" alt="" />
            </div>


            <div className='left_home_inner_sections_div2'>
              <div className='left_heading_home_inner_sections_div2'>
                Skills in High Demand: USA, Canada, Europe, Middle East
              </div>

              <div className='left_contant_home_inner_sections_div2'>
                Skills are in high demand in USA, Canada, Europe and the Middle East, and many students and professionals complete short skill courses, diploma or degree from unrecognized institutes to substantially increase their chances of getting jobs and progress in their careers. These courses and programs are often from lesser-known institutions and online providers. By keeping abreast of the latest developments, <b>American Skills Evaluation Institute</b> (ASEI) offers credential evaluation services which will give you a clear assessment of how your skills stack up against in-demand skills in USA, Canada. Europe and the Middle East.
              </div>

              <div className='left_button_home_inner_sections_div2'>
                <a href="/register">
                  <button className="button"><span> Get Your Global Accredited Certificate Now </span></button>
                </a>
              </div>
            </div>

          </div>
        </div>

        <div className='home_how_it_works_main_section'>
          <div className='home_how_it_works_inner_section'>
            <div className='home_how_it_works_inner_div'>
              <div className='home_how_it_works_inner_div_heading'> HOW IT WORKS <img src={question_mark_1} alt="" /> </div>
              <div className='home_how_it_works_inner_div_sub_heading'>
                <p>
                  <b>American Skills Evaluation Institute</b> (ASEI) is the premier accredited provider of services for the evaluation as per US standards of skill certificates, diplomas and degrees obtained globally, or even for self-learners, for the purpose of seeking employment, admission, immigration and visas, licensing boards and certification. ASEI also accredits institutes who seek global recognition through a vigorous screening and evaluation process. ASEI, the world leader in professional development credential evaluation services is currently offering evaluation of all skill based courses, certificates, diplomas and degrees for a limited time period using our state-of-the-art proprietary AI/ML engine to verify the skill level, evaluate credentials as per US standards and provides an equivalent globally recognized certificate to perspective students and professionals. Our proprietary algorithm verifies your institute and compares your courses, certificates, diploma and degree with industry leader institutions and top tier US universities course offerings to evaluate the level of the skills you have attained in your program or as a self learner.
                </p>
              </div>
              <div className='home_how_it_works_inner_div_video'>
                <video ref={videoRef} controls>
                  <source src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681051015-demo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>

        <div className='home_inner_sections_div1'>
          <div className='inner_home_inner_sections_div1'>

            <div className='left_home_inner_sections_div1'>
              <div className='left_heading_home_inner_sections_div1'>
                We Assess Professional Development Credentials For  Skills
              </div>

              <div className='left_contant_home_inner_sections_div1'>
                Are you required to take additional professional development courses as part of your job? Did you know that almost every Professional Development course has some skills embedded in it. . These skills can be surfaced, identified and certified by <b>American Skills Evaluation Institute</b> (ASEI) through our state-of-the-art proprietary AI/ML algorithms and technology. We are a team of senior educators and skills trainers who understand the needs of the American and International markets and can help you with your professional development credential evaluation. Let us help you to progress your career globally.
              </div>

              <div className='left_button_home_inner_sections_div1'>
                <a href="/aboutus">
                  <button className="button"><span> Discover Our Story </span></button>
                </a>
              </div>
            </div>

            <div className='right_home_inner_sections_div1'>
              <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712680970413-Courses_Assessed_for_IT_Skills.png" alt="" />
            </div>

          </div>
        </div>

        <div className='home_inner_sections_div1'>
          <div className='inner_home_inner_sections_div1'>

            <div className='right_home_inner_sections_div2'>
              <img
                src={SKILL_TESTING_SERVICES}
                alt="" />
            </div>

            <div className='left_home_inner_sections_div1'>
              <div className='left_heading_home_inner_sections_div1'>
                SKILL TESTING SERVICES
              </div>

              <div className='left_contant_home_inner_sections_div1'>
                Have you acquired new skills from non-accredited providers, or through self-learning? <b>American Skills
                  Evaluation Institute</b> (ASEI) provides skill testing services for such individuals in collaboration with our
                affiliated education provider <a href="https://soqalearn.com/" target="_blank"><b>SOQALEARN</b></a>, which offers a multitude of high-tech
                state-of-the-art and currently in-demand IT courses. ASEI will provide you with our globally recognized and accredited certificate after we have tested you for your skills. Please contact us at <a href="mailto:support@skillsevaluation.org"><b>support@skillsevaluation.org</b></a> for details.
              </div>

              <div className='left_button_home_inner_sections_div1'>
                <a href="https://soqalearn.com/" target='_blank'>
                  <button className="button"><span> Explore Courses </span></button>
                </a>
              </div>

            </div>


          </div>
        </div>

        <div className='home_inner_sections_div1'>
          <div className='inner_home_inner_sections_div1'>

            <div className='left_home_inner_sections_div1'>
              <div className='left_heading_home_inner_sections_div1'>
                ACCREDITATION
              </div>

              <div className='left_contant_home_inner_sections_div1'>
                ASEI has an institutional evaluation and accreditation program for non-accredited institutes, through which your institute can be evaluated to receive accreditation and become <b>ACCREDITED</b> thereby receiving instant global recognition. Click <b><a href="/accreditation"><u>ACCREDITATION</u></a></b> on how your institute can become accredited by the ASEI
              </div>

            </div>

            <div className='right_home_inner_sections_div2'>
              <img
                src={ENDORSED_AND_AFFILIATED_MEMBERS}
                alt="" />
            </div>

          </div>
        </div>

        <CounterSection />

        <div className='main_testimonials_section'>
          <div className='main_testimonials_inner_div'>

            <div className='inner_testimonials_inner_div1'>
              <div className='inner_testimonials_slider_heading_div'>
                TESTIMONIALS
              </div>
            </div>

            <div className='inner_testimonials_inner_div2'>

              <div className='inner_testimonials_slider_div'>
                <div>
                  <div>
                    <div>{testimonials_slider_div[currentTextIndex]}</div>
                  </div>
                  <div className='slider_buttons_div'>
                    <button
                      onClick={goToPreviousText}
                      disabled={currentTextIndex === 0}
                    >
                      <FaChevronLeft />
                    </button>
                    <button
                      onClick={goToNextText}
                      disabled={currentTextIndex === testimonials_slider_div.length - 1}
                    >
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className='main_testimonials_dropdown_div'>
                <div className='main_testimonials_dropdown_inner_div'>

                  <Accordion allowToggle>

                    <AccordionItem>
                      <h2>
                        <AccordionButton bg={'#195389'} color={'white'} _hover={{
                          background: "white",
                          color: "teal.500",
                        }}>
                          <Box as="span" flex='1' textAlign='left'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.

                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptates magnam explicabo similique officia odit vitae dolor modi. Explicabo earum totam delectus, veniam magni quia voluptatum omnis reprehenderit quo. Debitis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptates magnam explicabo similique officia odit vitae dolor modi. Explicabo earum totam delectus, veniam magni quia voluptatum omnis reprehenderit quo. Debitis.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton bg={'#195389'} color={'white'} _hover={{
                          background: "white",
                          color: "teal.500",
                        }}>
                          <Box as="span" flex='1' textAlign='left'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.

                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptates magnam explicabo similique officia odit vitae dolor modi. Explicabo earum totam delectus, veniam magni quia voluptatum omnis reprehenderit quo. Debitis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptates magnam explicabo similique officia odit vitae dolor modi. Explicabo earum totam delectus, veniam magni quia voluptatum omnis reprehenderit quo. Debitis.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptates magnam explicabo similique officia odit vitae dolor modi. Explicabo earum totam delectus, veniam magni quia voluptatum omnis reprehenderit quo. Debitis.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton bg={'#195389'} color={'white'} _hover={{
                          background: "white",
                          color: "teal.500",
                        }}>
                          <Box as="span" flex='1' textAlign='left'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.

                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus voluptates magnam explicabo similique officia odit vitae dolor modi. Explicabo earum totam delectus, veniam magni quia voluptatum omnis reprehenderit quo. Debitis.
                      </AccordionPanel>
                    </AccordionItem>


                  </Accordion>

                </div>
              </div> */}

            </div>

          </div >
        </div>

        {/* <CaptionCarousel/> */}
        {/* <Container maxW={"100%"} p={"10vh"} backgroundImage={`url(${pattern})`} backgroundSize={"30vh"} backgroundRepeat="repeat-x"></Container> */}
      </React.Fragment >
    </div>
  )
}
