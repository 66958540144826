import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react'
import study from '../../assets/images/study.webp'
import * as React from 'react'
import { UserAuth } from '../../services/api/auth'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'


function ErrorMessage(props: any) {
  return (
    <Text color={'red'} fontSize={15} mt={1}>
      {props.message}
    </Text>
  )
}

export default function New_password() {

  const navigate = useNavigate();


  // Error Notification state and Function Start here 

  const [notification_text, setnotification_text] = React.useState('');
  const [error_notification_text, seterror_notification_text] = React.useState('');

  const [visible, setVisible] = React.useState(false);
  const [error_visible, seterror_Visible] = React.useState(false);

  const handleShowNotification = () => {
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, 3000); // Adjust the timeout value to match the notification duration
  };

  const errorhandleShowNotification = () => {
    seterror_Visible(true);

    setTimeout(() => {
      seterror_Visible(false);
    }, 3000); // Adjust the timeout value to match the notification duration
  };

  // Error Notification state and Function End here 



  const [failure, setFailure] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)
  const email = localStorage.getItem('email')

  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password should at least be 8 characters long.')
      .required('Password is a required field'),
    retypePassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password does not match.')
      .required('Please confirm your password'),
  })

  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    setFailure(false)

    var user_email = ''

    if (email === null) {
      user_email = ''
    } else {
      user_email = email
    }


    const body = {
      email: user_email,
      password: values.password,
    }

    UserAuth.new_password(body)
      .then((res) => {

        if (res.data.status === "200") {

          setnotification_text(res.data.msg);
          handleShowNotification();


          setTimeout(() => {
            navigate('/login');
          }, 1500);

        }

      })
      .catch((error) => {
        errorhandleShowNotification();
        seterror_notification_text(error.response.data.msg);
        setFailure(true)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }





  return (
    <>

      {/* notification popup box Start here */}
      <div className={`modal ${visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box">
            <p>
              {notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* notification popup box End here */}

      {/* Error notification popup box Start here */}
      <div className={`modal ${error_visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box" style={{ backgroundColor: '#980f0f' }}>
            <p>
              {error_notification_text}
              {failure && error && <ErrorMessage message={error} />}
            </p>
          </div>

        </div>
      </div>
      {/* Error notification popup box End here */}

      <div className='main_faq_page_div'>
        <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              {failure && error && <ErrorMessage message={error} />}
              <Text
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                maxW={'3xl'}
              >
                New Password
              </Text>
              <Formik
                initialValues={{
                  password: '',
                  retypePassword: '',
                }}
                validationSchema={RegisterSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl id="password" mb={1}>
                      <FormLabel
                        color={'gray.600'}
                        maxW={'3xl'}
                        fontWeight={400}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Password
                      </FormLabel>
                      <Input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.password && touched.password && (
                      <ErrorMessage message={errors.password} />
                    )}
                    <FormControl id="retypePassword" mb={1}>
                      <FormLabel
                        color={'gray.600'}
                        maxW={'3xl'}
                        fontWeight={400}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Confirm Password
                      </FormLabel>
                      <Input
                        type="password"
                        name="retypePassword"
                        value={values.retypePassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.retypePassword && touched.retypePassword && (
                      <ErrorMessage message={errors.retypePassword} />
                    )}
                    <Stack spacing={6}>
                      <Button
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                        rounded={'full'}
                        mt={5}
                        px={6}
                        colorScheme={'orange'}
                        bg={'#68c2e8'}
                        _hover={{ bg: '#0e1e40' }}
                      >
                        Reset Password
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </Flex>

        </Stack>
      </div>
    </>
  )
}
