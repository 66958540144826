import { AxiosClient } from "../client";

export class User {
  public static get() {
    return AxiosClient.get('user', false, true);
  }

  public static update(body: any) {
    return AxiosClient.patch('user', body, true);
  }
}