import React, { useState } from 'react';
import './contact_us.css';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import back_end_base_url from '../../services/back_end_url';

export const Contact_us = () => {

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);

    const [showloader, setshowloader] = React.useState(true)

    // Error Notification state and Function Start here 

    const [notification_text, setnotification_text] = useState('');
    const [error_notification_text, seterror_notification_text] = useState('');

    const [visible, setVisible] = useState(false);
    const [error_visible, seterror_Visible] = useState(false);

    const handleShowNotification = () => {
        setVisible(true);

        setTimeout(() => {
            setVisible(false);
        }, 3000); // Adjust the timeout value to match the notification duration
    };

    const errorhandleShowNotification = () => {
        seterror_Visible(true);

        setTimeout(() => {
            seterror_Visible(false);
        }, 3000); // Adjust the timeout value to match the notification duration
    };

    // Error Notification state and Function End here 


    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')


    const submit = async () => {

        if (name === '') {
            seterror_notification_text("Please Enter a Name");
            errorhandleShowNotification();
        } else if (email === '') {
            seterror_notification_text("Please Enter a Email");
            errorhandleShowNotification();
        } else if (message === '') {
            seterror_notification_text("Please Enter a Message");
            errorhandleShowNotification();
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            seterror_notification_text("Invalid email address");
            errorhandleShowNotification();
        } else {
            setshowloader(false)
            try {

                let body = {
                    name: name,
                    email: email,
                    message: message
                }

                const res = await axios.post(back_end_base_url + 'email/contact-us', body)

                if (res.data.status === "200") {

                    setnotification_text(res.data.msg)
                    handleShowNotification();

                    setname('');
                    setemail('');
                    setmessage('');

                    setshowloader(true)

                }

            } catch (error: any) {
                seterror_notification_text(error.response.data.msg);
                errorhandleShowNotification();
            }

        }

    }


    return (
        <>

            {showloader ? (
                <></>
            ) : (
                <div className="main_loader_div" id="main_loader_div">
                    <div className="main_loader_inner_div">
                        <span className="loader"></span>
                    </div>
                </div>
            )}

            {/* notification popup box Start here */}
            <div className={`modal ${visible ? 'show' : 'hide'}`} id="notification_popup_box">
                <div className="modal-content">

                    <div className="notification_box">
                        <p>
                            {notification_text}
                        </p>
                    </div>

                </div>
            </div>
            {/* notification popup box End here */}

            {/* Error notification popup box Start here */}
            <div className={`modal ${error_visible ? 'show' : 'hide'}`} id="notification_popup_box">
                <div className="modal-content">

                    <div className="notification_box" style={{ backgroundColor: '#980f0f' }}>
                        <p id='error_message'>
                            {error_notification_text}
                        </p>
                    </div>

                </div>
            </div>
            {/* Error notification popup box End here */}


            <div className='contact_us_main_div'>

                <div className='main_contact_us_div'>
                    <div className='inne_contact_us_div'>

                        <div className='contact_form_main_div'>
                            <div className='main_contact_form_div'>
                                <div className='inner_main_contact_form_div_hold_heading_and_form'>

                                    <div className='contant_form_header_div'>
                                        <div className='inner_contant_form_header_div1'> CONTACT US </div>
                                        {/* <div className='inner_contant_form_header_div2'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ipsam nemo eos aut cupiditate asperiores ratione quos deleniti ullam incidunt consequuntur, </div> */}
                                    </div>

                                    <div className='contant_form_body_div'>

                                        <div className='input_holrer'>
                                            <label className='custom-field two '>
                                                <input
                                                    type="url"
                                                    placeholder="&nbsp;"
                                                    value={name}
                                                    onChange={(e) => setname(e.target.value)}
                                                />
                                                <span className="placeholder">Your Name</span>
                                            </label>
                                        </div>

                                        <div className='input_holrer'>
                                            <label className='custom-field two '>
                                                <input
                                                    type="url"
                                                    placeholder="&nbsp;"
                                                    value={email}
                                                    onChange={(e) => setemail(e.target.value)}
                                                />
                                                <span className="placeholder">Your Email</span>
                                            </label>
                                        </div>

                                        <div className='input_holrer'>
                                            <label className='custom-field two '>
                                                <input
                                                    type="url"
                                                    placeholder="&nbsp;"
                                                    value={message}
                                                    onChange={(e) => setmessage(e.target.value)}
                                                />
                                                <span className="placeholder">Your Message</span>
                                            </label>
                                        </div>

                                    </div>

                                    <div className='contant_form_footer_div'>
                                        <button onClick={submit}> SUBMIT </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};
