import * as React from 'react'
import {
    Container,
    Input,
    Select,
    SelectField,
    InputGroup,
    InputLeftAddon,
    Button,
    Card,
    CardBody,
    Flex,
    Img,
    CardHeader,
    Heading,
    Text,
    Stack,
    Spinner,
    useToast,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Textarea,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import Mascot from '../../assets/logo/light-mascot.png'
import { Prediction } from '../../services/api/prediction'
import { Analytics } from '../../services/api/analytics'
import { Badge } from '../../services/api/badge'
import { useLocation, useNavigate } from 'react-router-dom'
import nav_menu from '../../assets/icons/nav_menu.png'
import back_end_base_url from '../../services/back_end_url';
import { UserAuth } from '../../services/api/auth';
import tick_arrow from '../../assets/logo/tick_arrow.jpg';
import bllet_point from '../../assets/icons/bullet-point.png';
import axios from 'axios';
import { string } from 'yup';
import { error } from 'console';


export const Upload_certificate = () => {
    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);
    const token = localStorage.getItem('access-token')
    const resume = localStorage.getItem('resume')
    // Error Notification state and Function Start here 

    const [notification_text, setnotification_text] = React.useState('');
    const [error_notification_text, seterror_notification_text] = React.useState('');

    const [visible, setVisible] = React.useState(false);
    const [error_visible, seterror_Visible] = React.useState(false);

    const handleShowNotification = () => {
        setVisible(true);

        setTimeout(() => {
            setVisible(false);
        }, 3000);
    };

    const errorhandleShowNotification = () => {
        seterror_Visible(true);

        setTimeout(() => {
            seterror_Visible(false);
        }, 3000);
    };

    // Error Notification state and Function End here 

    const [showloader, setshowloader] = React.useState(true)
    const [loading, setLoading] = React.useState<boolean>(false)


    const [file, setFile] = React.useState<File | null>(null);
    const [file_name_of_course, setfile_name_of_course] = React.useState('')
    const [file_started_date, setfile_started_date] = React.useState('')
    const [file_completed_date, setfile_completed_date] = React.useState('')
    const [file_total_hours_completed, setfile_total_hours_completed] = React.useState('')
    const [file_online_or_F2F, setfile_online_or_F2F] = React.useState('')
    const [file_name_of_institute, setfile_name_of_institute] = React.useState('')
    const [file_website_of_institute, setfile_website_of_institute] = React.useState('')
    const [file_country_of_institute, setfile_country_of_institute] = React.useState('')
    const [file_contact_person_at_institute_name, setfile_contact_person_at_institute_name] = React.useState('')
    const [file_contact_person_at_institute_email, setfile_contact_person_at_institute_email] = React.useState('')
    const [course_description, setcourse_description] = React.useState('')
    const [request_ID, setrequest_ID] = React.useState('')


    const [complexity, setcomplexity] = React.useState('')
    const [prediction, setprediction] = React.useState('')
    const [skills, setskills] = React.useState([]);



    const [file_input_name_of_course, setfile_input_name_of_course] = React.useState(true);
    const [file_input_started_date, setfile_input_started_date] = React.useState(true);
    const [file_input_completed_date, setfile_input_completed_date] = React.useState(true);
    const [file_input_total_hours_completed, setfile_input_total_hours_completed] = React.useState(true);
    const [file_input_online_or_F2F, setfile_input_online_or_F2F] = React.useState(true);
    const [file_input_name_of_institute, setfile_input_name_of_institute] = React.useState(true);
    const [file_input_website_of_institute, setfile_input_website_of_institute] = React.useState(true);
    const [file_input_country_of_institute, setfile_input_country_of_institute] = React.useState(true);
    const [file_input_contact_perso_at_institute_name, setfile_input_contact_perso_at_institute_name] = React.useState(true);
    const [file_input_contact_person_at_institute_email, setfile_input_contact_person_at_institute_email] = React.useState(true);
    const [input_course_description, setinput_course_description] = React.useState(true);
    const [file_input, setfile_input] = React.useState(false);



    React.useEffect(() => {
        fetch_detail_function();
    }, [])

    const fetch_detail_function = async () => {

        const queryParams = new URLSearchParams(window.location.search);
        var badgeId = queryParams.get('badge_Id');

        setshowloader(false);

        try {

            const res = await axios.get(
                back_end_base_url + `badge/fetch-detail?badge_id=${badgeId}`,
                {
                    headers: {
                        'Authorization': "Bearer " + token,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (res.status === 200) {

                setfile_name_of_course(res.data.msg[0].course_name)
                setfile_started_date(res.data.msg[0].course_started_date)
                setfile_completed_date(res.data.msg[0].course_ending_date)
                setfile_total_hours_completed(res.data.msg[0].hours_completed)
                setfile_online_or_F2F(res.data.msg[0].learning_mode)
                setfile_name_of_institute(res.data.msg[0].institute_name)
                setfile_website_of_institute(res.data.msg[0].institute_website)
                setfile_country_of_institute(res.data.msg[0].institute_country)
                setfile_contact_person_at_institute_name(res.data.msg[0].institute_contact_person_name)
                setfile_contact_person_at_institute_email(res.data.msg[0].institute_contact_person_email)
                setcourse_description(JSON.parse(res.data.msg[0].description).description)
                setrequest_ID(res.data.msg[0].request_id)

                setcomplexity(res.data.msg[0].complexity)
                setskills(JSON.parse(res.data.msg[0].skills));

                setshowloader(true);
            }

        } catch (error: any) {
            seterror_notification_text(error.response.data.msg)
            errorhandleShowNotification();
            setshowloader(true);
        }

    }

    const [ip, setIp] = React.useState('Fetching IP...');

    const fetchUserIP = async () => {
        try {
            // const response = await fetch('https://api.ipify.org/?format=json');
            const response = await fetch("https://api.ipify.org/?format=json");
            const data = await response.json();
            const userIP = data.ip;
            // console.log('User IP:', userIP);
            setIp(userIP)
            // Do something with the user's IP address
        } catch (error) {
            console.error('Error fetching user IP:', error);
        }
    };

    // React.useEffect(() => {
    //     fetchUserIP();
    // }, [])


    const toast = useToast()
    const navigate = useNavigate()

    const verification = () => {
        setTimeout(Analytics.updateVerifications, 50)
    }

    const readFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];

        if (selectedFile) {
            setFile(selectedFile);
        } else {
            setFile(null);
        }
    };

    const submit = async () => {

        if ((resume == "null" || resume == null) && file == null) {

            seterror_notification_text(`
                Either Upload Your CV or Upload Course Completion Proof!
                \n\n
                \n\n
                NOTE: To Upload Your CV Please Navigate To Settings Tab
                `);
            errorhandleShowNotification();

        } else {

            setshowloader(false);

            if (file === null) {

                try {

                    let body = {
                        file_url: null,
                        request_ID: request_ID
                    }

                    const response = await axios.patch(
                        back_end_base_url + 'certificate/verify-course-completion',
                        body,
                        {
                            headers: {
                                'Authorization': "Bearer " + token,
                                'Content-Type': 'application/json',
                            },
                        }
                    );


                    if (response.status === 200) {

                        try {

                            let body = {
                                request_ID: request_ID
                            }

                            const response = await axios.post(
                                back_end_base_url + 'email/notify/request-submission',
                                body,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                        'Content-Type': 'application/json',
                                    },
                                }
                            );

                            if (response.status === 200) {

                                setnotification_text(response.data.msg);
                                handleShowNotification();

                                try {

                                    const email = localStorage.getItem('email');
                                    const sign_up_email = localStorage.getItem('sign_up_email');

                                    const user_email = email === null ? sign_up_email : email;


                                    let body = {
                                        email: user_email,
                                        client_user_agent: navigator.userAgent
                                    }

                                    const response = await axios.post(back_end_base_url + 'events/certificate-request', body, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    });

                                    if (response.status === 200) {
                                        // console.log(response);
                                        setshowloader(true);

                                        setTimeout(() => {
                                            navigate('/history');
                                        }, 2000);
                                    }
                                } catch (error: any) {
                                    seterror_notification_text(error.response.data.msg);
                                    errorhandleShowNotification();
                                    setshowloader(true);

                                }

                            }
                        } catch (error: any) {
                            seterror_notification_text(error.response.data.msg);
                            errorhandleShowNotification();
                            setshowloader(true);

                        }

                    }

                } catch (error: any) {
                    seterror_notification_text(error.response.data.msg)
                    errorhandleShowNotification();
                    setshowloader(true);
                }

            } else {

                try {

                    const form = new FormData();
                    if (file) {
                        form.append('file', file)
                        form.append('type', 'verification_document')
                    }

                    const res = await axios.post(
                        back_end_base_url + 'service/cloudinary/upload-file',
                        form,
                        {
                            headers: {
                                'Authorization': "Bearer " + token,
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );

                    if (res.status === 200) {

                        try {

                            let body = {
                                file_url: res.data.msg,
                                request_ID: request_ID
                            }

                            const response = await axios.patch(
                                back_end_base_url + 'certificate/verify-course-completion',
                                body,
                                {
                                    headers: {
                                        'Authorization': "Bearer " + token,
                                        'Content-Type': 'application/json',
                                    },
                                }
                            );


                            if (response.status === 200) {

                                try {

                                    let body = {
                                        request_ID: request_ID
                                    }

                                    const response = await axios.post(
                                        back_end_base_url + 'email/notify/request-submission',
                                        body,
                                        {
                                            headers: {
                                                'Authorization': `Bearer ${token}`,
                                                'Content-Type': 'application/json',
                                            },
                                        }
                                    );

                                    if (response.status === 200) {

                                        setnotification_text(response.data.msg);
                                        handleShowNotification();

                                        try {

                                            const email = localStorage.getItem('email');
                                            const sign_up_email = localStorage.getItem('sign_up_email');

                                            const user_email = email === null ? sign_up_email : email;


                                            let body = {
                                                email: user_email,
                                                client_user_agent: navigator.userAgent
                                            }

                                            const response = await axios.post(back_end_base_url + 'events/certificate-request', body, {
                                                headers: {
                                                    'Authorization': `Bearer ${token}`,
                                                    'Content-Type': 'application/json',
                                                },
                                            });

                                            if (response.status === 200) {
                                                // console.log(response);
                                                setshowloader(true);

                                                setTimeout(() => {
                                                    navigate('/history');
                                                }, 2000);
                                            }
                                        } catch (error: any) {
                                            seterror_notification_text(error.response.data.msg);
                                            errorhandleShowNotification();
                                            setshowloader(true);

                                        }

                                    }
                                } catch (error: any) {
                                    seterror_notification_text(error.response.data.msg);
                                    errorhandleShowNotification();
                                    setshowloader(true);

                                }

                            }

                        } catch (error: any) {
                            seterror_notification_text(error.response.data.msg)
                            errorhandleShowNotification();
                            setshowloader(true);
                        }

                    }

                } catch (error: any) {
                    seterror_notification_text(error.response.data.msg)
                    errorhandleShowNotification();
                    setshowloader(true);
                }
            }

        }

    };


    React.useEffect(() => {
        validate_session_function();
    }, [])

    const validate_session_function = async () => {

        if (token === null) {

            localStorage.clear()
            navigate('/');

        } else {

            try {
                const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token)
                if (res.data.status === "200") {
                }
            } catch (error: any) {
                seterror_notification_text(error.response.data.msg)
                errorhandleShowNotification();
                localStorage.clear()
                navigate('/');
            }

        }

    }

    return (
        <div className='main_faq_page_div'>

            {/* notification popup box Start here */}
            <div className={`modal ${visible ? 'show' : 'hide'}`} id="notification_popup_box">
                <div className="modal-content">

                    <div className="notification_box">
                        <p>
                            {notification_text}
                        </p>
                    </div>

                </div>
            </div>
            {/* notification popup box End here */}

            {/* Error notification popup box Start here */}
            <div className={`modal ${error_visible ? 'show' : 'hide'}`} id="notification_popup_box">
                <div className="modal-content">

                    <div className="notification_box" style={{ backgroundColor: '#980f0f' }}>
                        <p id='error_message'>
                            {error_notification_text}
                        </p>
                    </div>

                </div>
            </div>
            {/* Error notification popup box End here */}


            {showloader ? (
                <></>
            ) : (
                <div className="main_loader_div" id="main_loader_div">
                    <div className="main_loader_inner_div">
                        <span className="loader"></span>
                    </div>
                </div>
            )}

            <React.Fragment>
                <Container maxW="6xl" pt={10} pb={10}>

                    <Text
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                        maxW={'3xl'}
                        mb={3}
                    >
                        Verify Certificate
                    </Text>

                    <>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Name of Course <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id="name_of_course"
                                type="text"
                                value={file_name_of_course}
                                onChange={(e) => {
                                    setfile_name_of_course(e.target.value)
                                }}
                                disabled={file_input_name_of_course ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Started Date <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="date"
                                value={file_started_date}
                                onChange={(e) => {
                                    setfile_started_date(e.target.value)
                                }}
                                disabled={file_input_started_date ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Completed Date <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="date"
                                value={file_completed_date}
                                onChange={(e) => {
                                    setfile_completed_date(e.target.value)
                                }}
                                disabled={file_input_completed_date ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Total Hours Completed <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="text"
                                value={file_total_hours_completed}
                                onChange={(e) => {
                                    setfile_total_hours_completed(e.target.value)
                                }}
                                disabled={file_input_total_hours_completed ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Online or F2F (Face to Face) <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <FormControl>
                                <Select
                                    value={file_online_or_F2F}
                                    onChange={(e) => {
                                        setfile_online_or_F2F(e.target.value)
                                    }}
                                    disabled={file_input_online_or_F2F ? true : false}
                                    onError={() => {
                                        console.log('error')
                                    }}>
                                    <option value="Select an option" selected hidden>Select an option</option>
                                    <option value="Online">Online</option>
                                    <option value="Face to Face">Face to Face</option>
                                </Select>
                            </FormControl>
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Name of institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="text"
                                value={file_name_of_institute}
                                onChange={(e) => {
                                    setfile_name_of_institute(e.target.value)
                                }}
                                disabled={file_input_name_of_institute ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Website of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="text"
                                value={file_website_of_institute}
                                onChange={(e) => {
                                    setfile_website_of_institute(e.target.value)
                                }}
                                disabled={file_input_website_of_institute ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Country of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="text"
                                value={file_country_of_institute}
                                onChange={(e) => {
                                    setfile_country_of_institute(e.target.value)
                                }}
                                disabled={file_input_country_of_institute ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Name of contact person at institute: <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="text"
                                value={file_contact_person_at_institute_name}
                                onChange={(e) => {
                                    setfile_contact_person_at_institute_name(e.target.value)
                                }}
                                disabled={file_input_contact_perso_at_institute_name ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Email of contact person at institute: <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Input
                                id=""
                                type="email"
                                value={file_contact_person_at_institute_email}
                                color={'gray.600'}
                                // disabled
                                onChange={(e) => {
                                    setfile_contact_person_at_institute_email(e.target.value)
                                }}
                                disabled={file_input_contact_person_at_institute_email ? true : false}
                                onError={() => {
                                    console.log('error')
                                }}
                            />
                        </FormControl>
                        <FormControl mt={3}>
                            <FormLabel
                                color={'gray.600'}
                                fontWeight={400}
                                maxW={'3xl'}
                                fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                            >
                                Course Description <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                            </FormLabel>
                            <Textarea
                                value={course_description}
                                onChange={(e) => {
                                    setcourse_description(e.target.value)
                                }}
                                disabled={input_course_description ? true : false}
                                size="md"
                                noOfLines={50}
                            />
                        </FormControl>
                        <br />
                        <label htmlFor="" className="error_only_doc_docx_pdf_heading"> Only png, jpg, jpeg pdf, doc and docx files can be uploaded </label>
                        <br />
                        <br />
                        <InputGroup>
                            <InputLeftAddon
                                children="Upload Proof of Completion / Certificate: "
                            />
                            <Input
                                size="md"
                                type="file"
                                name="file"
                                accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                                onChange={readFile}
                                disabled={file_input ? true : false}
                                textAlign={"center"}
                            />
                        </InputGroup>
                        <br />
                        <br />
                        <Button
                            mt={'2'}
                            rounded={'full'}
                            px={6}
                            colorScheme={'orange'}
                            bg={'#68c2e8'}
                            _hover={{ bg: '#0e1e40' }}
                            onClick={submit}
                            disabled={loading}
                        >
                            SUBMIT
                        </Button>
                    </>

                    <Card mt={10} pt={5} pb={5} variant="outline">
                        <React.Fragment>
                            <CardHeader>
                                <Flex alignItems={'center'} justifyContent={'center'}>
                                    <Heading fontWeight={600} fontSize={{ base: 'lg', sm: 'lg', md: '2xl' }} maxW={'3xl'} textAlign="center" color={"#195389"}>
                                        <span style={{ color: "#195389" }}>
                                            <u>
                                                <u>
                                                    RESULT
                                                </u>
                                            </u>
                                        </span>
                                    </Heading>
                                </Flex>

                                <Flex alignItems={'center'} justifyContent={'start'}>
                                    <Heading fontWeight={500} fontSize={{ base: 'lg', sm: 'lg', md: '1xl' }} maxW={'3xl'} textAlign="left" display="Flex">
                                        <img src={bllet_point} width={20} alt="" /> Complexity: &nbsp; <span style={{ color: "#195389" }}>  {complexity} </span>
                                    </Heading>
                                </Flex>
                                <br />
                                <Flex alignItems={'center'} justifyContent={'start'}>
                                    <Heading fontWeight={500} fontSize={{ base: 'lg', sm: 'lg', md: '1xl' }} maxW={'3xl'} textAlign="left" display="Flex">
                                        <img src={bllet_point} width={20} alt="" /> Skills
                                    </Heading>
                                </Flex>

                            </CardHeader>
                            <CardBody>
                                <Flex alignItems={'center'} justifyContent={'center'}>
                                    <div className='main_skills_div'>
                                        <div className='main_show_skills_div'>
                                            {skills?.map((item, index) => (
                                                <div key={index} className='show_skills_div'>
                                                    <img src={tick_arrow} alt="" />
                                                    <p>{item}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Flex>
                            </CardBody>
                        </React.Fragment>
                        {loading && (
                            <Flex alignItems={'center'} justifyContent={'center'}>
                                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#68c2e8" size="xl" />
                            </Flex>
                        )}
                    </Card>

                </Container>
            </React.Fragment>
        </div>
    )
}
