import React, { useState } from 'react';
import './Chat.css'; // Make sure to import the CSS file
import '@fortawesome/fontawesome-free/css/all.css';
import dummy_man from "../../assets/user_guide/dummy-man.png";


const Chat = () => {
    const [isActive, setIsActive] = useState(false); // Set initial state to false

    const handleToggle = () => {
        setIsActive(true); // Always set isActive to true
    };

    const handleClose = () => {
        setIsActive(false);
    };

    const handleKeyDown = (evt: any) => {
        if (evt.key === 'Escape') {
            setIsActive(false);
        }
    };

    const phoneNumber = '19055100807';
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // const handleButtonClick = () => {
    //     const defaultMessage = encodeURIComponent("Hello! I need assistance. Can you please help me with [briefly describe the issue or question]?");

    //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    //     if (isMobile) {
    //         // Open WhatsApp app on mobile with default message
    //         window.location.href = `https://wa.me/${phoneNumber}?text=${defaultMessage}`;
    //     } else {
    //         // Open WhatsApp Web on desktop with default message
    //         const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${defaultMessage}`;

    //         // Try to open the WhatsApp application on PC using a custom URL scheme
    //         const whatsappAppUrl = `whatsapp://send?phone=${phoneNumber}&text=${defaultMessage}`;
    //         const win = window.open(whatsappAppUrl, '_blank');

    //         // Check if opening the WhatsApp app succeeded
    //         if (!win || win.closed || typeof win.closed === 'undefined') {
    //             // Fallback to opening WhatsApp Web if the app is not opened or blocked
    //             window.open(whatsappWebUrl, '_blank');
    //         }
    //     }
    // };




    return (
        <>
            <div className={`chat-app ${isActive ? 'is-active' : ''}`}>
                <div className="chat-app_toggle toggle">
                    <div className="icon send">
                        <i className="fas fa-paper-plane"></i>
                    </div>
                    <a href="https://wa.me/19055100807" target="_blank">
                        <div className="icon open">
                            <i className="fas fa-brands fa-whatsapp"></i>
                        </div>
                    </a>
                </div>

                <div className="chat-app_box">
                    <div className="chat-app_header">
                        <div className="close" onClick={handleClose}>
                            <i className="fas fa-solid fa-x"></i>
                        </div>

                        <div className="branding">
                            <div className="avatar is-online">
                                <img src={dummy_man} alt="" />
                            </div>

                            <div className="content">
                                <p className="title">Marcus</p>
                                <p className="subtitle">Ask me anything, I am here to help you.</p>
                            </div>
                        </div>
                    </div>

                    <div className="chat-app_content">
                        <div className="messages">
                            {/* Messages go here */}
                        </div>
                    </div>

                    <div className="chat-app_footer">
                        <div className="tools">
                            <a className="button-icon">
                                <i className="far fa-smile-wink"></i>
                            </a>
                            <a className="button-icon">
                                <i className="fas fa-paperclip"></i>
                            </a>
                        </div>
                        <input className="chat-input" type="text" placeholder="Type..." />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chat;
