import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Button,
  Spinner,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Analytics } from '../../services/api/analytics';
import './dashboard.css';
import axios from 'axios';
import back_end_base_url from '../../services/back_end_url';
import Joyride from 'react-joyride';


const ICONS = {
  certificate: (
    <img
      src={require('../../assets/icons/certificate-outline.svg').default}
      width={100}
      alt="Certificate Icon"
    />
  ),
  verification: (
    <img
      src={require('../../assets/icons/check-decagram.svg').default}
      width={100}
      alt="Verification Icon"
    />
  ),
  views: (
    <img src={require('../../assets/icons/earth.svg').default} width={100} alt="Views Icon" />
  ),
  points: (
    <img
      src={require('../../assets/icons/progress-star-four-points.svg').default}
      width={100}
      alt="Points Icon"
    />
  ),
};

interface ICardComponentProps {
  backgroundColor: string;
  heading: string;
  value: number;
  children: React.ReactNode;
}

function CardComponent(props: ICardComponentProps) {
  const { backgroundColor, heading, children, value } = props;
  return (
    <Card padding={10} backgroundColor={backgroundColor} width={300} height={250}>
      <CardHeader>
        <Flex direction={'row'} gap={5} justifyContent={'center'} alignItems={'center'}>
          <Heading size="xs" textTransform="uppercase">
            {heading}
          </Heading>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction={'row'} alignItems={'center'} gap={10}>
          {children}
          <Text fontSize={'3xl'} style={{ fontWeight: 'bold' }}>
            {value}
          </Text>
        </Flex>
      </CardBody>
    </Card>
  );
}

interface IAnalytics {
  verifications: number;
  badges: number;
  points: number;
  views: number;
}

function Dashboard() {
  const pathname = localStorage.getItem('pathname');

  React.useEffect(() => {
    if (pathname === '/Account-Activate/') {
      localStorage.setItem('pathname', location.pathname);
      window.location.reload();
    } else if (pathname === '/settings/') {
      localStorage.setItem('pathname', location.pathname);
      window.location.reload();
    } else {

    }
  }, [pathname]);

  const location = useLocation();

  // React.useEffect(() => {
  //   localStorage.setItem('pathname', location.pathname);
  // }, [location.pathname]);

  const navigate = useNavigate();
  const token = localStorage.getItem('access-token');
  const resume = localStorage.getItem('resume');

  React.useEffect(() => {
    validateSessionFunction();
  }, []);

  const validateSessionFunction = async () => {
    if (token === null) {
      localStorage.clear();
      navigate('/');
    } else {
      // if (resume === null) {
      //   navigate('/settings');
      // } else {
      try {
        const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token);
        if (res.data.status === '200') {
          // Handle successful session validation
        }
      } catch (error) {
        console.log(error);
        localStorage.clear();
        navigate('/');
      }
      // }
    }
  };

  const [name, setName] = React.useState<string | null>(localStorage.getItem('username'));
  const [analytics, setAnalytics] = React.useState<IAnalytics | null>(null);
  const [fetching, setFetching] = React.useState<boolean>(false);

  React.useEffect(() => {
    setFetching(true);

    Analytics.get()
      .then((res) => {
        const data: IAnalytics = {
          verifications: res.data.msg[0].verifications,
          badges: res.data.msg[0].total_badges,
          points: res.data.msg[0].points,
          views: res.data.msg[0].views,
        };
        setAnalytics(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  if (fetching) {
    return (
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        style={{ marginTop: 300, marginBottom: 300 }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#68c2e8"
          size="xl"
        />
      </Flex>
    );
  }

  const joyrideCompleted = localStorage.getItem('joyrideCompleted') === 'true';

  const handleJoyrideCallback = (data: { status: string }) => {
    const { status } = data;

    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('joyrideCompleted', 'true');

      // Check if the button exists before accessing its properties
      const button = document.querySelector('.Register_New_Certificate');
      if (button) {
        button.classList.remove('is-active');
      }

      // Check if the screenMount
    }
  };

  const steps = [
    {
      target: '.Register_New_Certificate',
      content: 'This is my awesome feature!',
    },
    {
      target: '.CERTIFICATES',
      content: 'This another awesome feature!',
    },
    {
      target: '.views_this_month',
      content: 'This another awesome feature!',
    },
    {
      target: '.cred_points',
      content: 'This another awesome feature!',
    },
  ];


  const explore_guide = () => { }

  return (
    <div className="main_faq_page_div">
      {/* {!joyrideCompleted && (
        <Joyride
          steps={steps}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          locale={{ skip: 'Skip' }}
          callback={handleJoyrideCallback}
        />
      )} */}

      <Container maxW="8xl" pt={10} pb={10}>

        <div style={{ padding: '20px', backgroundColor: '#d6d8d9', border: '1px solid #b8babc', borderRadius: '5px' }}>
          <h1>
            <b>
              Step-by-Step Guide
            </b>
          </h1>
          <p>If you have any questions or need assistance with the Equivalence process, check out our comprehensive step-by-step guide.</p>
          <p>Feel free to explore the Equivalence section, and if you encounter any challenges, our guide is here to help you through the process.</p>
          <Link to="/User-Guide">
            <Button style={{ marginTop: '10px' }}>Explore Guide</Button>
          </Link>
        </div>

        <Flex
          alignItems={'center'}
          direction={'row'}
          pb={'20'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          gap={7}
        >
          <Grid gap={5}>
            <GridItem>
              <Heading as="h2" size="xl">
                Welcome, {name}
              </Heading>
            </GridItem>
            <GridItem>
              <Text>Aspiring Creds Student</Text>
            </GridItem>
            <GridItem>
              <Text fontSize="sm" color={'gray.500'}>
                This is your personalized dashboard. Use this space to analyze your performance metrics.
              </Text>
            </GridItem>
          </Grid>
          <Link to={'/equivalence'}>
            <Button
              className='Register_New_Certificate'
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              bg={'#68c2e8'}
              _hover={{ bg: '#0e1e40' }}
              width={280}
              height={50}
              gap={2}
            >
              Register New Certificate
              <img
                src={require('../../assets/icons/file-certificate-outline.svg').default}
                width={30}
                style={{
                  filter: 'brightness(0) invert(1) sepia(1) saturate(5) hue-rotate(255deg)',
                }}
                alt="Certificate Icon"
              />
            </Button>
          </Link>
        </Flex>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          direction={'row'}
          gap={10}
          wrap={'wrap'}
          pt={'5'}
        >
          <a href="/history"
            className='CERTIFICATES'
          >
            <CardComponent
              backgroundColor={'#3CAEA3'}
              heading="certificates"
              value={analytics ? analytics.badges : 0}
            >
              {ICONS.certificate}
            </CardComponent>
          </a>

          {/* <CardComponent
            backgroundColor={'#F6D55C'}
            heading="verifications"
            value={analytics ? analytics.verifications : 0}
          >
            {ICONS.verification}
          </CardComponent> */}
          <div className='views_this_month'>
            <CardComponent
              backgroundColor={'#ED553B'}
              heading="views this month"
              value={analytics ? analytics.views : 0}
            >
              {ICONS.views}
            </CardComponent>
          </div>
          <div className='cred_points'>
            <CardComponent
              backgroundColor={'#FC7174'}
              heading="cred points"
              value={analytics ? analytics.points : 0}
            >
              {ICONS.points}
            </CardComponent>
          </div>
        </Flex>
      </Container>
    </div>
  );
}

export default Dashboard;
