import axios, { AxiosResponse } from 'axios'
import back_end_base_url from '../back_end_url';
import python_base_url from '../python_url';

export class AxiosClient {
  private static readonly baseURLBackend: string = back_end_base_url;
  private static readonly baseURLMachineLearning: string = python_base_url;

  private static getToken() {
    const token = localStorage.getItem('access-token');
    return token;
  }

  private static getBaseUrl(ml = false) {
    return ml ? this.baseURLMachineLearning : this.baseURLBackend;
  }

  private static getTokenHeader(auth = false) {
    if (!auth) return '';
    const token = this.getToken(); // Use the getToken method

    if (token) {
      return `Bearer ${token}`;
    }
    return '';
  }

  public static post(
    endpoint: string,
    body: any,
    ml: boolean = false,
    auth = false
  ): Promise<AxiosResponse<any, any>> {
    return axios.post(this.getBaseUrl(ml) + endpoint, body, {
      headers: {
        'Authorization': "Bearer " + this.getToken(),
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '69420',
      },
    });
  }

  public static get(
    endpoint: string,
    ml: boolean = false,
    auth = false
  ): Promise<AxiosResponse<any, any>> {
    return axios.get(this.getBaseUrl(ml) + endpoint, {
      headers: {
        'Authorization': "Bearer " + this.getToken(),
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '69420',
      },
    });
  }

  public static patch(
    endpoint: string,
    body: any,
    auth = false
  ): Promise<AxiosResponse<any, any>> {
    return axios.patch(this.getBaseUrl() + endpoint, body, {
      headers: {
        'Authorization': "Bearer " + this.getToken(),
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '69420',
      },
    });
  }
}
