import * as React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Container,
  Text,
  Flex,
  Spinner,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import { Analytics } from '../../services/api/analytics'
import moment from 'moment'
import nav_menu from '../../assets/icons/nav_menu.png'
import download_icon from '../../assets/icons/download-icon.svg'
import './history.css'
import { StarIcon } from '@chakra-ui/icons'
import back_end_base_url from '../../services/back_end_url'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { AiFillClockCircle } from 'react-icons/ai';

function Row(props: any) {

  const pointsColor = (points: number) => {
    let color = '';

    if (points === 1) {
      color = '#90EE90';
    } else if (points === 2) {
      color = '#ADD8E6';
    } else if (points === 3) {
      color = '#FFA500';
    } else if (points === 4) {
      color = '#00008B';
    } else if (points === 5) {
      color = '#8B0000';
    } else {
      color = '#CCCCCC';
    }

    return color;
  }

  const downloadCertificateFunction = (id: string) => {
    window.open(window.location.origin + "/Certificate?badge_Id=" + id, '_blank');
  };

  const requestCertificateFunction = (id: string) => {
    window.open(window.location.origin + "/Verify-Certificate?badge_Id=" + id, '_blank');
  };

  return (
    <Tr>
      <Td>{props.course_name}</Td>
      <Td>{props.complexity}</Td>
      <Td>
        <Tag
          size={'md'}
          key={props.points}
          borderRadius="full"
          variant="solid"
          backgroundColor="transparent"
        >
          <div className='main_start_reading_text'>
            <p className='start_reading_text'>{props.complexity}</p>
            <TagLabel>
              {Array.from({ length: 5 }).map((_, index) => (
                <StarIcon
                  key={index}
                  color={index < props.points ? pointsColor(props.points) : 'gray.300'}
                />
              ))}
            </TagLabel>
          </div>
        </Tag>
      </Td>
      <Td>{props.badge_creation_date}</Td>
      <Td>
        {props.status === 'Rejected' ? (
          <p>Rejected: Check Email</p>
        ) : (
          <p>{props.status}</p>
        )}
      </Td>
      <Td className='main_download_icon'>
        {props.status.toLowerCase() === 'not requested' ? (
          <button onClick={() => requestCertificateFunction(props.badge_id)}>Request Certificate</button>
        ) : props.status.toLowerCase() === 'pending' ? (
          <i><AiFillClockCircle /></i>
        ) : props.status.toLowerCase() === 'approved' ? (
          <img
            className='download_icon'
            src={download_icon}
            onClick={() => downloadCertificateFunction(props.badge_id)}
          />
        ) : null}
      </Td>


    </Tr>
  )
}

function History() {

  const location = useLocation();

  React.useEffect(() => {
    localStorage.setItem('pathname', location.pathname);
  }, []);

  const token = localStorage.getItem('access-token')
  const resume = localStorage.getItem('resume')

  const navigate = useNavigate();

  React.useEffect(() => {
    validate_session_function();
  }, [])

  const validate_session_function = async () => {

    if (token === null) {

      localStorage.clear()
      navigate('/');

    } else {
      // if (resume === null) {
      //   navigate('/settings');
      // } else {
      try {
        const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token)
        if (res.data.status === "200") {
        }
      } catch (error) {
        localStorage.clear()
        navigate('/');
      }
      // }

    }

  }


  const [listing, setListing] = React.useState([])
  const [fetching, setFetching] = React.useState(false)

  React.useEffect(() => {
    setFetching(true)

    Analytics.getHistory()
      .then((res) => {
        setListing(res.data.msg)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [])

  if (fetching) {
    return (
      <React.Fragment>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          style={{ marginTop: 300, marginBottom: 300 }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#68c2e8"
            size="xl"
          />
        </Flex>
      </React.Fragment>
    )
  }


  return (
    <div className='main_faq_page_div'>
      <React.Fragment>
        <Container maxW={'7xl'} pt={20} pb={20}>
          <div className='main_history_show_top_div'>
            <div className='inner_history_show_top_div1'>
              <Text
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                maxW={'3xl'}
                mb={3}
              >
                History
              </Text>
              <Text>{`Account > History`}</Text>
            </div>
            <div className='inner_history_show_top_div2'>
              <div className='history_level'> Levels </div>
              <div className='main_show_star_div'>
                <div className='inner_show_star_div1'>
                  <div> Beginner </div>
                  <div> Novice </div>
                  <div> Intermediate </div>
                  <div> Advance </div>
                  <div> Expert </div>
                </div>
                <div className='inner_show_star_div2'>
                  <div>
                    <span className="fa fa-star" style={{ color: "#90EE90" }}></span>
                  </div>
                  <div>
                    <span className="fa fa-star" style={{ color: "#ADD8E6" }}></span>
                    <span className="fa fa-star" style={{ color: "#ADD8E6" }}></span>
                  </div>
                  <div>
                    <span className="fa fa-star" style={{ color: "#FFA500" }}></span>
                    <span className="fa fa-star" style={{ color: "#FFA500" }}></span>
                    <span className="fa fa-star" style={{ color: "#FFA500" }}></span>
                  </div>
                  <div>
                    <span className="fa fa-star" style={{ color: "#00008B" }}></span>
                    <span className="fa fa-star" style={{ color: "#00008B" }}></span>
                    <span className="fa fa-star" style={{ color: "#00008B" }}></span>
                    <span className="fa fa-star" style={{ color: "#00008B" }}></span>
                  </div>
                  <div>
                    <span className="fa fa-star" style={{ color: "#8B0000" }}></span>
                    <span className="fa fa-star" style={{ color: "#8B0000" }}></span>
                    <span className="fa fa-star" style={{ color: "#8B0000" }}></span>
                    <span className="fa fa-star" style={{ color: "#8B0000" }}></span>
                    <span className="fa fa-star" style={{ color: "#8B0000" }}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableContainer mt={20} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Assigned Title</Th>
                  <Th>Level</Th>
                  <Th>Rating / Points</Th>
                  <Th>Date</Th>
                  <Th>Status</Th>
                  <Th>Download <br /> certificate</Th>
                </Tr>
              </Thead>
              {listing.length ? (
                <Tbody>
                  {listing.map((item: any) => <Row {...item} />)}
                </Tbody>
              ) : (
                <>
                  <TableCaption>
                    No Data Found
                  </TableCaption>
                </>
              )}
              <TableCaption>
                All the verifications you have made with American Skills Evaluation Institute
              </TableCaption>
            </Table>
          </TableContainer>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default History