import React, { ReactNode } from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

export default function Faq() {

  const location = useLocation();

  React.useEffect(() => {
    localStorage.setItem('pathname', location.pathname);
  }, []);

  return (
    <div className='main_faq_page_div'>

      <Box
        p={'2rem 0'}
        width={'80%'}
        mx={'auto'}
        my={0}
      >

        <div style={{ padding: '20px', backgroundColor: '#d6d8d9', border: '1px solid #b8babc', borderRadius: '5px', marginBottom: '10px' }}>
          <h1>
            <b>
              Step-by-Step Guide
            </b>
          </h1>
          <p>If you encounter any issues, visit the User Guide for assistance. There's also a User Guide link below for easy access.</p>
          <Link to="/User-Guide">
            <a style={{ marginTop: '10px', color: '#0e1e40', textDecoration: 'underline', cursor: 'pointer' }}>Explore User Guide</a>
          </Link>
        </div>


        <Flex justify={'center'} >

          <Text
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
            maxW={'3xl'}
          >
            FAQ PAGE
          </Text>
        </Flex>
        <br />
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Why should I choose ASEI for my certificate evaluation?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              You should choose ASEI because we are the pioneers and among the first skill certificate evaluation institute in the US that is currently offering its services at NO COST to our customers. There is no obligation whatsoever to continue to use our services.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What process do you use to evaluate certificates?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              ASEI sets itself apart with our groundbreaking approach, utilizing a patented* AI/ML engine powered by blockchain technology. This innovative combination ensures the utmost accuracy and integrity in verifying skill levels and evaluating credentials based on US standards.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Which countries are you qualified to complete academic evaluations for?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We can evaluate academic credentials from any country in the world, as long as the certificate that you are submitting, including the course outlines and description are in English.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Where are you located?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We are located in Houston, TX and serve our customers throughout the world.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What is your mailing address?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Our mailing address is American Skills Evaluation Institute (ASEI), 7080 Southwest Fwy, Houston, TX 77074, USA
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What are your hours of operation?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We provide sales and support 5 days a week, M-F 9am to 5pm CST.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What are your main phone numbers?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Our Voice / WhatsApp number is +1 905 510 0807 for you to send a message, and we will revert back to you at the earliest.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Who do I contact for support?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Please contact support@skillsevaluation.org for a prompt response.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What forms of payment are accepted?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We accept payment only through PayPal.
            </AccordionPanel>
          </AccordionItem>

          <Flex justify={'center'} p={'2rem 0'}>
            <Text
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
              maxW={'3xl'}>
              GENERAL QUESTIONS
            </Text>
          </Flex>

          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Why do I need a certificate evaluation?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Skills learnt in any country are not automatically recognized in any other country, particularly in the US. An evaluation is required in order to identify and evaluate if your skills are equivalent to US standards, thus making your foreign skills recognizable for the purpose of employment, education, licensing or for visa purposes.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What are your rates?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>

              <div className='faq_payment_table_div'>

                <table>

                  <thead>
                    <tr>
                      <th>Individual</th>
                      <th>Freelancer</th>
                      <th>Trainer</th>
                      <th>Enterprise</th>
                      <th>Enterprise Plus</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Certificate issued based on course evaluation </td>
                      <td>Certificate issued based testing and portfolio analysis</td>
                      <td>Special Package for small batch training providers</td>
                      <td>Certificates issued based on courses offered by Institute after accreditation by ASEI</td>
                      <td>Bulk certificates issued by Institute based on guidelines of ASEI*</td>
                    </tr>
                    <tr>
                      <td>1 Certificate</td>
                      <td>1 Certificate</td>
                      <td>25 -50 participants</td>
                      <td>51-100 participants per course</td>
                      <td>Upto 1000 certificates per year</td>
                    </tr>
                    <tr>
                      <td>$5</td>
                      <td>$50 for course / portfolio review</td>
                      <td>$3 per certificate for first 25 $2 per certificate for next 25</td>
                      <td>$1 per certificate per course</td>
                      <td>For quote contact <b>support@skillsevaluation.org</b> </td>
                    </tr>
                  </tbody>

                </table>

              </div>
              {/* We are currently offering our services */}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What document do I need to provide?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              You need to upload a copy of your certificate received and a description or course outline of the skill you learnt. These could be in word or pdf format.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  How long does it take to get my evaluation certificate?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Our standard service time is two business days, after which your evaluated certificate will be available online to download.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What if I have not completed my college or school, or don’t have a degree?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We do not require that you have completed school or college. We only evaluate the certificate that you have completed.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What is the process for an evaluation?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We ask you to upload a copy of the certificate you received, and the course description or outline, along with all the information requested in the template, such as your name, date of birth, etc.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Why do my documents need to be in English?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Our AI / ML technology currently only recognizes English characters.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Will my Certificate evaluations be accepted by all employers or schools?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We cannot guarantee for all employers and schools. However, since we are a credible and recognized US skill evaluation service provider having completed thousands of evaluations to date which have been accepted by many, we suggest you ask them to contact us or check our website if they have any difficulty in recognizing your skill.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Are your evaluations accepted for licensure?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We are well known around the world for licensure, and our evaluations are widely accepted in the US for this purpose, but we recommend you check with the relevant licensing board first.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Can I get a certificate for my skilled work experience?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We currently don’t evaluate work experience, however our experts are currently developing algorithms to this effect and we hope to launch this service by mid 2024.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What if I don’t agree with your academic evaluation assessment?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Please let us know why, and we’ll be happy to take your feedback to your evaluator. If you’re still not satisfied with your evaluation. Please let us know, and an advisor will get back to you.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Is my evaluation certified?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Yes, your evaluation will be certified, stamped and signed by the ASEI – ideal for education, work or visa purposes!
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  Will my evaluation expire?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              No – your evaluation/s do not expire.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  How do I make a correction/edit request?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Please email to us with your registration number and a copy of the ASEI certificate, with the justification why you should have received a better evaluation, and our evaluator will revery back to you within a few days.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  My school/employer wants to receive the document/s directly from you. How do I do that?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Please email to us their contact, and we will email a copy of your certificate to them directly.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  How long do you keep my record on file?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Certificates that we have evaluated and prepared for you are kept on file permanently.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton bg={'#195389'} color={'white'} _hover={{
                background: "white",
                color: "teal.500",
              }}>
                <Box as="span" flex='1' textAlign='left'>
                  What if I need a refund?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Refund policy We will work with you to resolve any outstanding issue. However, if you are not satisfied, we will fully refund you
              {/* Currently there is no <b style={{ color: "#980f0f" }}>fee for a limited time only</b> for an evaluation. */}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </div>
  );
}