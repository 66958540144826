import React, { useEffect, useState } from 'react';
import './blog_page.css';
import { useLocation, useNavigate } from 'react-router-dom';

import blog_one_thumbnail from '../../assets/images/blog_one_thumbnail.jpeg';
import blog_two_thumbnail from '../../assets/images/blog_two_thumbnail.jpeg';
import blog_three_thumbnail from '../../assets/images/blog_three_thumbnail.jpeg';
import blog_four_thumbnail from '../../assets/images/blog_four_thumbnail.jpeg';
import blog_six_thumbnail from '../../assets/images/skills_for_success_blog_img_three.jpeg';
import blog_seven_thumbnail from '../../assets/images/it_is_time_for_change_blog_img_one.png';


export const Blog_page = () => {

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);
    // useEffect(() => {
    //     create_blog_cards_function();
    // }, [])


    const create_blog_cards_function = () => {

        const inner_all_blogs_show_div = document.getElementById('inner_all_blogs_show_div')

        for (let i = 0; i < 0; i++) {

            const main_blog_card_div = document.createElement('div')
            main_blog_card_div.classList.add('main_blog_card_div')


            const blog_image_div = document.createElement('div')
            blog_image_div.classList.add('blog_image_div')

            const blog_image_div_image = document.createElement('img')
            blog_image_div_image.src = `https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681260851-how.jpg`


            const blog_body_div = document.createElement('div')
            blog_body_div.classList.add('blog_body_div')

            const blog_heading_div = document.createElement('div')
            blog_heading_div.classList.add('blog_heading_div')
            blog_heading_div.innerText = "Lorem ipsum dolor sit amet consectetur adipisicing elit."

            const blog_description_div = document.createElement('div')
            blog_description_div.classList.add('blog_description_div')
            blog_description_div.innerText = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi illo non debitis praesentium, temporibus consequuntur dolor sit soluta similique aspernatur dolore sint nihil, et ullam, excepturi odit sed vitae fugiat."

            const blog_read_more_button_div = document.createElement('div')
            blog_read_more_button_div.classList.add('blog_read_more_button_div')

            const button = document.createElement('button')
            button.innerText = "Read More"


            main_blog_card_div.appendChild(blog_image_div)
            blog_image_div.appendChild(blog_image_div_image)

            main_blog_card_div.appendChild(blog_body_div)
            blog_body_div.appendChild(blog_heading_div)
            blog_body_div.appendChild(blog_description_div)
            blog_body_div.appendChild(blog_read_more_button_div)
            blog_read_more_button_div.appendChild(button)

            inner_all_blogs_show_div?.appendChild(main_blog_card_div)

        }

    }


    return (
        <>
            <div className='main_blog_page_div'>

                <div className='main_blog_hero_section_div'>
                    <div className='inner_blog_hero_section_div'>
                        <div className='left_blog_hero_section_div'>
                            BLOGS
                        </div>
                        {/* <div className='right_blog_hero_section_div'>
                            <div className='right_inner_div1'>
                                <div className='sm_circle_div' id='person1'>
                                    <div className='circle_text_div'>
                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit,
                                    </div>
                                </div>
                            </div>
                            <div className='right_inner_div2'>
                                <div className='larg_circle_holder' id='circle_holder1'>
                                    <div className='sm_circle_div' id='person2'>
                                        <div className='circle_text_div'>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit,
                                        </div>
                                    </div>
                                    <div className='sm_circle_div' id='person3'>
                                        <div className='circle_text_div'>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit,
                                        </div>
                                    </div>
                                </div>
                                <div className='larg_circle_holder'>
                                    <div className='lg_circle_div' id='person5'>
                                        <div className='circle_text_div'>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit,
                                        </div>
                                    </div>
                                    <div className='sm_circle_div' id='person4'>
                                        <div className='circle_text_div'>
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit,
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='main_all_blogs_show_div'>
                    <div className='inner_all_blogs_show_div' id='inner_all_blogs_show_div'>

                        <div className="main_blog_card_div">
                            <div className="blog_image_div">
                                <img src={blog_one_thumbnail} />
                            </div>
                            <div className="blog_body_div">
                                <div className="blog_heading_div">
                                    How to know if your Short Course is worth the money you spent on it
                                </div>
                                <div className="blog_description_div">
                                    If you recently completed a short course and are wondering if it was worth your time and money, this guide will help you evaluate the quality and value of your learning experience. By considering various factors, such as the course content, instructor, and your own personal goals, you can determine if the course met your expectations and if it was a worthwhile investment.
                                </div>
                                <div className="blog_read_more_button_div">
                                    <a href="/Blog-review-1">
                                        <button>Read More</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="main_blog_card_div">
                            <div className="blog_image_div">
                                <img src={blog_two_thumbnail} />
                            </div>
                            <div className="blog_body_div">
                                <div className="blog_heading_div">
                                    Top 10 In-Demand Skills You Can Learn Online
                                </div>
                                <div className="blog_description_div">
                                    If you want to boost your resume and stand out from the crowd, online learning is a great option. You can access a variety of courses and programs that teach you valuable skills for the modern workplace. Here are some of the most in-demand skills that you can learn online
                                </div>
                                <div className="blog_read_more_button_div">
                                    <a href="/Blog-review-2">
                                        <button>Read More</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="main_blog_card_div">
                            <div className="blog_image_div">
                                <img src={blog_three_thumbnail} />
                            </div>
                            <div className="blog_body_div">
                                <div className="blog_heading_div">
                                    How to Compare Your Skills with Market Demands
                                </div>
                                <div className="blog_description_div">
                                    In today's competitive and dynamic job market, it is essential to keep your skills updated and aligned with the needs of employers. But how do you know what skills are in demand and how do you measure your own skill level against them? In this blog post, we will share some tips and tools to help you compare your skills with market demands and identify your strengths and areas for improvement.
                                </div>
                                <div className="blog_read_more_button_div">
                                    <a href="/Blog-review-3">
                                        <button>Read More</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="main_blog_card_div">
                            <div className="blog_image_div">
                                <img src={blog_four_thumbnail} />
                            </div>
                            <div className="blog_body_div">
                                <div className="blog_heading_div">
                                    What is more important: Learning skills or getting a degree?
                                </div>
                                <div className="blog_description_div">
                                    The debate surrounding the value of skills compared to a degree is a significant issue in the United States. While approximately two-thirds of job openings in the country require a degree, around one-third call for vocational training and skill certification. This situation has led to a pressing employment crisis, with over 8 million jobs that demand relevant credentials remaining unfilled across the US.
                                </div>
                                <div className="blog_read_more_button_div">
                                    <a href="/Blog-review-4">
                                        <button>Read More</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="main_blog_card_div">
                            <div className="blog_image_div">
                                <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681260851-how.jpg" />
                            </div>
                            <div className="blog_body_div">
                                <div className="blog_heading_div">
                                    Importance of Skills
                                </div>
                                <div className="blog_description_div">
                                    The education and employment landscape has experienced a notable shift due to recent developments, where acquiring relevant skills are becoming more important than degrees.
                                    <br />
                                    IBM in recent years has introduced an innovative program called Pathways in Technology Early College High School (P-TECH), enabling students who may not have considered college to earn a high school diploma alongside a skill certificate or associate degree.
                                </div>
                                <div className="blog_read_more_button_div">
                                    <a href="/Blog-review-5">
                                        <button>Read More</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="main_blog_card_div">
                            <div className="blog_image_div">
                                <img src={blog_six_thumbnail} />
                            </div>
                            <div className="blog_body_div">
                                <div className="blog_heading_div">
                                    Skills For Success
                                </div>
                                <div className="blog_description_div">
                                    Discover how Pakistan's IT industry is projected to reach new heights with a market volume of
                                    <strong style={{ color: '#195389' }}> $5 billion by 2028 </strong>
                                    and annual exports expected to hit
                                    <strong style={{ color: '#195389' }}> $10 billion </strong>.
                                    <br />
                                    This blog delves into the critical role of recognized IT skills, the shift from traditional degrees to micro-credentials, and the increasing demand for skilled professionals in the global job market.
                                    Learn about the Government impact, freelancing rise, accreditation need.
                                </div>
                                <div className="blog_read_more_button_div">
                                    <a href="/Blog-review-6">
                                        <button>Read More</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="main_blog_card_div">
                            <div className="blog_image_div">
                                <img src={blog_seven_thumbnail} />
                            </div>
                            <div className="blog_body_div">
                                <div className="blog_heading_div">
                                    It's Time For Change
                                </div>
                                <div className="blog_description_div">
                                    Explore how Pakistan's <strong style={{ color: '#195389' }}>$3 billion </strong>IT industry compares to global giants like <strong style={{ color: '#195389' }}>Apple, Microsoft, NVIDIA, and Alphabet</strong>, which together have a combined market cap exceeding $12 trillion. Despite resources, Pakistan's IT sector lags behind, with India achieving $200 billion in IT exports.
                                    <br></br>
                                    Discover the challenges including poor quality graduates and lack of government incentives. Learn why Pakistan needs to focus on high-quality education and skill recognition.
                                </div>
                                <div className="blog_read_more_button_div">
                                    <a href="/Blog-review-7">
                                        <button>Read More</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};
