import React from 'react'
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup'
import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
} from '@chakra-ui/react'
const CounterUP = ({end}) => {
  const [counterStart,setCounterStart] = React.useState(false)

  return (
          <ScrollTrigger onEnter={()=>setCounterStart(true)} onExit={()=>setCounterStart(false)}>
        <Text as={'span'} color={'white'}fontWeight={600}
          fontSize={{ base: '2xl', sm: '4xl', md: '4xl' }} >
        {counterStart&&<CountUp start={0} end={end} duration={2} delay={0} />}+
          </Text>
      </ScrollTrigger>
  )
}

export default CounterUP