import React, { useEffect, useState } from 'react';
import './Accreditation_page.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ENDORSED_AND_AFFILIATED_MEMBERS from "../../assets/images/ENDORSED-AND-AFFILIATED-MEMBERS.jpg";


export const Accreditation_page = () => {

    const location = useLocation();
    const [modalIsOpen, setIsOpen] = useState(false);

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);



    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }



    return (
        <>
            {modalIsOpen ? (
                <>
                    <div className='main_popup_box_div'>
                        <div className='inner_popup_box_div'>
                            <div>
                                <h1>ACCREDITATION REQUIREMENTS</h1>
                            </div>
                            <div>
                                <p>
                                    <b>
                                        Please submit the following detailed information with attachments to <a href="mailto:support@skillsevaluation.org"><b>support@skillsevaluation.org</b></a> with subject heading <b style={{ color: "#195389" }}>"Application for Accreditation"</b>
                                    </b>
                                </p>
                            </div>
                            <div>
                                <button onClick={closeModal}>OK</button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}


            <div className='main_blog_page_div'>

                <div className='main_accreditation_hero_section_div'>
                    <div className='inner_blog_hero_section_div'>
                        <div className='left_blog_hero_section_div'> ACCREDITATION </div>
                    </div>
                </div>

                <div className='main_all_blogs_show_div'>

                    <div className='home_inner_sections_div1'>
                        <div className='inner_home_inner_sections_div1'>
                            <div className='left_home_inner_sections_div1' style={{ width: "100%" }}>
                                <div className='left_heading_home_inner_sections_div1'>
                                    ACCREDITATION
                                </div>
                                <div className='left_contant_home_inner_sections_div1'>
                                    ASEI offers Institutional Accreditation for all types of Education Providers (Training Centers, Schools, Institutes, Colleges and Universities) which offer credentials, certificates, diploma or degrees in IT. All such education providers, by going through a rigorous ASEI evaluation process and meeting the requirements of accreditation, will be accredited by ASEI.
                                </div>
                                <div className='left_contant_home_inner_sections_div1'>
                                    Accredited Institutes and their graduates enjoy instant global recognition with the ASEI brand Logo placed on the institutional website by identifying it as ACCREDITED BY ASEI.
                                </div>
                                <div className='left_contant_home_inner_sections_div1'>
                                    Accredited Institutes can also affix the ASEI brand logo on their completion and graduating certificates, identifying it as ACCREDITED BY ASEI.
                                </div>
                                <div className='left_contant_home_inner_sections_div1'>
                                    Institutional accreditation applicants must complete and follow a rigorous screening process and follow stringent guidelines set forth in ASEI’s Standards when providing credential evaluations.
                                </div>
                                <div className='left_contant_home_inner_sections_div1 subheading'>
                                    Accreditation
                                </div>
                                <div className='left_contant_home_inner_sections_div1'>
                                    Accreditation is symbolic for instant recognition of quality. Accredited institutes receive instant global recognition for quality by perspective employers for employment, and for continuing education and visa purposes. There are additionally many benefits, including acceptance of transfer credits to another institute, student loans, and reaching out for partnership, and collaboration or affiliation with other institutes worldwide. ASEI accreditation gives instant acceptability by world leading education and training providers.
                                </div>
                                <div className='left_contant_home_inner_sections_div1'>
                                    Accreditation is open to education providers whose primary purpose and mission is to provide training and certification. Accreditation is contingent on the review, screening and verification of the education provider. Complete information with supporting documentation must be submitted, which after detailed review by ASEI Evaluators, may be asked secondary questions, followed up with an interview and a site visit.
                                </div>
                                <div className='left_contant_home_inner_sections_div1 subheading'>
                                    Accreditation Requirements and Screening Process
                                </div>
                                <div className='left_contant_home_inner_sections_div1'>
                                    Education providers must thoroughly review the accreditation requirements and complete all documentation before submitting the application for Accreditation. There is a rigorous screening process to receive accreditation from ASEI. Only the best receive accreditation.
                                </div>
                                <div className='left_button_home_inner_sections_div1'>
                                    <button className="button" onClick={openModal}><span> Accreditation Requirements </span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='home_inner_sections_div1'>
                        <div className='inner_home_inner_sections_div1'>
                            <div className='left_home_inner_sections_div1' style={{ width: "100%" }}>
                                <div className='left_heading_home_inner_sections_div1'>
                                    Education Provider / Institutional Specific
                                </div>
                                <div className='left_contant_home_inner_sections_div1' style={{ gap: ".5rem" }}>
                                    <p>
                                        1. Website (URL)
                                    </p>
                                    <p>
                                        2. Recognition / Registration by the respective government agencies or organizations (please provide copies of certificate of registration)
                                    </p>
                                    <p>
                                        3. Leadership Team
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;i. President / CEO and qualifications (CV)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;ii. Other top team members names and qualifications (VP, Registrar, Deans, etc in case of university)
                                    </p>
                                    <p>
                                        4 Recognition / Accreditation by any other Agency
                                    </p>
                                    <p>
                                        5 Grading System(s) used
                                    </p>
                                    <p>
                                        6. Instruction details – distance vs. in-person, classroom instruction vs. practice and experience-based instruction, etc.
                                    </p>
                                    <p>
                                        7. LMS used
                                    </p>
                                    <p>
                                        8. Facilities available
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;i  Computer Labs (numbers of PCs / laptops / Tablets available for training purpose, their specifications, etc) – Seating size for each lab.
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;ii  Internet bandwidth
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;iii Software available
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;iv Library Resources
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;v Classroom (seating size) and A/V facilities
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;vi Auditorium available (if any) and capacity
                                    </p>
                                    <p>
                                        9. Program specifics (Submit prospectus or URLs for each program or certification offered, or submit pdf copies of brochures)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;i. Levels (Certificate, undergraduate, graduate, postgraduate, etc)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;iii. Admission requirements (for each program or certificate)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;iv. Program / Certificate duration (for each program)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;v. Completion requirements
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;vi. Language of instruction
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;vii. Examination system (MCQs, Case Study, etc)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;viii. Grading system (Letter grade / Marks, GPA, S / U, Pass / Fail, etc)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;ix. Instruction details – distance vs. in-person, classroom instruction vs. practice and experience-based instruction, etc.
                                    </p>
                                    <p>
                                        10. Course / Certificate specifics (Submit prospectus or URLs for various programs, or pdf copies of brochures)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;i. Content
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;ii. Level
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;iii. Field of study
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;iv. Language of instruction
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;v. Duration (clock hours, units, etc.)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;vi. Full time vs. part-time vs. intensive
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;vii. Instruction details – distance vs. in-person instruction, classroom vs. practice and experience-based instruction, etc
                                    </p>
                                    <p>
                                        11. Teaching faculty, Instructors, Trainers (submit CVs of all to show qualifications and experience)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;i. Numbers (full time versus part time)
                                    </p>
                                    <p>
                                        &nbsp;&nbsp;&nbsp;ii. Areas of expertise (specify which faculty / trainer is teaching which subject)
                                    </p>
                                    <p>
                                        12. Number of students (male / female) – average total in a semester / quarter / course / certificate – minimum and maximum – provide most current information
                                    </p>
                                    <p>
                                        13. Number of supporting staff (admin, admission, finance, library)
                                    </p>
                                </div>
                                <div>
                                    <p>If you have questions or need clarification, please email to ASEI at <a href="mailto:support@skillsevaluation.org"><b>support@skillsevaluation.org</b></a> .</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};
