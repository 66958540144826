import {
  Container,
  FormControl,
  Select,
  Text,
  FormLabel,
  Flex,
  Input,
  Stack,
  Button,
  Spinner,
  useToast,
  Divider,
} from '@chakra-ui/react'
import * as React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import COUNTRIES from '../../constants/countries.json'
import { User } from '../../services/api/user'
import { FormikValues } from 'formik/dist/types'
import nav_menu from '../../assets/icons/nav_menu.png'
import axios from 'axios'
import back_end_base_url from '../../services/back_end_url'
import { useLocation, useNavigate } from 'react-router-dom'
import resume_img from '../../assets/images/resume.png';


interface SettingsProps {
  setShouldHideHeaderFooter: React.Dispatch<React.SetStateAction<boolean>>;
}

const Settings: React.FC<SettingsProps> = ({ setShouldHideHeaderFooter }) => {
  const token = localStorage.getItem('access-token')
  const resume = localStorage.getItem('resume')

  // Assuming you have setShouldHideHeaderFooter in your component's props
  const location = useLocation();
  const pathname = localStorage.getItem('pathname');

  const [showmsg, setshowmsg] = React.useState(true);

  // Error Notification state and Function Start here 

  const [notification_text, setnotification_text] = React.useState('');
  const [error_notification_text, seterror_notification_text] = React.useState('');

  const [visible, setVisible] = React.useState(false);
  const [error_visible, seterror_Visible] = React.useState(false);

  const handleShowNotification = () => {
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const errorhandleShowNotification = () => {
    seterror_Visible(true);

    setTimeout(() => {
      seterror_Visible(false);
    }, 3000);
  };

  // Error Notification state and Function End here 

  const [showloader, setshowloader] = React.useState(true)

  const [fetching, setFetching] = React.useState<boolean>(false)
  const [feedbackMsg, setFeedbackMsg] = React.useState<string | null>(null)
  const [failure, setFailure] = React.useState<string | null>(null)
  const [userData, setUserData] = React.useState({
    firstName: '',
    lastName: '',
    majors: '',
    country: '',
    city: '',
    institute: '',
    email: '',
    username: '',
  })

  const toast = useToast()

  const handleSubmit = (values: FormikValues, { setSubmitting }: any) => {
    User.update(values)
      .then((res) => {
        setUserData((previous) => {
          return { ...previous, ...res.data.data.user }
        })
        setFeedbackMsg(res.data.message)
        toast({
          title: 'Info Updated.',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      })
      .catch((error) => {
        setFailure(error.response.data.errors[0].title)
        toast({
          title: 'Failed to update info.',
          description: error.response.data.errors[0].title,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const UpdateSchema = Yup.object().shape({
    firstName: Yup.string().min(1).max(15, 'Too long value for a first name.'),
    lastName: Yup.string().min(1).max(15, 'Too long value for a first name.'),
    city: Yup.string()
      .min(2, 'Too short value for a city name.')
      .max(25, 'Too long value for a city name.'),
    country: Yup.string()
      .min(2, 'Too short value for a country name.')
      .max(25, 'Too long value for a country name.'),
    institute: Yup.string()
      .min(2, 'Too short value for an institute name.')
      .max(50, 'Too long value for an institute name.'),
    majors: Yup.string()
      .min(2, 'Too short value for a descipline name.')
      .max(50, 'Too long value for a descipline name.'),
  })



  const [email, setemail] = React.useState('')
  const [username, setusername] = React.useState('')
  const [date_of_birth, setdate_of_birth] = React.useState('')
  const [city, setcity] = React.useState('')
  const [state, setstate] = React.useState('')
  const [country, setcountry] = React.useState('')
  const [institute, setinstitute] = React.useState('')
  const [majors, setmajors] = React.useState('')
  const [cv_file_name, setcv_file_name] = React.useState('')


  const [upload_cv_heading, setupload_cv_heading] = React.useState(true)
  const [replace_cv, setreplace_cv] = React.useState(true)
  const [api_replace_cv, setapi_replace_cv] = React.useState(true)
  const [file, setFile] = React.useState<File | null>(null);

  const [new_cv_upload, setnew_cv_upload] = React.useState(true)


  const navigate = useNavigate()

  React.useEffect(() => {
    if (pathname === '/Account-Activate/') {
    } else {
      validate_session_function();
    }
  }, [])

  const validate_session_function = async () => {

    if (token === null) {

      localStorage.clear()
      navigate('/');

    } else {

      try {
        const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token)
        if (res.data.status === "200") {
          retrieve_user_account_details();
        }
      } catch (error) {
        localStorage.clear()
        navigate('/');
      }

    }

  }


  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current!.click(); // Non-null assertion
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      const url = URL.createObjectURL(selectedFile);
      // Extracting file name from URL
      const fileName = url.split('/').pop() || '';
      console.log('File name:', fileName);
      console.log('File name url:', url);
      console.log('File name selectedFile:', selectedFile.name);
      setcv_file_name(selectedFile.name)
      setnew_cv_upload(false)

    } else {
      setnew_cv_upload(true)
      setFile(null);
    }
  };

  const readFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      setFile(selectedFile);
      const url = URL.createObjectURL(selectedFile);
      // Extracting file name from URL
      const fileName = url.split('/').pop() || '';
      console.log('File name:', selectedFile.name);
    } else {
      setFile(null);
    }
  };

  React.useEffect(() => {
    retrieve_user_account_details();
  }, [])

  const retrieve_user_account_details = async () => {

    console.log(token);

    setshowloader(false)

    try {

      const response = await axios.get(back_end_base_url + 'service/get/account-details',
        {
          headers: {
            'Authorization': "Bearer " + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.data.status === "200") {
        setshowloader(true)
        console.log(response);


        setcv_file_name(response.data.msg[0].email)

        setemail(response.data.msg[0].email)
        setusername(response.data.msg[0].username)
        setdate_of_birth(response.data.msg[0].dob);
        setcity(response.data.msg[0].city)
        setstate(response.data.msg[0].state)
        setcountry(response.data.msg[0].country)
        setinstitute(response.data.msg[0].education_institute)
        setmajors(response.data.msg[0].majors)
        setFile(response.data.msg[0].resume)

        localStorage.setItem('resume', response.data.msg[0].resume);


        if (!response.data.msg[0].resume) {
          setreplace_cv(false);
          setapi_replace_cv(false);
          setupload_cv_heading(true);
          setnew_cv_upload(false);
        } else {
          setapi_replace_cv(true);
          setreplace_cv(true);
          setupload_cv_heading(false);
          setnew_cv_upload(true);
        }




      }


    } catch (error: any) {
      setshowloader(true)
      seterror_notification_text(error.response.data.msg)
      errorhandleShowNotification();
    }

  }

  const submit_user_account_details = async () => {

    if (email === '') {
      seterror_notification_text("Please Enter a Email")
      errorhandleShowNotification();
    } else if (username === '') {
      seterror_notification_text("Please Enter a Username")
      errorhandleShowNotification();
    } else if (date_of_birth === '') {
      seterror_notification_text("Please Enter a Date of Birth")
      errorhandleShowNotification();
    } else if (city === '') {
      seterror_notification_text("Please Enter a City")
      errorhandleShowNotification();
    } else if (state === '') {
      seterror_notification_text("Please Enter a state")
      errorhandleShowNotification();
    } else if (country === '') {
      seterror_notification_text("Please Enter a Country")
      errorhandleShowNotification();
    } else if (file === null) {
      seterror_notification_text("Please Upload a Resume")
      errorhandleShowNotification();
    } else if (institute === '') {
      seterror_notification_text("Please Enter a Institute")
      errorhandleShowNotification();
    } else if (majors === '') {
      seterror_notification_text("Please Enter a Majors")
      errorhandleShowNotification();
    } else {

      setshowloader(false)

      if (new_cv_upload === true) {

        try {

          let body = {
            username: username,
            dob: date_of_birth,
            city: city,
            state: state,
            country: country,
            education_institute: institute,
            majors: majors,
            resume: file
          }

          const response = await axios.put(back_end_base_url + 'service/account-details-update/', body,
            {
              headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json',
              },
            }
          )

          if (response.data.status === "200") {
            setshowloader(true)
            setnotification_text(response.data.msg);
            handleShowNotification();


            setTimeout(() => {

              if (pathname === '/Account-Activate/') {
                localStorage.setItem('pathname', location.pathname);
                navigate('/dashboard');
              } else {
                window.location.reload();
              }

            }, 1500);
          }


        } catch (error: any) {
          setshowloader(true)
          seterror_notification_text(error.response.data.msg)
          errorhandleShowNotification();
        }

      } else {

        try {

          const form = new FormData();
          if (file) {
            form.append('file', file)
            form.append('type', 'resume')
          }

          const res = await axios.post(
            back_end_base_url + 'service/cloudinary/upload-file',
            form,
            {
              headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          if (res.status === 200) {
            // console.log(res.data);

            try {

              let body = {
                username: username,
                dob: date_of_birth,
                city: city,
                state: state,
                country: country,
                education_institute: institute,
                majors: majors,
                resume: res.data.msg
              }

              const response = await axios.put(back_end_base_url + 'service/account-details-update/', body,
                {
                  headers: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'application/json',
                  },
                }
              )

              if (response.data.status === "200") {

                localStorage.setItem('resume', res.data.msg);

                setshowloader(true)
                setnotification_text(response.data.msg);
                handleShowNotification();


                setTimeout(() => {

                  if (pathname === '/Account-Activate/') {
                    localStorage.setItem('pathname', location.pathname);
                    navigate('/dashboard');
                  } else if (resume === "null") {
                    localStorage.setItem('pathname', location.pathname);
                    navigate('/dashboard');
                  }
                  else {
                    window.location.reload();
                  }

                }, 1500);
              }


            } catch (error: any) {
              setshowloader(true)
              seterror_notification_text(error.response.data.msg)
              errorhandleShowNotification();
            }

          }

        } catch (error: any) {
          setshowloader(true)
          seterror_notification_text(error.response.data.msg)
          errorhandleShowNotification();
        }

      }

    }

  }








  React.useEffect(() => {

    // Check if the current route is '/Account-Activate/' or '/register'
    if (pathname === '/Account-Activate/') {
      setShouldHideHeaderFooter(true);
      setshowmsg(true);
      // localStorage.setItem('pathname', location.pathname);
    } else if (resume === "null") {
      setShouldHideHeaderFooter(true);
      setshowmsg(true);
    } else {
      setshowmsg(false);
      setShouldHideHeaderFooter(false);
    }
  }, [pathname]);


  return (

    <>
      {/* notification popup box Start here */}
      <div className={`modal ${visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box">
            <p>
              {notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* notification popup box End here */}

      {/* Error notification popup box Start here */}
      <div className={`modal ${error_visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box" style={{ backgroundColor: '#980f0f' }}>
            <p id='error_message'>
              {error_notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* Error notification popup box End here */}


      {showloader ? (
        <></>
      ) : (
        <div className="main_loader_div" id="main_loader_div">
          <div className="main_loader_inner_div">
            <span className="loader"></span>
          </div>
        </div>
      )}


      <div className='main_faq_page_div'>
        <React.Fragment>
          <Container maxW={'6xl'} mt={10} mb={10}>

            {showmsg ? (
              <Text
                fontWeight={600}
                fontSize={{ base: '3xl', sm: '3xl', md: '3xl' }}
                maxW={'5xl'}
                mb={10}
                color={"#1F487C"}
              >
                You are only a few steps away from completing your profile 😀
              </Text>
            ) : (
              <></>
            )}

            <Text
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
              maxW={'3xl'}
              mb={10}
            >
              Account Settings
            </Text>

            <div>
              <Text fontSize={{ base: '20px', sm: '25px', md: '20px' }} mb={5}>
                Personal
              </Text>

              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
                mb={2}
              >
                <FormControl id="email" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    Email Address
                  </FormLabel>
                  <Input
                    disabled
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />

                </FormControl>
                <FormControl id="username" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    Username
                  </FormLabel>
                  <Input
                    type="text"
                    name="username"
                    value={username}
                    onChange={(e) => {
                      setusername(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl id="date_of_birth" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    Date of Birth
                  </FormLabel>
                  <Input
                    type="date"
                    name="date_of_birth"
                    value={date_of_birth}
                    onChange={(e) => {
                      setdate_of_birth(e.target.value)
                    }}
                  />
                </FormControl>
              </Stack>
              {/* <Text fontSize={{ base: '20px', sm: '25px', md: '20px' }} mb={5}>
                Location
              </Text> */}
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
                mb={10}
              >
                {/* Existing code for City */}
                <FormControl id="city" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    City
                  </FormLabel>
                  <Input
                    type="text"
                    name="city"
                    value={city}
                    onChange={(e) => {
                      setcity(e.target.value)
                    }}
                  />
                </FormControl>

                {/* Existing code for State */}
                <FormControl id="state" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    State
                  </FormLabel>
                  <Input
                    type='text'
                    name="state"
                    value={state}
                    onChange={(e) => {
                      setstate(e.target.value);
                    }}
                  />
                </FormControl>

                {/* Existing code for Country */}
                <FormControl id="country" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    Country
                  </FormLabel>
                  <Select
                    name="country"
                    value={country}
                    onChange={(e) => {
                      setcountry(e.target.value);
                    }}
                    placeholder="Select Your Country"
                  >
                    {COUNTRIES.map((country) => (
                      <option value={country.name} key={country.code}>
                        {country.name}
                        {/* &emsp; {country.emoji} */}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              {upload_cv_heading ? (
                <div className='upload_cv_heading'> Upload Your CV </div>
              ) : (
                <div style={{ fontSize: "1rem", fontWeight: "600", border: "1px solid gray", width: "fit-content", padding: "0.2rem 1rem", marginBottom: "1rem" }}>
                  Upload Your CV
                </div>
              )}

              {/* <Text fontSize={{ base: '20px', sm: '25px', md: '20px' }} mb={5} style={{ border: "1px sold gray" }}>
                Upload Your CV
              </Text> */}
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
                mb={10}
              >
                {/* Existing code for City */}
                <FormControl id="city" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    CV
                  </FormLabel>

                  {replace_cv ? (
                    ""
                  ) : (
                    <>
                      <Input
                        style={{ paddingTop: "0.2rem" }}
                        type="file"
                        name="file"
                        accept=".pdf, .doc, .docx, .rtf"
                        onChange={readFile}
                      />
                      <br />
                      <br />
                    </>
                  )}

                  {api_replace_cv ? (
                    <div className='main_resume_div'>
                      <img src={resume_img} alt="" />
                      {cv_file_name}_cv
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx, .rtf"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                      />
                      <button onClick={handleButtonClick}> Replace CV </button>
                    </div>
                  ) : (
                    ''
                  )}

                </FormControl>

              </Stack>

              <Text fontSize={{ base: '20px', sm: '25px', md: '20px' }} mb={5}>
                Education
              </Text>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
              >
                <FormControl id="institute" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    Educational Institute
                  </FormLabel>
                  <Input
                    type="text"
                    name="institute"
                    value={institute}
                    onChange={(e) => {
                      setinstitute(e.target.value)
                    }}
                  />
                </FormControl>

                <FormControl id="majors" mb={1}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    <span style={{ color: "#980f0f", fontSize: "1rem", fontWeight: "bold", paddingRight: "0.2rem" }}>*</span>
                    Descipline or Majors
                  </FormLabel>
                  <Input
                    type="text"
                    name="majors"
                    value={majors}
                    onChange={(e) => {
                      setmajors(e.target.value)
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack spacing={6} mt={10} maxW={'xs'}>
                <Button
                  type="submit"
                  rounded={'full'}
                  mt={5}
                  px={6}
                  colorScheme={'orange'}
                  bg={'#68c2e8'}
                  _hover={{ bg: '#0e1e40' }}
                  onClick={submit_user_account_details}
                >
                  Save Changes
                </Button>
              </Stack>
            </div>
          </Container>
        </React.Fragment>
      </div>
    </>
  )
}

export default Settings;