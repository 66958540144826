import React, { useEffect, useState, useRef } from 'react';
import './User_Guide.css';

export const User_Guide = () => {
    return (
        <>
            <div className="main_faq_page_div">

                <div className='main_user_guide_div'>
                    <div className='inner_user_guide_div'>
                        <div>
                            <h1>Welcome to the User Guide!</h1>
                        </div>

                        <div>
                            <p>
                                Explore our Equivalence page to submit course information easily.
                                <br />
                                Watch our quick tutorial for a guided tour:
                            </p>
                        </div>

                        <div className='main_User_Guide_video_div'>
                            <video controls>
                                <source src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681643747-user_guide.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className='main_User_Guide_pdf_heading_div'>
                            <p>Additionally, refer to the PDF below for a visual guide:</p>
                        </div>
                        <div className='main_User_Guide_pdf_div'>
                            <iframe
                                src='https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712680577267-ASEI Certificate rev3.pdf'
                                title="User Guide PDF"
                                width="85%"
                                height="650px"
                            ></iframe>
                        </div>
                        <div className='main_User_Guide_image_heading_div'>
                            <p>Additionally, refer to the Image below for a visual guide:</p>
                        </div>
                        <div className='main_User_Guide_image_div'>
                            <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/ASEI Certificate rev3_page-0001.jpg" alt="" />
                            <img src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/ASEI Certificate rev3_page-0002.jpg" alt="" />
                        </div>

                        <div>
                            <p>Preparing for Skills Evaluation: Essential Information You Need to Submit</p>
                            <br />
                            <div className='main_certificate_request_field_div '>
                                <ol>
                                    <li>
                                        Name
                                    </li>
                                    <li>
                                        Date of Birth
                                    </li>
                                    <li>
                                        City, State, Country of Residence
                                    </li>
                                    <li>
                                        Last School, Institute or University attended, and major.
                                    </li>
                                    <li>
                                        Name of Course (to be evaluated) (you can do multiple courses, repeating the process each time as described below)
                                    </li>
                                    <li>
                                        Copy of Course Certificate completed (in pdf or jpg form)
                                    </li>
                                    <li>
                                        Course Outlines or Description (in pdf or as Word document, or as text)
                                    </li>
                                    <li>
                                        Date Course started.
                                    </li>
                                    <li>
                                        Date Course completed
                                    </li>
                                    <li>
                                        Total hours completed
                                    </li>
                                    <li>
                                        Name of Institute from where course completed.
                                    </li>
                                    <li>
                                        Country of Institute
                                    </li>
                                    <li>
                                        Website of Institute
                                    </li>
                                    <li>
                                        Contact person at the Institute
                                    </li>
                                    <li>
                                        Email of contact person at the Institute
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    );
};
