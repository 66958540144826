import { AxiosClient } from "../client"

interface IBadge {
  title: string
  complexity?: string
}

export class Badge {
  public static add(body: IBadge) {
    return AxiosClient.post('badge', body, false, true);
  }
}