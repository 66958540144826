import React, { useEffect, useState } from 'react';
// import './blog_review_1.css';
import { useLocation, useNavigate } from 'react-router-dom';
import blog_img1 from '../../assets/images/skills_for_success_blog_img_one.jpeg';
import blog_img2 from '../../assets/images/skills_for_success_blog_img_two.jpeg';
import blog_img3 from '../../assets/images/skills_for_success_blog_img_three.jpeg';


export const Blog_review_6 = () => {

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);
    return (
        <>
            <div className='main_Blog_review_1_div'>
                <div className='inner_Blog_review_1_div'>

                    <div className='main_show_blog_review_page'>
                        <div className='inner_show_blog_review_page1'>

                            <div className='review_blog_page_heading'>
                                <h1>Skills For Success</h1>
                            </div>

                            <p>
                                The Pakistan IT industry is projected to cross $3 billion in 2024, while the annual growth rate is expected to be over 12 per cent, resulting in a market volume of $5 billion by 2028.
                            </p>

                            <div className='review_blog_page_heading'>
                                <img src={blog_img1} alt="Image 1" className='blog_image' />
                            </div>

                            <p>
                                Estimates push annual exports to $10 billion over the next three years. A significant part of IT services are also exported by over eight million freelancers through platforms such as Upwork and Fiverr.
                            </p>

                            <p>
                                Some of the most sought after services include artificial intelligence and machine learning, cloud computing, cybersecurity, data science, mobile app development, full-stack development, game development, virtual reality/augmented reality and Metaverse among others. While Pakistan earned $397 million through freelancing in FY 2021-22, estimates suggest that freelancers will earn another $3 billion in 2024.
                            </p>

                            <p>
                                In the last one year, the government has facilitated the development of the IT industry, and has included its incorporation into the Special Investment Facilitation Council (SIFC), allowing 50 per cent retention of dollars in forex bank accounts. It also plans to introduce Paypal and similar financial services to Pakistan.
                            </p>

                            <p>
                                In today's evolving job market, the importance of acquiring and learning IT skills that are in demand cannot be overstated. While two-thirds of job openings in the US and other developed countries require a degree, the remaining one-third demand post-secondary vocational training and skill certification. This reflects a growing recognition that a degree does not necessarily guarantee income or employment. Instead, the focus is shifting towards acquiring relevant and recognized IT skills that meet the demands of employers and the job market.

                            </p>

                            <div className='review_blog_page_heading'>
                                <img src={blog_img2} alt="Image 2" className='blog_image' />
                            </div>

                            <p>
                                It has become a normal trend that the corporate world is increasingly investing more and more time and resources to retrain employees in relevant skills so that they can deliver effectively. This underscores the existing skill gap in the IT workforce. Today, in the US alone, over eight million jobs remain unfilled due to a shortage of professionals with the required necessary technical skills, particularly in the IT sector.

                            </p>

                            <p>
                                A game changer today has become the emphasis on skills over just degrees. In an era characterized by disruptive technology, leading employers, including IBM, Google, Apple, and Tesla are re-evaluating educational prerequisites. Most job advertisements from these organizations place greater emphasis on relevant and recognized skills and credentials (micro-credentials) over traditional degrees. This shift, among other factors, also includes the higher cost of acquiring a degree, which has led to a decline in university enrolments particularly in the US, as students increasingly prefer short-term skills over lengthy degree programmes.
                            </p>

                            <p>
                                The trend indicates a significant change in educational priorities, with a growing focus on acquiring practical, job-ready skills through micro-credentials.
                            </p>

                            <p>
                                The post-pandemic job market has evolved to prioritize applicants with the right credentials, further emphasizing skills over mere academic degrees. As a result, individuals holding recognized micro-credentials are experiencing heightened demand for employment and outsourcing.
                            </p>

                            <p>
                                The pandemic accelerated the need for professionals who can quickly adapt to new roles and technologies, making short-term skill certification more important than ever before. Hence the number of certifications offered around the world have grown exponentially, while some degree programmes have actually tapered down or have been phased out altogether.
                            </p>

                            <p>
                                There are currently over a million unique post-secondary credentials offered in the US alone. These include over 350,000 degrees and certificates, over 13,000 course completion certificates, micro-credentials, and online degrees offered from foreign universities, over 650,000 certificates, licences, certifications, and apprenticeships, and over 56,000 diplomas from public and private secondary schools, alternative certificates, and high school equivalency diplomas, according to transparency non-profit Credential Engine.
                            </p>


                            <p>
                                This extensive array of credential offerings reflects the diverse pathways available for acquiring recognized skills and certifications. Most of these credentials are recognized by various accreditation bodies which is the norm and is usually required by potential employers.
                            </p>
                            <p>
                                Besides this, there are millions of self-learners who have reached different levels of proficiency and seek employment and/or freelancing contracts. Despite the high demand for professionals with recognized credentials, self-learners and those from unrecognized institutions, such as those from developing countries, face significant challenges in their being accepted at face value for employment or outsourcing.
                            </p>
                            <p>
                                Industry leaders prefer candidates who have recognized certificates, or those who have undergone proper training and testing from accredited institutions. It is therefore imperative for self-learners to seek evaluation and testing of their skills from credible and accredited organizations to have their credentials recognized. This validation is crucial for ensuring their skills are valued in the job market to seek employment and/or freelancing contracts.
                            </p>

                            <p>
                                As a result, more and more institutions globally are offering recognized post-secondary certification programmes. However, graduates, particularly from Pakistan and from lesser-known institutions, and self-learners often struggle to have their certificates acknowledged by major employers or from those who outsource their work.
                            </p>

                            <p>
                                In addition, this growing demand for evaluation and accreditation of skill certificates is also essential for licensing and visa purposes. The recognition of such credentials will facilitate a more inclusive and dynamic global workforce in Pakistan.
                            </p>

                            <div className='review_blog_page_heading'>
                                <img src={blog_img3} alt="Image 3" className='blog_image' />
                            </div>


                            <p><strong style={{ color: '#195389' }}>Note:</strong> This article is based on a keynote lecture delivered at the National Skills University, Islamabad.
                                The writer is a former senator and former chairperson of the HEC.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
