import * as React from 'react'
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Link,
} from '@chakra-ui/react'
import { FaBars } from 'react-icons/all'
import { CgClose, FaChevronRight, FaChevronDown } from 'react-icons/all'
import logoStandard from '../../../assets/logo/logoRight.png'
import { Link as RouterLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import './header.css'
import phone from '../../../assets/icons/phone.png'
import email from '../../../assets/icons/email.png'
import "boxicons/css/boxicons.min.css";
import back_end_base_url from '../../../services/back_end_url';
import axios from 'axios';


interface MobileNavItemProps {
  label: string;
  href?: string;
  onClick?: () => void;
}
interface NavItem {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'About us',
    href: '/aboutus',
  },
  {
    label: 'Accreditation',
    href: '/accreditation',
  },
  {
    label: 'Contact us',
    href: '/Contact-us',
  },
  {
    label: 'FAQ',
    href: '/faq',
  },
  {
    label: 'Blogs',
    href: '/Blogs',
  },
  {
    label: 'User Guide',
    href: '/User-Guide',
  },
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  // {
  //   label: 'New Badge',
  //   href: '/equivalence',
  // }
  // ,
  {
    label: 'Account',
    href: '#',
    children: [
      {
        label: 'History',
        subLabel: 'Your verification history so far',
        href: '/history',
      },
      {
        label: 'Setting',
        subLabel: 'Update your personal information',
        href: '/settings',
      },
      // {
      //   label: 'Profile',
      //   subLabel: 'Your public SOQA-Creds profile',
      //   href: `/profile/${localStorage.getItem('username')}`,
      // },
    ],
  },
]

const NAV_ITEMSList: Array<NavItem> = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'About us',
    href: '/aboutus',
  },
  {
    label: 'Accreditation',
    href: '/accreditation',
  },
  {
    label: 'Contact us',
    href: '/Contact-us',
    // children: [
    //   {
    //     label: 'History',
    //     subLabel: 'Your verification history so far',
    //     href: '/history',
    //   },
    //   {
    //     label: 'Setting',
    //     subLabel: 'Update your personal information',
    //     href: '/settings',
    //   },
    //   {
    //     label: 'Profile',
    //     subLabel: 'Your public SOQA-Creds profile',
    //     href: `/profile/${localStorage.getItem('username')}`,
    //   },
    // ],
  },
  {
    label: 'Faq',
    href: '/faq',
  },
  {
    label: 'Blogs',
    href: '/Blogs',
  },
  {
    label: 'User Guide',
    href: '/User-Guide',
  },
]

const mobile_NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'About us',
    href: '/aboutus',
  },
  {
    label: 'Accreditation',
    href: '/accreditation',
  },
  {
    label: 'Contact us',
    href: '/Contact-us',
  },
  {
    label: 'Faq',
    href: '/faq',
  },
  {
    label: 'Blog',
    href: '/Blogs',
  },
  {
    label: 'User Guide',
    href: '/User-Guide',
  },
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  // {
  //   label: 'New Badge',
  //   href: '/equivalence',
  // }
  // ,
  // {
  // label: 'Account',
  // href: '#',
  // children: [
  //   {
  //     label: 'History',
  //     subLabel: 'Your verification history so far',
  //     href: '/history',
  //   },
  //   {
  //     label: 'Setting',
  //     subLabel: 'Update your personal information',
  //     href: '/settings',
  //   },
  // ],
  // },
  {
    label: 'History',
    href: '/history',
  }
  ,
  {
    label: 'Setting',
    href: '/settings',
  }
  ,
]

export const Header = (props: { auth: boolean }) => {
  const { isOpen, onToggle } = useDisclosure()
  const navigate = useNavigate();

  const token = localStorage.getItem('access-token')

  // React.useEffect(() => {
  //   if (!token) {
  //     navigate('/login');
  //   }
  // }, [])

  // const validate_session_function = async () => {

  //   if (token === null) {

  //     localStorage.clear()
  //     navigate('/');

  //   } else {

  //     try {
  //       const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token)
  //       if (res.data.status === "200") {
  //       }
  //     } catch (error) {

  //       setTimeout(() => {
  //         localStorage.clear()
  //         navigate('/');
  //       }, 3000);
  //     }

  //   }

  // }




  const handleSignOut = () => {
    localStorage.clear()
    navigate('/');

    window.location.reload()
  }

  return (
    <Box className="main_header">
      <Flex

        bg={useColorModeValue('#195389', '#195389')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'40px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        justify={'flex-end'}
        align={'center'}
      >
        <Button
          as={'a'}
          fontSize={'sm'}
          color={'white'}
          fontWeight={400}
          variant={'link'}
        >
          <i className="fa fa-brands fa-whatsapp icon"></i>
          &nbsp;
          +1 (905) 510-0807
        </Button>
        &nbsp;
        &nbsp;
        &nbsp;
        <Button
          as={'a'}
          fontSize={'sm'}
          color={'white'}
          fontWeight={400}
          variant={'link'}
        >
          <i className='bx bx-mail-send icon'></i>
          &nbsp;
          support@skillsevaluation.org
        </Button>

      </Flex>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? (
                <CgClose width={3} height={3} />
              ) : (
                <FaBars width={5} height={5} />
              )
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <RouterLink to="/">
            <img src={logoStandard} width={250} />
          </RouterLink>


        </Flex>
        <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
          <DesktopNav auth={props.auth} />
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}
          >
            {!props.auth ? (
              <RouterLink to="/login">
                <Button
                  as={'a'}
                  fontSize={'sm'}
                  fontWeight={500}
                  variant={'link'}
                  href={'#'}
                  mt={3}
                >
                  Sign In
                </Button>
              </RouterLink>
            ) : (
              <Button
                as={'a'}
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
                href={'#'}
                onClick={handleSignOut}
              >
                Sign Out
              </Button>
            )}
            {!props.auth && (
              <RouterLink to="/register">
                <Button
                  as={'a'}
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#195389'}
                  href={'#'}
                  _hover={{
                    bg: '#0e1e40',
                  }}
                >
                  Sign Up
                </Button>
              </RouterLink>
            )}
          </Stack>
        </Flex>

      </Flex>

      {/* {props.auth && ( */}
      <Collapse in={isOpen} animateOpacity>
        <MobileNav auth={props.auth} handleSignOut={handleSignOut} />
      </Collapse>
      {/* )} */}
    </Box>
  )
}

const DesktopNav = ({ auth }: any) => {
  const linkColor = useColorModeValue('gray.700', 'gray.200')
  const linkHoverColor = useColorModeValue('#68c2e8', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4} align={'center'} mr={5}>
      {auth ? NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} >
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      )) : NAV_ITEMSList.map((navItem) => (
        <Box key={navItem.label} >
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}

                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <RouterLink to={href ?? '#'}>
      <Link
        href={href}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('blue.100', 'blue.100') }}
      >
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: '#0e1e40' }}
              fontWeight={500}
            >
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}
          >
            <Icon color={'#0e1e40'} w={2} as={FaChevronRight} />
          </Flex>
        </Stack>
      </Link>
    </RouterLink>
  )
}

const MobileNav = ({ auth }: any) => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear()
    navigate('/');

    window.location.reload()
  }

  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {auth && (
        <>
          {mobile_NAV_ITEMS.map((navItem) => (
            <MobileNavItem key={navItem.label} {...navItem} />
          ))}
        </>
      )}
      {!auth && (
        <>
          {NAV_ITEMSList.map((navItem) => (
            <MobileNavItem key={navItem.label} {...navItem} />
          ))}
        </>
      )}
      {!auth && (
        <>
          <MobileNavItem label="Login" href="/login" />
          <MobileNavItem label="Sign Up" href="/register" />
        </>
      )}
      {auth && (
        <Button
          as={'a'}
          fontSize={'sm'}
          fontWeight={400}
          variant={'link'}
          href={'#'}
          onClick={handleSignOut}
        >
          Sign Out
        </Button>
      )}
    </Stack>
  );
}

const MobileNavItem = ({ label, href, onClick }: MobileNavItemProps) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        href={href || '#'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          fontWeight={500}
          color={useColorModeValue('gray.800', 'gray.200')}
        >
          {label}
        </Text>
      </Flex>
      {/* {onClick && (
        <Link py={2} onClick={onClick}>
          Sign Out
        </Link>
      )} */}
    </Stack>
  );
}

