import { AxiosClient } from '../client'

export class Analytics {
  public static get() {
    return AxiosClient.get('badge/insights', false, true)
  }

  public static getcertificate() {
    const queryParams = new URLSearchParams(window.location.search);
    return AxiosClient.get('badge/insights' + queryParams.get('badge_Id'), false, true)
  }

  public static updateVerifications() {
    return AxiosClient.patch('analytics/update-verifications', {}, true)
  }

  public static getHistory() {
    return AxiosClient.get('badge/history', false, true)
  }

  public static getHistoryById(id: string) {
    return AxiosClient.get(`badge/history/${id}`, false, true)
  }

  public static updateHistoryStatus(id: string, status: string) {
    return AxiosClient.patch(`badge/history/${id}/status`, { status }, true)
  }
}
