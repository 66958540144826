import React, { useEffect, useState } from 'react';
import './blog_review_1.css';
import { useLocation, useNavigate } from 'react-router-dom';


export const Blog_review_1 = () => {
    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);

    return (
        <>
            <div className='main_Blog_review_1_div'>
                <div className='inner_Blog_review_1_div'>

                    <div className='main_show_blog_review_page'>
                        <div className='inner_show_blog_review_page1'>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h1>How to know if your Short Course is worth the money you spent on it</h1>
                                    </div>
                                    <p>
                                        If you recently completed a short course and are wondering if it was worth your time and money, this guide will help you evaluate the quality and value of your learning experience. By considering various factors, such as the course content, instructor, and your own personal goals, you can determine if the course met your expectations and if it was a worthwhile investment.
                                    </p>
                                </div>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://res.cloudinary.com/dabkyosdo/image/upload/v1707044309/web_assets/Person-earning-money-300x214.png_eca73ab5-a3d4-4859-930d-fbc140f30c31.png" alt="Image 1" />
                                    </div>
                                </div>
                            </div>

                            <div className='hold_left_and_right_blog_review_div'>
                                <div className='blog_review_image_div'>
                                    <div className='review_blog_page_heading'>
                                        <img src="https://res.cloudinary.com/dabkyosdo/image/upload/v1707044287/web_assets/degree.jpeg_0549bf04-5297-4953-958a-bfa21450e798.jpg" alt="Image 2" />
                                    </div>
                                </div>
                                <div className='blog_review_content_div'>
                                    <div className='review_blog_page_heading'>
                                        <h2>Evaluate the Course Content and Structure.</h2>
                                    </div>
                                    <p>
                                        If you recently completed a short course and are wondering if it was worth your time and money, this guide will help you evaluate the quality and value of your learning experience. By considering various factors, such as the course content, instructor, and your own personal goals, you can determine if the course met your expectations and if it was a worthwhile investment.
                                        <br />
                                        <br />
                                        In evaluating the course content and structure, it is important to consider if the course covered the topics and skills that were promised. Did it provide the information that you were expecting to learn? Furthermore, assess if the information was presented in a clear and organized manner. A well-structured course will make it easier for you to understand and retain the information. Reviewing the course syllabus or outline can help determine if it aligns with your learning goals and expectations. Additionally, consider the depth and breadth of the content. Did the course provide a comprehensive overview or did it only touch on the surface of the subject? Evaluating the course content and structure will help you determine if the course provided value for the money you invested in it.
                                    </p>
                                </div>
                            </div>

                            <p>If you have taken any course in an IT related field, you can have it evaluated by American Skills Evaluation Institute &nbsp;
                                <b>
                                    (
                                    <a href="https://skillsevaluation.org/">https://skillsevaluation.org/</a>
                                    ).
                                </b>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
