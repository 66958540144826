import React, { useEffect, useState } from 'react';
// import './blog_review_1.css';
import { useLocation, useNavigate } from 'react-router-dom';
import blog_img from '../../assets/images/Importance of Skills.png';


export const Blog_review_5 = () => {

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);
    return (
        <>
            <div className='main_Blog_review_1_div'>
                <div className='inner_Blog_review_1_div'>

                    <div className='main_show_blog_review_page'>
                        <div className='inner_show_blog_review_page1'>

                            <div className='review_blog_page_heading'>
                                <h1>Importance of Skills</h1>
                            </div>

                            <p>
                                The education and employment landscape has experienced a notable shift due to recent developments, where acquiring relevant skills are becoming more important than degrees.
                            </p>

                            <div className='review_blog_page_heading'>
                                <img src={blog_img} alt="Image 2" className='blog_image' />
                            </div>

                            <p>
                                IBM in recent years has introduced an innovative program called Pathways in Technology Early College High School (P-TECH), enabling students who may not have considered college to earn a high school diploma alongside a skill certificate or associate degree.
                            </p>

                            <p>
                                Likewise, Google has launched customized certificate programs and job search experiences that align with candidates' specific education and experience. In a similar trend, Apple has also made a significant change by eliminating degree requirements for certain positions. Even Tesla recognizes the value of skills over specific degrees when identifying top candidates for employment.
                            </p>

                            <p>
                                Following this trend, many technology-based organizations have abandoned bachelor's degree requirements and instead emphasize alternative pathways that prioritize skill acquisition. As a result, hundreds of bootcamps and online training sites offering certificates lasting from three to six months have emerged just in the last five years.
                            </p>

                            <p>
                                The digitization of credentials has ushered in a new era of transparency in educational outcomes. Digital badges and other verifiable online credentials are increasingly considered equivalent to traditional diplomas and degrees. With the shift toward online learning and digital representation of credentials and certificates, employers are better equipped to evaluate candidates' skills and competencies.
                            </p>

                            <p>
                                This evolving landscape of credentialing and acquiring skill certificates has prompted the development of new corporate tools, services, and businesses as organizations incorporate these credentials into their talent strategies. Consequently, the demand for acquiring skills and experience is rising among American students, leading to a credentialing revolution. Traditional degrees provided by colleges are being challenged by digital alternatives such as micro-credentials, badges, certificates, and certifications.
                            </p>

                            <p>
                                Despite these remarkable advancements, there is currently no comprehensive platform available that can even evaluate, let alone aggregate and map all these credentials, including those obtained from MOOCs (Massive Open Online Courses), or any other institute to create a unified certification.
                            </p>

                            <p>
                                <b>  American Skills Evaluation Institute </b> (ASEI) the premier academic organization that evaluates all foreign certificates as per US standards, and provides to the candidate an equivalent certificate issued by ASEI, which is a brand that is immediately recognized globally.
                            </p>

                            <p>Take advantage of these services by registering online today
                                <b>
                                    (
                                    <a href="https://skillsevaluation.org/">https://skillsevaluation.org/</a>
                                    ).
                                </b>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
