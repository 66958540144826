import React, { useEffect, useState } from 'react';
// import './blog_review_1.css';
import { useLocation, useNavigate } from 'react-router-dom';
import blog_img1 from '../../assets/images/it_is_time_for_change_blog_img_two.jpeg';
import blog_img2 from '../../assets/images/it_is_time_for_change_blog_img_one.png';
import blog_img3 from '../../assets/images/it_is_time_for_change_blog_img_three.jpeg';


export const Blog_review_7 = () => {

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);
    return (
        <>
            <div className='main_Blog_review_1_div'>
                <div className='inner_Blog_review_1_div'>

                    <div className='main_show_blog_review_page'>
                        <div className='inner_show_blog_review_page1'>

                            <div className='review_blog_page_heading'>
                                <h1>It's Time For Change</h1>
                            </div>

                            <p>
                                In the swiftly changing world of innovation, we stand on the brink of a new era powered by revolutionary technologies set to transform every facet of our daily lives in the foreseeable future.
                            </p>

                            <div className='review_blog_page_heading'>
                                <img src={blog_img1} alt="Image 1" className='blog_image' />
                            </div>

                            <p>
                                Today, the top four companies globally by market cap -- Apple, Microsoft, NVIDIA, and Alphabet (Google) -- are all IT giants with a combined market cap exceeding $12 trillion. Close behind are Meta (Facebook) at $1.5 trillion and Tesla at $800 billion, ranked seventh and twelfth, respectively. This shift indicates that manufacturing, pharmaceutical, and oil and gas companies, which demand substantial investments, are no longer the dominant leaders in the global market.
                            </p>

                            <p>
                                Pakistan's IT industry is quite small, estimated to be just $3 billion in 2024. In contrast, IT exports from India, a country with only six times the population, amount to a staggering $200 billion. Despite having over 250 universities, more than 4000 IT certification institutes, producing over 20,000 IT graduates and engineers annually, and some of the lowest salaries globally, Pakistan has yet to make a significant impact globally in the IT sector.
                            </p>

                            <p>
                                What has gone wrong? We are aware of some of the issues: the poor quality of IT graduates, the difficulty of starting a business, and the lack of sufficient government incentives, such as affordable broadband internet, uninterrupted power supply, and flexible FBR policies. Pakistan needs to prioritize producing high-quality graduates, similar to those from India's IITs, rather than focusing on quantity. Hundreds of billions of rupees in the HEC budget have largely been wasted on brick-and-mortar infrastructure, resulting in more unemployed graduates.
                            </p>

                            <p>
                                Another significant issue is that IT and CS graduates from lesser-known institutions in Pakistan, as well as self-taught individuals, often struggle to have their credentials recognized by major employers or outsourcing companies. It is crucial that these graduates are evaluated and certified by an independent accredited organization to ensure their skills are globally recognized. This recognition will help create a more inclusive and dynamic global workforce from Pakistan.
                            </p>

                            <div className='review_blog_page_heading'>
                                <img src={blog_img2} alt="Image 2" className='blog_image' />
                            </div>

                            <p>
                                Therefore, to build a sustainable technology-driven knowledge economy, we should focus our investments on specific skill areas at top universities and education providers. This approach will create a critical mass of high-quality graduates instead of spreading resources too thin. Many of these technologies require quality education rather than extensive infrastructure.

                            </p>

                            <p>
                                While the number of game-changing technologies are many, if Pakistan could only focus on some of the following for skill building, and implement appropriate measures as outlined above, it may be able to make a big difference to its economy in just a few years.
                            </p>

                            <p>
                                One is Artificial Intelligence (AI), Machine Learning (ML), and Deep Learning (DL): These technologies have significantly impacted our lives in recent years, especially with the advent of generative AI, like ChatGPT. The AI market has surpassed $184 billion in 2024 and is projected to exceed $800 billion by 2030. Advancements in AI, ML, and DL will continue to transform every industry, from healthcare and finance to military applications and autonomous vehicles, and beyond.
                            </p>

                            <p>
                                Augmented Reality (AR), Virtual Reality (VR), Mixed Reality (MR), and the Metaverse: The global Metaverse market, valued at $92 billion in 2023, is projected to surpass $2 trillion by 2033. These technologies are increasingly used in gaming, simulation, education, healthcare, defence, and other sectors to create immersive experiences. The integration of the Metaverse with AR, VR, and MR will revolutionize how we live, entertain, and learn in the future.
                            </p>

                            <p>
                                Quantum computing: Although still in its early stages, quantum computing promises to solve complex problems that are currently beyond the reach of even the most advanced computers. The global quantum computing market, valued at $885 million in 2023, is projected to grow to $1.1 billion in 2024 and exceed $12 billion by 2032.
                            </p>

                            <p>
                                Robotics and automation: Ranging from autonomous vehicles to robotic process automation in various industries, robotics and automation technologies are constantly advancing to enhance efficiency and productivity. The global robotics market, valued at $46 billion in 2023, is projected to grow to $170 billion between 2024 and 2032.
                            </p>


                            <p>
                                Blockchain and cryptocurrencies: In addition to cryptocurrencies like Bitcoin, blockchain technology is being investigated for a wide range of uses, including secure and transparent supply chains, smart contracts, and decentralized finance (DeFi). The global blockchain market is expected to experience an increase from $20 billion in 2024 to $250 billion by 2029.
                            </p>
                            <p>
                                Internet of Things (IoT): In the future, everyday devices will be interconnected, allowing them to send and receive data seamlessly. This increasing connectivity will propel advancements in smart homes, cities, finance, defense, transportation, and daily activities like shopping. The global IoT market, valued at $330 billion in 2021, is expected to grow to $650 billion by 2026.
                            </p>
                            <p>
                                Cloud Computing (CC) and Data Science (DS): CC and DS are complementary fields that work together to store, process, and analyze data. CC/DS cap is estimated at $680 billion in 2024, and is expected to reach $ 1.44 trillion by 2029.
                            </p>

                            <p>
                                If Pakistan can cultivate high-quality skills in any of these areas and offer certain incentives, it may be able to increase IT exports significantly and enhance its economy.
                            </p>

                            <div className='review_blog_page_heading'>
                                <img src={blog_img3} alt="Image 3" className='blog_image' />
                            </div>


                            <p><strong style={{ color: '#195389' }}>Note:</strong> This article is based on a keynote lecture delivered at the National Skills University, Islamabad.
                                The writer is a former senator and former chairperson of the HEC.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
