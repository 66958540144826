import React, { useEffect, useState, useRef } from 'react';
import './Privacy_Policy.css';


export const Privacy_Policy = () => {

    return (
        <>
            <div className='main_privacy_policy_div'>

                <div className='inner_privacy_policy_div'>
                    <div className='inner_privacy_policy_div_sub_heading'>
                        <span>Hey</span> there, thanks for your interest in our skills evaluation process.  We're super excited to help you evaluate your credentials, and move ahead in your career and life goals. But before you sign up, we want to let you know how we handle your personal information and what rights you have.
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        First of all, we only collect the information that you voluntarily provide to us through our data entry forms, such as your name, email address, phone number, name and type of certificate and name and address of the institute where the certificate was obtained. This is necessary for our evaluation process to work properly.
                    </div>

                    <div className='inner_privacy_policy_div_heading'>
                        We use your information for the following purposes:
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        <p>
                            <b>-</b> To evaluate the certificates provided by you for level of expertise in the areas identified by our expert systems.
                        </p>
                        <p>
                            <b>-</b> To comply with any legal obligations or requests from authorities.
                        </p>
                        <p>
                            <b>-</b> Since our process is completely free, we do not ask for or process any financial information. If this changes in the future we will make the necessary changes to the privacy policy as needed.
                        </p>
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        We respect your privacy and we don't sell, rent, or share your information with any third parties without your consent. We only work with trusted service providers who help us evaluate your credentials in a professional manner. They only have access to the information that they need to perform their services and they are bound by strict confidentiality and security obligations.
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        You have the right to access, update, delete, or withdraw your consent for your information at any time. You can do this by contacting us at info@skillsevaluation.org or by clicking the unsubscribe link in any of our emails. We will respond to your request as soon as possible and within 30 days.
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        We take reasonable measures to protect your information from unauthorized access, use, disclosure, alteration, or destruction. However, no method of transmission or storage is 100% secure, so we cannot guarantee the absolute security of your information. You are responsible for keeping your password and access links confidential and for notifying us of any suspicious or unauthorized activity.
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        We may update this privacy policy from time to time to reflect changes in our online training and events or in the applicable laws. We will notify you of any material changes by email or by posting a notice on our website. Your continued use of our credential evaluation services after the update means that you accept the new policy.
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        If you have any questions or comments about this privacy policy or how we handle your information, please feel free to contact us at info@skillsevaluation.org. We're always happy to hear from you and to address any concerns you may have.
                    </div>

                    <div className='inner_privacy_policy_div_sub_heading'>
                        By submitting your information through our data entry forms, you agree to this privacy policy and consent to the collection and use of your information as described above.
                    </div>

                </div>

            </div>
        </>
    );
};
