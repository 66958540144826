import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import { UserAuth } from '../../services/api/auth'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { useNavigate } from 'react-router-dom';

function ErrorMessage(props: any) {
  return (
    <Text color={'red'} fontSize={12} mt={1}>
      {props.message}
    </Text>
  )
}

export default function Login() {

  const navigate = useNavigate();
  const token = localStorage.getItem('access-token');

  if (token === null) {
    // navigate('/')
  } else {
    navigate('/dashboard')
  }

  // Error Notification state and Function Start here 

  const [notification_text, setnotification_text] = React.useState('');
  const [error_notification_text, seterror_notification_text] = React.useState('');

  const [visible, setVisible] = React.useState(false);
  const [error_visible, seterror_Visible] = React.useState(false);

  const handleShowNotification = () => {
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, 3000); // Adjust the timeout value to match the notification duration
  };

  const errorhandleShowNotification = () => {
    seterror_Visible(true);

    setTimeout(() => {
      seterror_Visible(false);
    }, 3000); // Adjust the timeout value to match the notification duration
  };

  // Error Notification state and Function End here 




  const [loginFailure, setLoginFailure] = React.useState<boolean>(false)
  const validate = (values: FormikValues) => {
    const errors: any = {}
    if (values.password.length < 8) {
      errors.password = 'Password cannot be less than 8 characters'
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }

  const onSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    setLoginFailure(false);

    const body = {
      email: values.email,
      password: values.password,
    };

    try {
      const res = await UserAuth.login(body);

      const { auth, user } = res.data;


      localStorage.setItem('access-token', res.data.msg.access_token);
      localStorage.setItem('email', res.data.msg.user.email);
      localStorage.setItem('resume', res.data.msg.user.resume);
      localStorage.setItem('username', res.data.msg.user.username);

      // localStorage.setItem('access-token', res.data.msg);
      // localStorage.setItem('email', values.email);
      // localStorage.setItem('email', user.email);
      // localStorage.setItem('firstName', user.firstName);
      // localStorage.setItem('lastName', user.lastName);
      // localStorage.setItem('username', user.username);

      if (res.status === 201 || res.status === 200) {
        // navigate('/dashboard');
        // window.location.reload('/dashboard');

        // if (res.data.msg.user.resume === null) {
        //   // navigate('/settings');
        //   window.location.href = '/settings';
        // } else {
        //   // navigate('/dashboard');
        //   window.location.href = '/dashboard';
        // }

        window.location.href = '/dashboard';

      }
    } catch (error: any) {
      errorhandleShowNotification();
      seterror_notification_text(error.response.data.msg);
      console.error(error);  // Use console.error for error messages
      // setLoginFailure(true);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <>
      {/* notification popup box Start here */}
      <div className={`modal ${visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box">
            <p>
              {notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* notification popup box End here */}

      {/* Error notification popup box Start here */}
      <div className={`modal ${error_visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box" style={{ backgroundColor: '#980f0f' }}>
            <p id='error_message'>
              {error_notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* Error notification popup box End here */}

      <div className='main_faq_page_div'>
        <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              <Text
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                maxW={'3xl'}
              >
                Sign in to your account
              </Text>

              <Formik
                initialValues={{ email: '', password: '' }}
                validate={validate}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl id="email">
                      <FormLabel
                        color={'gray.600'}
                        fontWeight={400}
                        maxW={'3xl'}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Email address
                      </FormLabel>
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.email && touched.email && (
                      <ErrorMessage message={errors.email} />
                    )}
                    <FormControl id="password">
                      <FormLabel
                        color={'gray.600'}
                        maxW={'3xl'}
                        fontWeight={400}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Password
                      </FormLabel>
                      <Input
                        id="password"
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.password && touched.password && (
                      <ErrorMessage message={errors.password} />
                    )}
                    <Stack spacing={6}>
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}
                      >
                        <Checkbox color={'gray.600'}>Remember me</Checkbox>
                        <Link href="/forgot" color="gray.600">Forgot password?</Link>
                      </Stack>
                      {loginFailure && (
                        <ErrorMessage message={'Incorrect email or password'} />
                      )}
                      <Button
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                        rounded={'full'}
                        px={6}
                        colorScheme={'orange'}
                        bg={'#68c2e8'}
                        _hover={{ bg: '#0e1e40' }}
                      >
                        Sign in
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image alt={'Login Image'} objectFit={'cover'} src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681567766-study.webp" />
          </Flex>
        </Stack>
      </div>
    </>
  )
}
