import {
  Avatar,
  Box,
  chakra,
  Container,
  ButtonGroup,
  Divider,
  Stack,
  Link,
  Flex,
  Icon,
  SimpleGrid,
  Button,
  Card,
  CardFooter,
  CardBody,
  Image,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import './style.css'
import React from 'react'

// const testimonials = [
//   {
//     name: 'Dr. Wassim Khechen',
//     role: 'https://www.linkedin.com/in/wassim-khechen-8bb89a84',
//     content:
//       `PhD in Electrical and Computer Engineering from SUNY Buffalo, NY, USA, and specializes in space
//         power systems for NASA and other space systems. He has worked extensively on US defense
//         contracts from the US Navy and Air Force. He was previously the Venezuelan national champion
//         in Tae Kwon-Do and is presently the Chief Instructor and owner of Master Khechen School of
//         Tae Kwon-Do in the US. Dr. Khechen is a successful entrepreneur and is establishing the first
//         accredited Martial Arts University in the US.
//         `,
//     avatar: Person1
//   },
//   {
//     name: 'Dr. Martha Dillman ',
//     role: 'https://www.linkedin.com/in/martha-dillman-01867750/',
//     content:
//       `Advisor for Quality Assurance and Accreditation at the prestigious National Defence College,
//         Abu Dhabi, UAE for the last 10 years.
//         Prior to that, she has worked as manager for Quality Assurance at the National Qualifications
//         Authority (NQA), UAE for 3 years assisting in the development of the Emirates Qualifications
//         Framework, which set the standards for qualification certification and accreditation of higher
//         education institutions across the UAE.
//         Prior to that Dr. Dillman work as manager of quality assurance at the Department of Knowledge
//         and Education (ADEK) for another 3 years, bringing a total of 16 years of accreditation and
//         quality assurance experience to ASEI. Dr. Dillman has a PhD in Higher Education Administration
//         from the University of Central Florida, USA.
//         `,
//     avatar: Person2,
//   },

//   {
//     name: 'Dr. Javaid Laghari',
//     role: 'https://www.linkedin.com/in/dr-javaid-laghari-6504b715/',
//     content:
//       `Senior Higher Education Professional based in USA, with 30 years of
//         experience leading higher education in USA, Middle East and Pakistan. PhD in Electrical and
//         Computer Engineering from UBuffalo, USA`,
//     avatar: Person3
//   },
//   {
//     name: 'Dr. Imran Amin',
//     role: 'https://www.linkedin.com/in/drimran/',
//     content:
//       `20 years of teaching computer
//         Sciences and technology courses
//         Professor of Artificial Intelligence , SZABIST. Pakistan`,
//     avatar: Person4
//   },
//   {
//     name: 'Salman Abedin',
//     role: 'https://www.linkedin.com/in/salmanabedin/',
//     content:
//       `Salman Abedin, a College Professor in Toronto, Canada, currently educates students at the Toronto School of Management and Conestoga College, specializing in Digital Marketing. Holding three Master's Degrees, including an esteemed M.Des. from OCAD University in Toronto and an MS Degree from SZABIST University in Karachi, Salman has shared his expertise in Idea Generation, Product Design, and Digital Marketing Strategy at the College, Undergraduate, and Graduate levels in both Pakistan and Canada.`,
//     avatar: Person5
//   },

// ];

// interface TestimonialCardProps {
//   name: string;
//   role: string;
//   content: string;
//   avatar: string;
//   index: number;
// }

// function TestimonialCard(props: TestimonialCardProps) {
//   const { name, role, content, avatar, index } = props;
//   const [showMore, setShowMore] = React.useState<any>([]);

//   return (
//     <Card maxW='sm'>
//       <CardBody>
//         <Image
//           src={avatar}
//           alt=''
//           width='100%'
//           height='300px'
//           borderRadius='lg'
//         />
//         <Stack mt='6' spacing='3'>
//           <Heading size='md' textAlign={'start'}>{name}</Heading>
//           <Text textAlign={'start'}>
//             {content}
//           </Text>
//         </Stack>
//       </CardBody>
//       <Divider />
//       <CardFooter>
//         <Flex justifyContent={'center'} width='100%'>
//           <Link href={role} width='100%'>
//             <Button variant='solid' colorScheme='blue' width='90%'>
//               Linkedin
//             </Button>
//           </Link>
//         </Flex>
//       </CardFooter>
//     </Card>

//   );
// }

export default function GridBlurredBackdrop() {

  return (
    <div className='main_bgCard'>
      <Flex
        className='bgCard'
        textAlign={'center'}
        p={'2rem 0'}
        justifyContent={'center'}
        direction={'column'}
        width={'full'}
        overflow={'hidden'}>
        <Box
          // width={{ base: 'full', sm: 'lg', lg: 'xl' }} 
          margin={'auto'}>

          <div className='about_page_heading'>
            Global Credentials Evaluated and Tested Instantly
          </div>
        </Box>
        <div className='about_us_banner_div'>
          <chakra.h2
            fontSize={'17px'}
            width={'90%'}
            mx={'auto'}
            my={0}
            mb={'2rem'}
            fontWeight={'500'}
            color={'white.400'}>
            <p>
              American Skills Evaluation Institute (ASEI) is the premier provider of accredited services for the evaluation and testing as per US standards of short courses, skill certificates, diplomas and degrees, including self-learning courses, obtained globally for the purpose of seeking employment, freelancing, admission, immigration and visas, licensing boards and certification. When it comes to skill evaluation services, ASEI stands out as the trusted authority.
            </p>
            <br />
            <p>
              Our commitment to excellence, use of cutting-edge technology, adherence to US standards, and vast network of industry connections make us the premier choice for individuals and institutes seeking accurate credential evaluation, skill assessments and testing, and unlocking opportunities worldwide. ASEI also accredits institutions, colleges and universities around the world who seek global recognition for quality.
            </p>
            <br />
            <p>
              ASEI uses a state-of-the-art proprietary AI/ML engine to verify the skill level, evaluate credentials as per US standards and provides an equivalent globally recognized and accredited certificate to perspective students and professionals. Skill credential evaluations testing are developed in accordance with the established guidelines of the leading academic credential evaluation organizations, industry leaders and top tier US universities.
            </p>
            <br />
            <p>
              We connect skill inventories with employers and educational institutions. licensing boards and government agencies around the world. Join us at ASEI, and let us help you navigate the path to success.
            </p>
            <br />
            <p>
            ASEI is accredited by the <a href="https://www.qahe.org.uk/" target="_blank">Quality Assurance in Higher Education (QAHE)</a>. ASEI is also under process for accreditation by the Council of Higher Education Accreditation (CHEA) to accredit education providers which offer training certificates, short courses and micro-credentials. ASEI is also a candidate with the Department of Education ( DoE ) and with the Texas Higher Education Coordinating Board (THECB) as a registered organization.
            </p>

            <br />
            <p>
            ASEI is a subsidiary of SOQA LLC which is incorporated in the State of Delaware, USA. ASEI is based in Houston, TX.
            </p>
          </chakra.h2>
          <div className='left_button_home_inner_sections_div2'>
            <a href="/register">
              <button className="button"><span> Secure your Accredited and Globally Recognized ASEI Certificate </span></button>
            </a>
          </div>

        </div>


        <chakra.h2
          margin={'auto'}
          width={'70%'}
          fontSize={'35px'}
          mb={'2rem'}
          fontFamily={'Inter'}
          fontWeight={'medium'}
          color={useColorModeValue('white', 'gray.400')}>
          ADVISORY BOARD
        </chakra.h2>
        {/* <SimpleGrid
          columns={{ base: 1, xl: 2 }}
          spacing={'20'}
          mt={16}
          mb={16}
          mx={'auto'}>
          {testimonials.map((cardInfo, index) => (
            <TestimonialCard {...cardInfo} index={index} />
          ))}
        </SimpleGrid> */}
        <Box >


          <div className='main_BOARD_OF_ADVISORS_section'>
            <div className='inner_BOARD_OF_ADVISORS_section'>

              <div className='main_cards_div'>
                <div className='main_cards_top_div'>
                  <div className='profile_images_holder_div'>
                    <img src="https://storage.googleapis.com/asei-632d2.appspot.com/advisory_board_members/1712681891130-person (5).png" alt="" />
                  </div>
                </div>
                <div className='main_cards_body_div'>
                  <div className='inner_cards_body_div1'>
                    <div className='name_div'> Dr. Javaid Laghari </div>
                  </div>
                  <div className='inner_cards_body_div2'>
                    <div>President</div>
                    Dr. Javaid Laghari, a former Professor at SUNY Buffalo, has held key roles, including Director of Graduate Studies and Director of the Space Power Institute. His distinguished career features Chairmanship of Pakistan's Higher Education Commission (HEC) and a Commissioner role at the Ministry of Education (CAA), UAE. He's led a private university and currently presides over the Forum for Dialogue and Diplomacy in Houston, USA. Dr. Laghari's extensive contributions span 600 publications and presentations, primarily focused on education and skill development.
                  </div>
                  <div className='inner_cards_body_div3'>
                    <a href="https://www.linkedin.com/in/dr-javaid-laghari-6504b715/" target="_blank">
                      <button> Linkedin </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className='main_cards_div'>
                <div className='main_cards_top_div'>
                  <div className='profile_images_holder_div'>
                    <img src="https://storage.googleapis.com/asei-632d2.appspot.com/advisory_board_members/1712681856534-person (4).png" alt="" />
                  </div>
                </div>
                <div className='main_cards_body_div'>
                  <div className='inner_cards_body_div1'>
                    <div className='name_div'> Dr. Imran Amin </div>
                  </div>
                  <div className='inner_cards_body_div2' style={{ paddingBottom: "18%" }}>
                    <div>Executive Director</div>
                    Dr. Amin, a distinguished computer science professor, advises Master's and Doctoral candidates on their theses. His research centers on AI for manufacturing optimization. Leading global manufacturing expansion, he bridges academia and industry, focusing on AI and IoT for manufacturing. A serial entrepreneur, he mentors PhD students and startups, contributing to pioneering AI and data analytics research as a Co-Principal Investigator at the National Center for Big Data & Cloud Computing.
                  </div>
                  <div className='inner_cards_body_div3'>
                    <a href="https://www.linkedin.com/in/drimran/" target="_blank">
                      <button> Linkedin </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className='main_cards_div'>
                <div className='main_cards_top_div'>
                  <div className='profile_images_holder_div'>
                    <img src="https://storage.googleapis.com/asei-632d2.appspot.com/advisory_board_members/1712681787672-person (2).png" alt="" />
                  </div>
                </div>
                <div className='main_cards_body_div'>
                  <div className='inner_cards_body_div1'>
                    <div className='name_div'> Dr. Martha Dillman </div>
                  </div>
                  <div className='inner_cards_body_div2' style={{ paddingBottom: "18%" }}>
                    <div>Executive Secretary</div>
                    Dr. Martha Dillman serves as Advisor for Quality Assurance and Accreditation at the prestigious National Defence College, Abu Dhabi, UAE, for the last decade. Prior, she worked as Quality Assurance Manager at the National Qualifications Authority (NQA), UAE, for 3 years, aiding in the Emirates Qualifications Framework development. Dr. Dillman's 16-year accreditation and quality assurance experience includes a PhD in Higher Education Administration from the University of Central Florida, USA.
                  </div>
                  <div className='inner_cards_body_div3'>
                    <a href="https://www.linkedin.com/in/martha-dillman-01867750" target="_blank">
                      <button> Linkedin </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className='main_cards_div'>
                <div className='main_cards_top_div'>
                  <div className='profile_images_holder_div'>
                    <img src="https://storage.googleapis.com/asei-632d2.appspot.com/advisory_board_members/1712681743431-person (1).png" alt="" />
                  </div>
                </div>
                <div className='main_cards_body_div'>
                  <div className='inner_cards_body_div1'>
                    <div className='name_div'> Dr. Wassim Khechen </div>
                  </div>
                  <div className='inner_cards_body_div2'>
                    Dr. Wassim Khechen, holding a PhD in Electrical and Computer Engineering from SUNY Buffalo, NY, USA, specializes in space power systems for NASA and US defense contracts. A former Venezuelan Tae Kwon-Do champion, he now heads the Master Khechen School of Tae Kwon-Do in the US. Dr. Khechen is a successful entrepreneur, pioneering the first accredited Martial Arts University in the US, drawing upon his extensive and diverse expertise.
                  </div>
                  <div className='inner_cards_body_div3'>
                    <a href="https://www.linkedin.com/in/wassim-khechen-8bb89a84" target="_blank">
                      <button> Linkedin </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className='main_cards_div'>
                <div className='main_cards_top_div'>
                  <div className='profile_images_holder_div'>
                    <img src="https://storage.googleapis.com/asei-632d2.appspot.com/advisory_board_members/1712681821264-person (3).png" alt="" />
                  </div>
                </div>
                <div className='main_cards_body_div'>
                  <div className='inner_cards_body_div1'>
                    <div className='name_div'> Salman Abedin </div>
                  </div>
                  <div className='inner_cards_body_div2'>
                    Salman Abedin is a College Professor in Toronto, Canada. Currently teaching Digital Marketing to College Students at Toronto School of Management and Conestoga College, he holds three Master's Degrees, including an M.Des. from OCAD University in Toronto and an MS Degree from SZABIST University in Karachi. Salman has taught Idea Generation, Product Design, and Digital Marketing Strategy at College, Undergraduate, and Graduate levels in Pakistan and Canada.
                  </div>
                  <div className='inner_cards_body_div3'>
                    <a href="https://www.linkedin.com/in/salmanabedin/" target="_blank">
                      <button> Linkedin </button>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>




          {/* <Flex flexWrap={'wrap'} gap={'2rem'} justify={'center'}  >
          {testimonials.map((cardInfo, index) => (
            <TestimonialCard {...cardInfo} index={index} />
          ))}
        </Flex> */}






        </Box>
      </Flex>
    </div>
  );
}




