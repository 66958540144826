import React, { useEffect } from 'react';

import './Account_activate.css';
import axios from 'axios';
import back_end_base_url from '../../services/back_end_url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { UserAuth } from '../../services/api/auth';


export const Account_activate = () => {

    const [shouldHideHeaderFooter, setShouldHideHeaderFooter] = React.useState(false);
    const [error, seterror] = React.useState('');

    const location = useLocation();

    React.useEffect(() => {
        localStorage.setItem('pathname', location.pathname);
    }, []);

    const queryParams = new URLSearchParams(window.location.search);
    var token = queryParams.get('update_token');

    const navigate = useNavigate()

    useEffect(() => {
        account_activate_function();
    }, []);

    const account_activate_function = async () => {

        try {
            let body = {
                update_token: token
            }

            const res = await axios.patch(back_end_base_url + 'service/account-status-update', body);

            if (res.data.status === "200") {

                // console.log(res.data);
                // toast(res.data.msg);

                setTimeout(() => {
                    // console.log(response);

                    localStorage.setItem('access-token', res.data.msg.access_token);
                    localStorage.setItem('username', res.data.msg.user.username);
                    localStorage.setItem('resume', res.data.msg.user.resume);
                    localStorage.setItem('user', res.data.msg.user);

                    navigate('/dashboard');
                }, 1000);

            }

        } catch (error: any) {
            seterror(error.response.data.msg)
            console.error('API Call Error:', error);
        }
    };

    return (
        <>
            <div className='main_laoder_div' id='main_laoder_div'>
                <div className='inner_laoder_div'>
                    <span className="loader"></span>
                </div>
            </div>
            <div className='main_account_activate_div'>
                {error !== '' ? (
                    <div id="error_account_activate_container">
                        <div className="error_account_activate_message">{error}</div>
                    </div>
                ) : (
                    <>
                        <ToastContainer />
                        <div id="account_activate_container">
                            <div className="account_activate_message">Account Activation Successful</div>
                        </div>
                    </>
                )}
            </div>

        </>
    );
};
