import React from 'react'
import CounterUP from './CounterUp'
import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
  Box,
} from '@chakra-ui/react'
import './style.css'

const CounterSection = () => {
  return (
    <Box h="200px" className='bgimg'>
      {/* Your content here */}

      <Flex
        bgColor={'#605c5e5c'}
        alignItems={'center'}
        justifyContent={'center'}
        h="100%"
        flexWrap={'wrap'}
      >
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          paddingRight={{ base: '30px', sm: '40px', md: '40px' }}
          width={{ base: '50%', sm: '50%', md: '20%' }}

        >
          <CounterUP end={50000} />
          <Text
            as={'span'}
            color={'white'}
            fontWeight={600}
            fontSize={{ base: 'xl', sm: '4xl', md: 'xl' }}
          >
            Evaluation{' '}
          </Text>
        </Flex>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          paddingRight={{ base: '30px', sm: '40px', md: '40px' }}
          width={{ base: '50%', sm: '50%', md: '20%' }}


          flexDirection={'column'}
        >
          <CounterUP end={15000} />
          <Text
            as={'span'}
            color={'white'}
            fontWeight={600}
            fontSize={{ base: 'xl', sm: '4xl', md: 'xl' }}
          >
            Badges{' '}
          </Text>
        </Flex>

        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          paddingRight={{ base: '30px', sm: '40px', md: '40px' }}
          width={{ base: '50%', sm: '50%', md: '20%' }}


        >
          <CounterUP end={900} />
          <Text
            as={'span'}
            color={'white'}
            fontWeight={600}
            fontSize={{ base: 'xl', sm: '4xl', md: 'xl' }}
          >
            Certificates{' '}
          </Text>
        </Flex>

        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          paddingRight={{ base: '30px', sm: '40px', md: '40px' }}
          width={{ base: '50%', sm: '50%', md: '20%' }}


        >
          <CounterUP end={10000} />
          <Text
            as={'span'}
            color={'white'}
            fontWeight={600}
            fontSize={{ base: 'xl', sm: '4xl', md: 'xl' }}
          >
            InFlexiduals
          </Text>
        </Flex>

      </Flex>
    </Box>
  )
}

export default CounterSection
