import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Container,
  List,
  ListItem,
  ListIcon,
  Text,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Box,
  AccordionPanel,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'
import { Analytics } from '../../services/api/analytics'
import { Badge } from '../../services/api/badge'
import back_end_base_url from '../../services/back_end_url'
import axios from 'axios'


function ConfirmBadge() {

  const location = useLocation();

  React.useEffect(() => {
    localStorage.setItem('pathname', location.pathname);
  }, []);

  const token = localStorage.getItem('access-token');
  const resume = localStorage.getItem('resume');

  React.useEffect(() => {
    validate_session_function();
  }, [])

  const validate_session_function = async () => {

    if (token === null) {

      localStorage.clear()
      navigate('/');

    } else {
      // if (resume === null) {
      //   navigate('/settings');
      // } else {
        try {
          const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token)

          if (res.data.status === "200") {
          }
        } catch (error) {
          localStorage.clear()
          navigate('/');
        }
      // }

    }

  }



  const [data, setData] = React.useState<any>()
  const navigate = useNavigate()
  const toast = useToast()
  const queryParams = new URLSearchParams(location.search)
  const [skill, setSkill] = React.useState([])
  const [institute, setInstitute] = React.useState('')
  const [date, setDate] = React.useState('')
  const [addLoading, setAddLoading] = React.useState(false)
  const [discardLoading, setDiscardLoading] = React.useState(false)
  const [errors, setErrors] = React.useState({
    institute: '',
    date: '',
  })

  React.useEffect(() => {
    if (!queryParams.get('verification')) {
      navigate('/dashboard')
    }

    Analytics.getHistoryById(queryParams.get('verification') ?? '')
      .then((res) => {

        setData(res.data.data.history)
        setSkill(res.data.data.history.skills)
      })
      .catch((error) => {
        console.log(error)
      })

    return () => {
      Analytics.updateHistoryStatus(
        queryParams.get('verification') ?? '',
        'Discarded'
      )
    }
  }, [])

  const add = () => {
    if (!/^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/.test(date)) {
      setErrors((prev) => {
        return { ...prev, date: 'Date must be in valid format.' }
      })
      return
    }

    const body: any = {}
    body.title = data.title
    body.complexity = data.complexity
    body.skills = skill
    body.history = queryParams.get('verification')

    if (institute.length) {
      body.institute = institute
    }

    if (date.length) {
      body.date = date
    }

    setAddLoading(true)
    Badge.add(body)
      .then((res) => {
        toast({
          title: 'Badge Added.',
          description: `We have added ${data.title} Badge to your profile!`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        navigate('/dashboard')
      })
      .catch((error) => {
        toast({
          title: 'Failure in adding badge.',
          description: `Something went wrong`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
      .finally(() => {
        setAddLoading(false)
      })
  }

  const discard = () => {
    setDiscardLoading(true)

    Analytics.updateHistoryStatus(
      queryParams.get('verification') ?? '',
      'Discarded'
    )
      .then((res) => {
        navigate('/dashboard')
      })
      .catch((error) => {
        toast({
          title: 'Failure.',
          description: `Something went wrong`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
      .finally(() => {
        setDiscardLoading(false)
      })
  }

  const renderSkill = (item: string, key: number) => {
    return (
      <ListItem key={key}>
        <ListIcon as={MdCheckCircle} color="green.500" />
        {item.trim()}
      </ListItem>
    )
  }

  return (
    <div className='main_faq_page_div'>
      <React.Fragment>
        <Container maxW={'6xl'} pt={20} pb={20}>
          <Text
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
            maxW={'3xl'}
            mb={3}
          >
            Confirm Badge
          </Text>
          <Text color={'gray.600'}>
            Congratulations, you just earned a new badge! Add to your profile to
            show the world.
          </Text>

          <Stack
            direction={{ base: 'column', sm: 'row' }}
            align={'start'}
            justify={'space-between'}
            mt={{ base: 20, sm: 10, md: 20, lg: 20 }}
            mb={{ base: 10, sm: 10, md: 20, lg: 10 }}
          >
            <div>
              <Text fontSize={'sm'} color={'gray.500'}>
                Badge title
              </Text>
              <Text fontSize={'4xl'}>{data?.title && data.title}</Text>
            </div>
            <div>
              <Text fontSize={'sm'} color={'gray.500'} pt={2}>
                Badge Complexity
              </Text>
              <Text fontSize={'4xl'} top={-2}>
                {data?.annotated && data.annotated}
              </Text>
            </div>
            <div>
              <Text fontSize={'sm'} color={'gray.500'} pt={2}>
                Points Earned
              </Text>
              <Text fontSize={'4xl'} top={-2}>
                {data?.points && data.points} Cred Points
              </Text>
            </div>
          </Stack>

          <div>
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton _expanded={{ bg: '#0e1e40', color: 'white' }}>
                    <Box as="span" flex="1" textAlign="left">
                      Skills identified in your uploaded document ({skill?.length}
                      )
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <List spacing={3}>
                    {skill && skill.map((s, index) => renderSkill(s, index))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <Text mt={20} fontSize={'lg'}>
            Certificate Details
          </Text>

          <FormControl mt={3}>
            <FormLabel
              color={'gray.600'}
              fontWeight={400}
              maxW={'3xl'}
              fontSize={{ base: '14px', sm: '12px', md: '14px' }}
            >
              Institute where you earned this certificate (Optional)
            </FormLabel>
            <Input
              type="text"
              name="firstName"
              placeholder="Enter Institute Name"
            />
          </FormControl>
          <FormControl mt={3}>
            <FormLabel
              color={'gray.600'}
              fontWeight={400}
              maxW={'3xl'}
              fontSize={{ base: '14px', sm: '12px', md: '14px' }}
            >
              Date on which you earned this certificate (Optional)
            </FormLabel>
            <Input
              type="text"
              value={date}
              onChange={(e) => {
                setErrors((prev) => {
                  return { ...prev, date: '' }
                })
                setDate(e.target.value)
              }}
              placeholder="Enter a date in DD-MM-YYYY format"
              pattern="^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$"
              onError={() => {
                console.log('error')
              }}
            />
          </FormControl>
          {errors.date.length ? errors.date : ''}

          <Stack
            direction={'row'}
            align={'start'}
            gap={5}
            mt={{ base: 20, sm: 10, md: 20, lg: 20 }}
            mb={{ base: 10, sm: 10, md: 20, lg: 10 }}
          >
            <Button
              isLoading={addLoading}
              disabled={addLoading}
              type="submit"
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              bg={'#68c2e8'}
              _hover={{ bg: '#0e1e40' }}
              width={150}
              onClick={add}
            >
              Add To Profile
            </Button>
            <Button
              isLoading={discardLoading}
              disabled={discardLoading}
              width={150}
              type="submit"
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              color={'red'}
              bg={'gray.200'}
              _hover={{ bg: '#0e1e40', color: 'white' }}
              onClick={discard}
            >
              Discard
            </Button>
          </Stack>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default ConfirmBadge
