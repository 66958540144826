import React, { ReactNode } from 'react';
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import Testimonial from './Testimonial'
import { useLocation } from 'react-router-dom';

export default function About() {
  const location = useLocation();

  React.useEffect(() => {
    localStorage.setItem('pathname', location.pathname);
  }, []);
  return (
    <Box
      width={'100%'}
      textAlign="center"
      color={useColorModeValue("white", 'white')}>
      <Testimonial />
    </Box>
  );
}