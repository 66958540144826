import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import { UserAuth } from '../../services/api/auth'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import { useNavigate } from 'react-router-dom';



function ErrorMessage(props: any) {
  return (
    <Text color={'red'} fontSize={12} mt={1}>
      {props.message}
    </Text>
  )
}

export default function Forgot() {
  const [loginFailure, setLoginFailure] = React.useState<boolean>(false)
  const validate = (values: FormikValues) => {
    const errors: any = {}
    if (values.password.length < 8) {
      errors.password = 'Password cannot be less than 8 characters'
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }
  const navigate = useNavigate();
  const onSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    setLoginFailure(false);

    const body = {
      email: values.email,
    };

    try {
      const res = await UserAuth.forgot_password(body);

      if (res.data.status === "200") {
        localStorage.setItem('toast_msg', res.data.msg);
        localStorage.setItem('email', values.email);
        navigate('/Verification')
      }

    } catch (error) {
      console.error(error);
      setLoginFailure(true);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <>
      <div className='main_faq_page_div'>
        <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              <Text
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                maxW={'3xl'}
              >
                Forgot your Password
              </Text>

              <Formik
                initialValues={{ email: '' }}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl id="email">
                      <FormLabel
                        color={'gray.600'}
                        fontWeight={400}
                        maxW={'3xl'}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Email address
                      </FormLabel>
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.email && touched.email && (
                      <ErrorMessage message={errors.email} />
                    )}
                    <Stack spacing={6}>
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}
                      >
                      </Stack>
                      {loginFailure && (
                        <ErrorMessage message={'Incorrect email'} />
                      )}
                      <Button
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                        rounded={'full'}
                        px={6}
                        colorScheme={'orange'}
                        bg={'#68c2e8'}
                        _hover={{ bg: '#0e1e40' }}
                      >
                        Verfiy
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image alt={'Login Image'} objectFit={'cover'} src="https://storage.googleapis.com/asei-632d2.appspot.com/web_assets/1712681567766-study.webp" />
          </Flex>
        </Stack>
      </div>
    </>
  )
}
