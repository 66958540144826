import {
  Button,
  Checkbox,
  Flex,
  Select,
  SelectField,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import { UserAuth } from '../../services/api/auth'
import { Formik } from 'formik'
import { FormikValues } from 'formik/dist/types'
import * as Yup from 'yup'
import './register.css'
import { useLocation, useNavigate } from 'react-router-dom'

function ErrorMessage(props: any) {
  return (
    <Text color={'red'} fontSize={15} mt={1}>
      {props.message}
    </Text>
  )
}

export default function Register() {

  const location = useLocation();

  React.useEffect(() => {
    localStorage.setItem('pathname', location.pathname);
  }, []);

  const token = localStorage.getItem('access-token');

  // Error Notification state and Function Start here 

  const [notification_text, setnotification_text] = React.useState('');
  const [error_notification_text, seterror_notification_text] = React.useState('');

  const [visible, setVisible] = React.useState(false);
  const [error_visible, seterror_Visible] = React.useState(false);

  const handleShowNotification = () => {
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, 3000); // Adjust the timeout value to match the notification duration
  };

  const errorhandleShowNotification = () => {
    seterror_Visible(true);

    setTimeout(() => {
      seterror_Visible(false);
    }, 3000); // Adjust the timeout value to match the notification duration
  };

  // Error Notification state and Function End here 

  const [ip, setIp] = React.useState('Fetching IP...');

  const fetchUserIP = async () => {
    try {
      // const response = await fetch('https://api.ipify.org/?format=json');
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      const userIP = data.ip;
      // console.log('User IP:', userIP);
      setIp(userIP)
      // Do something with the user's IP address
    } catch (error) {
      console.error('Error fetching user IP:', error);
    }
  };

  // React.useEffect(() => {
  //   fetchUserIP();
  // }, [])

  const navigate = useNavigate();

  const [failure, setFailure] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)

  const RegisterSchema = Yup.object().shape({
    name_of_applicant: Yup.string()
      .min(1)
      .max(30, 'Too long value for a first name.')
      .required('Name Of Applicant is a required field.'),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address.')
      .required('Email is a required field.'),
    date_of_birth: Yup.string()
      .min(8, 'Password should at least be 8 characters long.')
      .required('date Of Birth is a required field'),
    password: Yup.string()
      .min(8, 'Password should at least be 8 characters long.')
      .required('Password is a required field'),
    retypePassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password does not match.')
      .required('Please confirm your password'),
  });


  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    setFailure(false)
    let body = {
      username: values.name_of_applicant,
      email: values.email,
      dob: values.date_of_birth,
      password: values.password,
    }

    UserAuth.register(body)
      .then((res) => {

        if (res.data.status === "200") {

          let data = {
            email: values.email,
          }


          UserAuth.email_verify(data)
            .then((res) => {

              if (res.data.status === "200") {

                setnotification_text(res.data.msg);
                handleShowNotification();

                let events_body = {
                  email: values.email,
                  client_user_agent: navigator.userAgent
                }

                UserAuth.events(events_body)
                  .then((response) => {

                    if (response.status === 200) {
                      // console.log(response);

                      setTimeout(() => {
                        navigate('/login');
                      }, 3000);

                    }

                  })
                  .catch((error) => {
                    console.error('Events API Call Error:', error);
                  })
                  .finally(() => {
                    // setSubmitting(false)
                  })

              }

            })
            .catch((error) => {
              errorhandleShowNotification();
              seterror_notification_text(error.response.data.msg);
              setFailure(true)
            })
            .finally(() => {
              setSubmitting(false)
            })

        }

      })
      .catch((error) => {
        errorhandleShowNotification();
        seterror_notification_text(error.response.data.msg);
        setFailure(true)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }


  return (
    <>

      {/* notification popup box Start here */}
      <div className={`modal ${visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box">
            <p>
              {notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* notification popup box End here */}

      {/* Error notification popup box Start here */}
      <div className={`modal ${error_visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box" style={{ backgroundColor: '#980f0f' }}>
            <p>
              {error_notification_text}
              {failure && error && <ErrorMessage message={error} />}
            </p>
          </div>

        </div>
      </div>
      {/* Error notification popup box End here */}

      <div className='main_faq_page_div'>
        <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              {failure && error && <ErrorMessage message={error} />}
              <Text
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                maxW={'3xl'}
              >
                Register your account
              </Text>
              <Formik
                initialValues={{
                  name_of_applicant: '',
                  email: '',
                  date_of_birth: '',
                  password: '',
                  retypePassword: '',
                }}
                validationSchema={RegisterSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl id="name_of_applicant" mb={1}>
                      <FormLabel
                        color={'gray.600'}
                        fontWeight={400}
                        maxW={'3xl'}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Name of Applicant
                      </FormLabel>
                      <Input
                        type="text"
                        name="name_of_applicant"
                        value={values.name_of_applicant}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.name_of_applicant && touched.name_of_applicant && (
                      <ErrorMessage message={errors.name_of_applicant} />
                    )}
                    <FormControl id="email" mb={1}>
                      <FormLabel
                        color={'gray.600'}
                        fontWeight={400}
                        maxW={'3xl'}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Email Address
                      </FormLabel>
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.email && touched.email && (
                      <ErrorMessage message={errors.email} />
                    )}
                    <FormControl id="date_of_birth" mb={1}>
                      <FormLabel
                        color={'gray.600'}
                        fontWeight={400}
                        maxW={'3xl'}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Date of Birth
                      </FormLabel>
                      <Input
                        type="date"
                        name="date_of_birth"
                        value={values.date_of_birth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.date_of_birth && touched.date_of_birth && (
                      <ErrorMessage message={errors.date_of_birth} />
                    )}

                    <FormControl id="password" mb={1}>
                      <FormLabel
                        color={'gray.600'}
                        maxW={'3xl'}
                        fontWeight={400}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Password
                      </FormLabel>
                      <Input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.password && touched.password && (
                      <ErrorMessage message={errors.password} />
                    )}
                    <FormControl id="retypePassword" mb={1}>
                      <FormLabel
                        color={'gray.600'}
                        maxW={'3xl'}
                        fontWeight={400}
                        fontSize={{ base: 'md', sm: 'md', md: 'md' }}
                      >
                        Confirm Password
                      </FormLabel>
                      <Input
                        type="password"
                        name="retypePassword"
                        value={values.retypePassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                    {errors.retypePassword && touched.retypePassword && (
                      <ErrorMessage message={errors.retypePassword} />
                    )}
                    <Stack spacing={6}>
                      <Button
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                        rounded={'full'}
                        mt={5}
                        px={6}
                        colorScheme={'orange'}
                        bg={'#68c2e8'}
                        _hover={{ bg: '#0e1e40' }}
                      >
                        Register
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </Flex>

        </Stack>
      </div>
    </>
  )
}
