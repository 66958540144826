import * as React from 'react'
import './equivalence.css';
import {
  Container,
  Input,
  Select,
  SelectField,
  InputGroup,
  InputLeftAddon,
  Button,
  Card,
  CardBody,
  Flex,
  Img,
  CardHeader,
  Heading,
  Text,
  Stack,
  Spinner,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import Mascot from '../../assets/logo/light-mascot.png'
import { Prediction } from '../../services/api/prediction'
import { Analytics } from '../../services/api/analytics'
import { Badge } from '../../services/api/badge'
import { useLocation, useNavigate } from 'react-router-dom'
import nav_menu from '../../assets/icons/nav_menu.png'
import back_end_base_url from '../../services/back_end_url';
import axios from 'axios';
import { UserAuth } from '../../services/api/auth';
import tick_arrow from '../../assets/logo/tick_arrow.jpg';
import bllet_point from '../../assets/icons/bullet-point.png';
import { string } from 'yup';
import { error } from 'console';
import COUNTRIES from '../../constants/countries.json'

function NoResultCard() {
  return (
    <React.Fragment>
      <CardHeader>
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Heading
            fontWeight={600}
            fontSize={{ base: 'lg', sm: 'lg', md: '2xl' }}
            maxW={'3xl'}
            textAlign="center"
          >
            Working In Progress
          </Heading>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Img src={Mascot} width={200} />
        </Flex>
      </CardBody>
    </React.Fragment>
  )
}

export const Equivalence = () => {

  const location = useLocation();

  React.useEffect(() => {
    localStorage.setItem('pathname', location.pathname);
  }, []);

  const token = localStorage.getItem('access-token')
  const resume = localStorage.getItem('resume')

  // Error Notification state and Function Start here 

  const [notification_text, setnotification_text] = React.useState('');
  const [error_notification_text, seterror_notification_text] = React.useState('');

  const [visible, setVisible] = React.useState(false);
  const [error_visible, seterror_Visible] = React.useState(false);

  const handleShowNotification = () => {
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const errorhandleShowNotification = () => {
    seterror_Visible(true);

    setTimeout(() => {
      seterror_Visible(false);
    }, 3000);
  };

  // Error Notification state and Function End here 
  const [showloader, setshowloader] = React.useState(true)
  const [loading, setLoading] = React.useState<boolean>(false)

  const [file, setFile] = React.useState<File | null>(null);
  const [file_name_of_course, setfile_name_of_course] = React.useState('')
  const [file_started_date, setfile_started_date] = React.useState('')
  const [file_completed_date, setfile_completed_date] = React.useState('')
  const [file_total_hours_completed, setfile_total_hours_completed] = React.useState('')
  const [file_online_or_F2F, setfile_online_or_F2F] = React.useState('')
  const [file_name_of_institute, setfile_name_of_institute] = React.useState('')
  const [file_website_of_institute, setfile_website_of_institute] = React.useState('')
  const [file_country_of_institute, setfile_country_of_institute] = React.useState('')
  const [file_contact_person_at_institute_name, setfile_contact_person_at_institute_name] = React.useState('')
  const [file_contact_person_at_institute_email, setfile_contact_person_at_institute_email] = React.useState('')

  const [file_showbadgelist, setfile_showbadgelist] = React.useState(true)

  const [file_complexity, setfile_complexity] = React.useState<string | null>(null);
  const [file_prediction, setfile_prediction] = React.useState('')
  const [file_skills, setfile_skills] = React.useState([])
  const [file_description, setfile_description] = React.useState('')

  const [file_input_name_of_course, setfile_input_name_of_course] = React.useState(false);
  const [file_input_started_date, setfile_input_started_date] = React.useState(false);
  const [file_input_completed_date, setfile_input_completed_date] = React.useState(false);
  const [file_input_total_hours_completed, setfile_input_total_hours_completed] = React.useState(false);
  const [file_input_online_or_F2F, setfile_input_online_or_F2F] = React.useState(false);
  const [file_input_name_of_institute, setfile_input_name_of_institute] = React.useState(false);
  const [file_input_website_of_institute, setfile_input_website_of_institute] = React.useState(false);
  const [file_input_country_of_institute, setfile_input_country_of_institute] = React.useState(false);
  const [file_input_contact_perso_at_institute_name, setfile_input_contact_perso_at_institute_name] = React.useState(false);
  const [file_input_contact_person_at_institute_email, setfile_input_contact_person_at_institute_email] = React.useState(false);
  const [file_input, setfile_input] = React.useState(false);
  const [file_input_button, setfile_input_button] = React.useState(false);



  const [name_of_course, setname_of_course] = React.useState('')
  const [started_date, setstarted_date] = React.useState('')
  const [completed_date, setcompleted_date] = React.useState('')
  const [total_hours_completed, settotal_hours_completed] = React.useState('')
  const [online_or_F2F, setonline_or_F2F] = React.useState('')
  const [name_of_institute, setname_of_institute] = React.useState('')
  const [website_of_institute, setwebsite_of_institute] = React.useState('')
  const [country_of_institute, setcountry_of_institute] = React.useState('')
  const [contact_person_at_institute_name, setcontact_person_at_institute_name] = React.useState('')
  const [contact_person_at_institute_email, setcontact_person_at_institute_email] = React.useState('')
  const [course_description, setcourse_description] = React.useState('')

  const [complexity, setcomplexity] = React.useState<string | null>(null);
  const [prediction, setprediction] = React.useState('')
  const [skills, setskills] = React.useState([])

  const [showbadgelist, setshowbadgelist] = React.useState(true)

  const [input_name_of_course, setinput_name_of_course] = React.useState(false);
  const [input_started_date, setinput_started_date] = React.useState(false);
  const [input_completed_date, setinput_completed_date] = React.useState(false);
  const [input_total_hours_completed, setinput_total_hours_completed] = React.useState(false);
  const [input_online_or_F2F, setinput_online_or_F2F] = React.useState(false);
  const [input_name_of_institute, setinput_name_of_institute] = React.useState(false);
  const [input_website_of_institute, setinput_website_of_institute] = React.useState(false);
  const [input_country_of_institute, setinput_country_of_institute] = React.useState(false);
  const [input_contact_perso_at_institute_name, setinput_contact_perso_at_institute_name] = React.useState(false);
  const [input_contact_person_at_institute_email, setinput_contact_person_at_institute_email] = React.useState(false);
  const [input_course_description, setinput_course_description] = React.useState(false);
  const [input_button, setinput_button] = React.useState(false);



  const toast = useToast()
  const navigate = useNavigate()

  const verification = () => {
    setTimeout(Analytics.updateVerifications, 50)
  }

  const allowedExtensions = ['doc', 'docx', 'txt', 'pdf'];

  const readFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      const fileExtension = (selectedFile.name.split('.').pop() || '').toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFile(selectedFile);
      } else {
        setFile(null);
        seterror_notification_text("Invalid file extension. Please upload only doc, docx, txt, or pdf files.")
        errorhandleShowNotification();
      }
    }
  };

  const submit = async () => {

    if (file_name_of_course === '') {
      seterror_notification_text("Please Enter a Enter Name of Course / Diploma / Degree")
      errorhandleShowNotification();
    } else if (file_started_date === '') {
      seterror_notification_text("Please Enter a Started Date")
      errorhandleShowNotification();
    } else if (file_completed_date === '') {
      seterror_notification_text("Please Enter a Completed Date")
      errorhandleShowNotification();
    } else if (file_total_hours_completed === '') {
      seterror_notification_text("Please Enter a Enter Number of Credit Hours Completed (For Diploma / Degree Holders)")
      errorhandleShowNotification();
    } else if (file_online_or_F2F === '') {
      seterror_notification_text("Please Enter a Online Or F2F")
      errorhandleShowNotification();
    } else if (file_name_of_institute === '') {
      seterror_notification_text("Please Enter a Name Of Institute")
      errorhandleShowNotification();
    } else if (file_website_of_institute === '') {
      seterror_notification_text("Please Enter a Website Of Institute")
      errorhandleShowNotification();
    } else if (file_country_of_institute === '') {
      seterror_notification_text("Please Enter a Country Of Institute")
      errorhandleShowNotification();
    } else if (file_contact_person_at_institute_name === '') {
      seterror_notification_text("Please Enter a Contact Person At Institute: Name")
      errorhandleShowNotification();
    } else if (file_contact_person_at_institute_email === '') {
      seterror_notification_text("Please Enter a Contact Person At Institute: Email")
      errorhandleShowNotification();
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(file_contact_person_at_institute_email)) {
      seterror_notification_text("Invalid email address")
      errorhandleShowNotification();
    } else if (file === null) {
      seterror_notification_text("Please select a File")
      errorhandleShowNotification();
    } else {

      setshowloader(false);

      try {

        const form = new FormData();
        if (file) {
          form.append('outline', file)
        }

        const res = await axios.post(
          back_end_base_url + 'evaluation/type/document',
          form,
          {
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (res.status === 200) {

          setfile_prediction(res.data.msg.prediction)
          setfile_complexity(res.data.msg.complexity)
          setfile_skills(res.data.msg.skills)
          setfile_description(res.data.msg.description)
          setfile_showbadgelist(false)

          setfile_input_name_of_course(true)
          setfile_input_started_date(true)
          setfile_input_completed_date(true)
          setfile_input_total_hours_completed(true)
          setfile_input_online_or_F2F(true)
          setfile_input_name_of_institute(true)
          setfile_input_website_of_institute(true)
          setfile_input_country_of_institute(true)
          setfile_input_contact_perso_at_institute_name(true)
          setfile_input_contact_person_at_institute_email(true)
          setfile_input_button(true)
          setfile_input(true)

          setshowloader(true);
        }

      } catch (error: any) {
        seterror_notification_text(error.response.data.msg)
        errorhandleShowNotification();
        setshowloader(true);
      }

    }

  };

  const addBadge = () => {
    setshowloader(false);
    setLoading(true);

    var points = 0;


    if (file_complexity === "Not Provided By AI") {
      points = 0
    } else if (file_complexity === "Novice") {
      points = 2
    } else if (file_complexity === "Intermediate") {
      points = 3
    } else if (file_complexity === "Advance") {
      points = 4
    }

    let body = {
      title: file_prediction,
      complexity: file_complexity,
      points: points,
      skills: file_skills,
      course_name: file_name_of_course,
      course_started_date: file_started_date,
      course_ending_date: file_completed_date,
      hours_completed: file_total_hours_completed,
      learning_mode: file_online_or_F2F,
      institute_name: file_name_of_institute,
      institute_website: file_website_of_institute,
      institute_country: file_country_of_institute,
      institute_contact_person_name: file_contact_person_at_institute_name,
      institute_contact_person_email: file_contact_person_at_institute_email,
      description: { description: file_description },
    };

    // If you intend to use this block of code, uncomment it and ensure it meets your requirements
    UserAuth.badge_create(body)
      .then((res) => {

        if (res.data.status === "200") {

          setnotification_text(res.data.msg)
          handleShowNotification();

          setshowloader(true);
          navigate('/history')
        }
      })
      .catch((error: any) => {

        seterror_notification_text(error.response.data.msg)
        errorhandleShowNotification();

        setshowloader(true);
        // toast({
        //   title: 'Failure in adding badge.',
        //   description: `Something went wrong`,
        //   status: 'error',
        //   duration: 9000,
        //   isClosable: true,
        // })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitText = () => {

    if (name_of_course === '') {
      seterror_notification_text("Please Enter a Enter Name of Course / Diploma / Degree")
      errorhandleShowNotification();
    } else if (started_date === '') {
      seterror_notification_text("Please Enter a Started Date")
      errorhandleShowNotification();
    } else if (completed_date === '') {
      seterror_notification_text("Please Enter a Completed Date")
      errorhandleShowNotification();
    } else if (total_hours_completed === '') {
      seterror_notification_text("Please Enter a Enter Number of Credit Hours Completed (For Diploma / Degree Holders)")
      errorhandleShowNotification();
    } else if (online_or_F2F === '') {
      seterror_notification_text("Please Enter a Online Or F2F")
      errorhandleShowNotification();
    } else if (name_of_institute === '') {
      seterror_notification_text("Please Enter a Name Of Institute")
      errorhandleShowNotification();
    } else if (website_of_institute === '') {
      seterror_notification_text("Please Enter a Website Of Institute")
      errorhandleShowNotification();
    } else if (country_of_institute === '') {
      seterror_notification_text("Please Enter a Country Of Institute")
      errorhandleShowNotification();
    } else if (contact_person_at_institute_name === '') {
      seterror_notification_text("Please Enter a Contact Person At Institute: Name")
      errorhandleShowNotification();
    } else if (contact_person_at_institute_email === '') {
      seterror_notification_text("Please Enter a Contact Person At Institute: Email")
      errorhandleShowNotification();
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contact_person_at_institute_email)) {
      seterror_notification_text("Invalid email address")
      errorhandleShowNotification();
    } else if (course_description === '') {
      seterror_notification_text("Please Enter a Course / Program Description")
      errorhandleShowNotification();
    } else {

      setshowloader(false);
      setLoading(true);

      let course_description_body = {
        data: course_description
      };

      UserAuth.getdescription(course_description_body)
        .then((res) => {

          // console.log(res.data.msg);

          if (res.data.status === "200") {
            setprediction(res.data.msg.prediction)
            setcomplexity(res.data.msg.complexity)
            setskills(res.data.msg.skills)
            setshowloader(true);
            setshowbadgelist(false)

            setinput_name_of_course(true)
            setinput_started_date(true)
            setinput_completed_date(true)
            setinput_total_hours_completed(true)
            setinput_online_or_F2F(true)
            setinput_name_of_institute(true)
            setinput_website_of_institute(true)
            setinput_country_of_institute(true)
            setinput_contact_perso_at_institute_name(true)
            setinput_contact_person_at_institute_email(true)
            setinput_course_description(true)
            setinput_button(true)

          }

        })
        .catch((error: any) => {
          setshowloader(true);
          seterror_notification_text(error.response.data.msg)
          errorhandleShowNotification();
        })
        .finally(() => {
          setshowloader(true);
          setLoading(false);
        });

    }
  };

  const addtextBadge = () => {
    setshowloader(false);
    setLoading(true);

    var points = 0;

    if (complexity === "Not Provided By AI") {
      points = 0
    } else if (complexity === "Novice") {
      points = 2
    } else if (complexity === "Intermediate") {
      points = 3
    } else if (complexity === "Advance") {
      points = 4
    }


    let body = {
      title: prediction,
      complexity: complexity,
      points: points,
      skills: skills,
      course_name: name_of_course,
      course_started_date: started_date,
      course_ending_date: completed_date,
      hours_completed: total_hours_completed,
      learning_mode: online_or_F2F,
      institute_name: name_of_institute,
      institute_website: website_of_institute,
      institute_country: country_of_institute,
      institute_contact_person_name: contact_person_at_institute_name,
      institute_contact_person_email: contact_person_at_institute_email,
      description: { description: course_description },
    };


    // If you intend to use this block of code, uncomment it and ensure it meets your requirements
    UserAuth.badge_create(body)
      .then((res) => {

        if (res.data.status === "200") {

          setnotification_text(res.data.msg)
          handleShowNotification();

          setshowloader(true);
          navigate('/history')
        }
      })
      .catch((error: any) => {
        seterror_notification_text(error.response.data.msg)
        errorhandleShowNotification();
        setshowloader(true);
        // toast({
        //   title: 'Failure in adding badge.',
        //   description: `Something went wrong`,
        //   status: 'error',
        //   duration: 9000,
        //   isClosable: true,
        // })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    validate_session_function();
  }, [])

  const validate_session_function = async () => {

    if (token === null) {

      localStorage.clear()
      navigate('/');

    } else {
      // if (resume === null) {
      //   navigate('/settings');
      // } else {

      try {
        const res = await axios.get(back_end_base_url + 'auth/validate-session?auth_token=' + token)
        if (res.data.status === "200") {
        }
      } catch (error: any) {
        seterror_notification_text(error.response.data.msg)
        errorhandleShowNotification();
        localStorage.clear()
        navigate('/');
      }
      // }

    }

  }

  return (
    <div className='main_faq_page_div'>

      {/* notification popup box Start here */}
      <div className={`modal ${visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box">
            <p>
              {notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* notification popup box End here */}

      {/* Error notification popup box Start here */}
      <div className={`modal ${error_visible ? 'show' : 'hide'}`} id="notification_popup_box">
        <div className="modal-content">

          <div className="notification_box" style={{ backgroundColor: '#980f0f' }}>
            <p id='error_message'>
              {error_notification_text}
            </p>
          </div>

        </div>
      </div>
      {/* Error notification popup box End here */}


      {showloader ? (
        <></>
      ) : (
        <div className="main_loader_div" id="main_loader_div">
          <div className="main_loader_inner_div">
            <span className="loader"></span>
          </div>
        </div>
      )}

      <React.Fragment>
        <Container maxW="6xl" pt={10} pb={10}>

          <Text
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
            maxW={'3xl'}
            mb={3}
          >
            Equivalence
          </Text>

          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>File Upload</Tab>
              <Tab>Text Upload</Tab>
            </TabList>
            <TabPanels>
              {/* File Upload Start Here */}
              <TabPanel>
                <>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Enter Name of Course / Diploma / Degree <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id="name_of_course"
                      type="text"
                      value={file_name_of_course}
                      onChange={(e) => {
                        setfile_name_of_course(e.target.value)
                      }}
                      placeholder="Enter Name of Course / Diploma / Degree"
                      disabled={file_input_name_of_course ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    />
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Started Date <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id=""
                      type="date"
                      value={file_started_date}
                      onChange={(e) => {
                        setfile_started_date(e.target.value)
                      }}
                      placeholder="Started Date"
                      disabled={file_input_started_date ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    />
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Completed Date <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id=""
                      type="date"
                      value={file_completed_date}
                      onChange={(e) => {
                        setfile_completed_date(e.target.value)
                      }}
                      placeholder="Completed Date"
                      disabled={file_input_completed_date ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    />
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Enter Number of Credit Hours Completed (For Diploma / Degree Holders) <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id=""
                      type="number"
                      value={file_total_hours_completed}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        // Check if the input length is less than 5 before updating the state
                        if (inputValue.length <= 5) {
                          setfile_total_hours_completed(inputValue);
                        }

                        // Optionally, you can perform an action or show a message when 5 numbers are entered
                        if (inputValue.length === 5) {
                          console.log('Input contains 5 numbers');
                        }
                      }}
                      placeholder="Enter Number of Credit Hours Completed (For Diploma / Degree Holders)"
                      disabled={file_input_total_hours_completed ? true : false}
                      onError={() => {
                        console.log('error');
                      }}
                    />
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Online or F2F (Face to Face) <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <FormControl>
                      <Select
                        value={file_online_or_F2F}
                        onChange={(e) => {
                          setfile_online_or_F2F(e.target.value)
                        }}
                        disabled={file_input_online_or_F2F ? true : false}
                        onError={() => {
                          console.log('error')
                        }}>
                        <option value="Select an option" selected hidden>Select an option</option>
                        <option value="Online">Online</option>
                        <option value="Face to Face">Face to Face</option>
                      </Select>
                    </FormControl>
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Name of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id=""
                      type="text"
                      value={file_name_of_institute}
                      onChange={(e) => {
                        setfile_name_of_institute(e.target.value)
                      }}
                      placeholder="Name of institute"
                      disabled={file_input_name_of_institute ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    />
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Website of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id=""
                      type="text"
                      value={file_website_of_institute}
                      onChange={(e) => {
                        setfile_website_of_institute(e.target.value)
                      }}
                      placeholder="Website of Institute"
                      disabled={file_input_website_of_institute ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    />
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Country of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Select
                      name="country"
                      value={file_country_of_institute}
                      onChange={(e) => {
                        setfile_country_of_institute(e.target.value)
                      }}
                      placeholder="Country of Institute"
                      disabled={file_input_country_of_institute ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    >
                      {COUNTRIES.map((country) => (
                        <option value={country.name} key={country.code}>
                          {country.name}
                          {/* &emsp; {country.emoji} */}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Name of contact person at institute: <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id=""
                      type="text"
                      value={file_contact_person_at_institute_name}
                      onChange={(e) => {
                        setfile_contact_person_at_institute_name(e.target.value)
                      }}
                      placeholder="Contact person at Institute: Name"
                      disabled={file_input_contact_perso_at_institute_name ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    />
                  </FormControl>
                  <FormControl mt={3}>
                    <FormLabel
                      color={'gray.600'}
                      fontWeight={400}
                      maxW={'3xl'}
                      fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                    >
                      Email of contact person at institute: <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                    </FormLabel>
                    <Input
                      id=""
                      type="email"
                      value={file_contact_person_at_institute_email}
                      color={'gray.600'}
                      // disabled
                      onChange={(e) => {
                        setfile_contact_person_at_institute_email(e.target.value)
                      }}
                      placeholder="Contact person at Institute: Email"
                      disabled={file_input_contact_person_at_institute_email ? true : false}
                      onError={() => {
                        console.log('error')
                      }}
                    />
                  </FormControl>
                  <label htmlFor="" className="error_only_doc_docx_pdf_heading"> Only doc, docx , txt and pdf files can be uploaded </label>
                  <InputGroup>
                    <InputLeftAddon
                      children="Course Description / Outline: "
                    />
                    <Input
                      size="md"
                      type="file"
                      name="file"
                      accept=".docx,.doc,.pdf,.txt"
                      onChange={readFile}
                      disabled={file_input ? true : false}
                    />
                  </InputGroup>
                  <Button
                    mt={'2'}
                    rounded={'full'}
                    px={6}
                    colorScheme={'orange'}
                    bg={'#68c2e8'}
                    _hover={{ bg: '#0e1e40' }}
                    onClick={submit}
                    disabled={file_input_button ? true : false}
                  >
                    Evaluate
                  </Button>

                  {file_showbadgelist ? (
                    <Card mt={10} pt={5} pb={5} variant="outline">
                      <React.Fragment>
                        <CardHeader>
                          <Flex alignItems={'center'} justifyContent={'center'}>
                            <Heading fontWeight={600} fontSize={{ base: 'lg', sm: 'lg', md: '2xl' }} maxW={'3xl'} textAlign="center">
                              Get Results by posting your course description
                            </Heading>
                          </Flex>
                        </CardHeader>
                        <CardBody>
                          <Flex alignItems={'center'} justifyContent={'center'}>
                            <Img src={Mascot} width={200} />
                          </Flex>
                        </CardBody>
                      </React.Fragment>
                      {loading && (
                        <Flex alignItems={'center'} justifyContent={'center'}>
                          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#68c2e8" size="xl" />
                        </Flex>
                      )}
                    </Card>
                  ) : (
                    <Card mt={10} pt={5} pb={5} variant="outline">
                      <React.Fragment>
                        <CardHeader>
                          <Flex alignItems={'center'} justifyContent={'center'}>
                            <Heading fontWeight={600} fontSize={{ base: 'lg', sm: 'lg', md: '2xl' }} maxW={'3xl'} textAlign="center" color={"#195389"}>
                              <span style={{ color: "#195389" }}>
                                <u>
                                  <u>
                                    RESULT
                                  </u>
                                </u>
                              </span>
                            </Heading>
                          </Flex>

                          <Flex alignItems={'center'} justifyContent={'start'}>
                            <Heading fontWeight={500} fontSize={{ base: 'lg', sm: 'lg', md: '1xl' }} maxW={'3xl'} textAlign="left" display="Flex">
                              <img src={bllet_point} width={20} alt="" /> Complexity: <span style={{ color: "#195389" }}>
                                {file_complexity !== null ? (
                                  <p>{file_complexity}</p>
                                ) : null}
                              </span>
                            </Heading>
                          </Flex>
                          <br />
                          <Flex alignItems={'center'} justifyContent={'start'}>
                            <Heading fontWeight={500} fontSize={{ base: 'lg', sm: 'lg', md: '1xl' }} maxW={'3xl'} textAlign="left" display="Flex">
                              <img src={bllet_point} width={20} alt="" /> Skills
                            </Heading>
                          </Flex>

                        </CardHeader>
                        <CardBody>
                          <Flex alignItems={'center'} justifyContent={'center'}>
                            <div className='main_skills_div'>
                              <div className='main_show_skills_div'>
                                {/* {file_skills.map((item, index) => (
                                  <div key={index} className='show_skills_div'>
                                    <img src={tick_arrow} alt="" />
                                    <p>{item}</p>
                                  </div>
                                ))} */}

                                {file_skills.length === 0 ? (
                                  <h1>No skills evaluated</h1>
                                ) : (
                                  file_skills.map((item, index) => (
                                    // Check if item is not null or undefined before rendering the div
                                    item != null && (
                                      <div key={index} className='show_skills_div'>
                                        <img src={tick_arrow} alt="" />
                                        <p>{item}</p>
                                      </div>
                                    )
                                  ))
                                )}

                              </div>
                              <div className='main_add_badge_button'>
                                <Button mt={'2'} rounded={'full'} px={6} colorScheme={'orange'} bg={'#68c2e8'} _hover={{ bg: '#0e1e40' }}
                                  onClick={addBadge} disabled={loading}>
                                  Add Badge To Profile!
                                </Button>
                              </div>
                            </div>
                          </Flex>
                        </CardBody>
                      </React.Fragment>
                      {loading && (
                        <Flex alignItems={'center'} justifyContent={'center'}>
                          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#68c2e8" size="xl" />
                        </Flex>
                      )}
                    </Card>
                  )}
                </>
              </TabPanel>
              {/* File Upload End Here */}
              {/* Text Upload Start Here */}
              <TabPanel>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Enter Name of Course / Diploma / Degree <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id="name_of_course"
                    type="text"
                    value={name_of_course}
                    onChange={(e) => {
                      setname_of_course(e.target.value)
                    }}
                    placeholder="Enter Name of Course / Diploma / Degree"
                    disabled={input_name_of_course ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Started Date <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id=""
                    type="date"
                    value={started_date}
                    onChange={(e) => {
                      setstarted_date(e.target.value)
                    }}
                    placeholder="Started Date"
                    disabled={input_started_date ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Completed Date <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id=""
                    type="date"
                    value={completed_date}
                    onChange={(e) => {
                      setcompleted_date(e.target.value)
                    }}
                    placeholder="Completed Date"
                    disabled={input_completed_date ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Enter Number of Credit Hours Completed (For Diploma / Degree Holders) <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id=""
                    type="text"
                    value={total_hours_completed}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      // Check if the input length is less than or equal to 5 before updating the state
                      if (inputValue.length <= 5) {
                        settotal_hours_completed(inputValue);
                      }

                      // Optionally, you can perform an action or show a message when 5 characters are entered
                      if (inputValue.length === 5) {
                        console.log('Input contains 5 characters');
                      }
                    }}
                    placeholder="Enter Number of Credit Hours Completed (For Diploma / Degree Holders)"
                    disabled={input_total_hours_completed ? true : false}
                    onError={() => {
                      console.log('error');
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Online or F2F (Face to Face) <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <FormControl>
                    <Select
                      value={online_or_F2F}
                      onChange={(e) => {
                        setonline_or_F2F(e.target.value)
                      }}
                      disabled={input_online_or_F2F ? true : false}
                      onError={() => {
                        console.log('error')
                      }}>
                      <option value="Select an option" selected hidden>Select an option</option>
                      <option value="Online">Online</option>
                      <option value="Face to Face">Face to Face</option>
                    </Select>
                  </FormControl>
                  {/* <Input
                    id=""
                    type="text"
                    // value={text1}
                    onChange={(e) => {
                      setText1(e.target.value)
                    }}
                    placeholder="Online or F2F (Face to Face)"
                    onError={() => {
                      console.log('error')
                    }}
                  /> */}
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Name of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id=""
                    type="text"
                    value={name_of_institute}
                    onChange={(e) => {
                      setname_of_institute(e.target.value)
                    }}
                    placeholder="Name of institute"
                    disabled={input_name_of_institute ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Website of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id=""
                    type="text"
                    value={website_of_institute}
                    onChange={(e) => {
                      setwebsite_of_institute(e.target.value)
                    }}
                    placeholder="Website of Institute"
                    disabled={input_website_of_institute ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Country of Institute <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Select
                    name="country"
                    value={country_of_institute}
                    onChange={(e) => {
                      setcountry_of_institute(e.target.value)
                    }}
                    placeholder="Country of Institute"
                    disabled={input_country_of_institute ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  >
                    {COUNTRIES.map((country) => (
                      <option value={country.name} key={country.code}>
                        {country.name}
                        {/* &emsp; {country.emoji} */}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Contact person at Institute: Name <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id=""
                    type="text"
                    value={contact_person_at_institute_name}
                    onChange={(e) => {
                      setcontact_person_at_institute_name(e.target.value)
                    }}
                    placeholder="Contact person at Institute: Name"
                    disabled={input_contact_perso_at_institute_name ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Contact person at Institute: Email <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Input
                    id=""
                    type="email"
                    value={contact_person_at_institute_email}
                    color={'gray.600'}
                    // disabled
                    onChange={(e) => {
                      setcontact_person_at_institute_email(e.target.value)
                    }}
                    placeholder="Contact person at Institute: Email"
                    disabled={input_contact_person_at_institute_email ? true : false}
                    onError={() => {
                      console.log('error')
                    }}
                  />
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel
                    color={'gray.600'}
                    fontWeight={400}
                    maxW={'3xl'}
                    fontSize={{ base: '14px', sm: '12px', md: '14px' }}
                  >
                    Course / Program Description <span style={{ color: "red", fontSize: "20px" }}>&#42;</span>
                  </FormLabel>
                  <Textarea
                    value={course_description}
                    onChange={(e) => {
                      setcourse_description(e.target.value)
                    }}
                    placeholder="Paste here"
                    disabled={input_course_description ? true : false}
                    size="md"
                    noOfLines={50}
                  />
                </FormControl>
                <Button
                  mt={'2'}
                  rounded={'full'}
                  px={6}
                  colorScheme={'orange'}
                  bg={'#68c2e8'}
                  _hover={{ bg: '#0e1e40' }}
                  onClick={submitText}
                  disabled={input_button ? true : false}
                >
                  Evaluate
                </Button>

                {showbadgelist ? (
                  <Card mt={10} pt={5} pb={5} variant="outline">
                    <React.Fragment>
                      <CardHeader>
                        <Flex alignItems={'center'} justifyContent={'center'}>
                          <Heading fontWeight={600} fontSize={{ base: 'lg', sm: 'lg', md: '2xl' }} maxW={'3xl'} textAlign="center">
                            Get Results by posting your course description
                          </Heading>
                        </Flex>
                      </CardHeader>
                      <CardBody>
                        <Flex alignItems={'center'} justifyContent={'center'}>
                          <Img src={Mascot} width={200} />
                        </Flex>
                      </CardBody>
                    </React.Fragment>
                    {loading && (
                      <Flex alignItems={'center'} justifyContent={'center'}>
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#68c2e8" size="xl" />
                      </Flex>
                    )}
                  </Card>
                ) : (
                  <Card mt={10} pt={5} pb={5} variant="outline">
                    <React.Fragment>
                      <CardHeader>
                        <Flex alignItems={'center'} justifyContent={'center'}>
                          <Heading fontWeight={600} fontSize={{ base: 'lg', sm: 'lg', md: '2xl' }} maxW={'3xl'} textAlign="center" color={"#195389"}>
                            <span style={{ color: "#195389" }}>
                              <u>
                                <u>
                                  RESULT
                                </u>
                              </u>
                            </span>
                          </Heading>
                        </Flex>

                        <Flex alignItems={'center'} justifyContent={'start'}>
                          <Heading fontWeight={500} fontSize={{ base: 'lg', sm: 'lg', md: '1xl' }} maxW={'3xl'} textAlign="left" display="Flex">
                            <img src={bllet_point} width={20} alt="" /> Complexity: <span style={{ color: "#195389" }}>
                              {complexity !== null ? (
                                <p>{complexity}</p>
                              ) : null}
                            </span>
                          </Heading>
                        </Flex>
                        <br />
                        <Flex alignItems={'center'} justifyContent={'start'}>
                          <Heading fontWeight={500} fontSize={{ base: 'lg', sm: 'lg', md: '1xl' }} maxW={'3xl'} textAlign="left" display="Flex">
                            <img src={bllet_point} width={20} alt="" /> Skills
                          </Heading>
                        </Flex>

                      </CardHeader>
                      <CardBody>
                        <Flex alignItems={'center'} justifyContent={'center'}>
                          <div className='main_skills_div'>
                            <div className='main_show_skills_div'>
                              {/* {skills.map((item, index) => (
                                <div key={index} className='show_skills_div'>
                                  <img src={tick_arrow} alt="" />
                                  <p>{item}</p>
                                </div>
                              ))} */}

                              {skills.length === 0 ? (
                                <h1>No skills evaluated</h1>
                              ) : (
                                skills.map((item, index) => (
                                  // Check if item is not null or undefined before rendering the div
                                  item != null && (
                                    <div key={index} className='show_skills_div'>
                                      <img src={tick_arrow} alt="" />
                                      <p>{item}</p>
                                    </div>
                                  )
                                ))
                              )}

                            </div>
                            <div className='main_add_badge_button'>
                              <Button mt={'2'} rounded={'full'} px={6} colorScheme={'orange'} bg={'#68c2e8'} _hover={{ bg: '#0e1e40' }}
                                onClick={addtextBadge} disabled={loading}>
                                Add Badge To Profile!
                              </Button>
                            </div>
                          </div>
                        </Flex>
                      </CardBody>
                    </React.Fragment>
                    {loading && (
                      <Flex alignItems={'center'} justifyContent={'center'}>
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#68c2e8" size="xl" />
                      </Flex>
                    )}
                  </Card>
                )}

              </TabPanel>
              {/* Text Upload End Here */}
            </TabPanels>
          </Tabs>
        </Container>
      </React.Fragment>
    </div>
  )
}
