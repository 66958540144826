import { AxiosClient } from '../client'

interface ILoginBody {
  email: string
  password: string
}

interface Iemail_verify {
  email: string
}

interface events_body {
  email: any;
  client_user_agent: string;
}

interface Iverification_code {
  email: string
  auth_code: string
}

interface ForgotBody {
  email: string
}

interface Iforgot_password {
  email: string
}

interface IRegisterBody {
  username: string
  email: string
  dob: number
  password: string
}

interface Inew_password {
  email: string
  password: string
}


export class UserAuth {
  static login(body: ILoginBody) {
    return AxiosClient.post('auth/login', body)
  }

  static forgot(body: ForgotBody) {
    return AxiosClient.post('auth/login', body)
  }

  static register(body: IRegisterBody) {
    return AxiosClient.post('auth/register', body)
  }

  static email_verify(body: Iemail_verify) {
    return AxiosClient.post('email/verify', body)
  }

  static events(body: events_body) {
    return AxiosClient.post('events/registration-complete', body)
  }

  static forgot_password(body: Iforgot_password) {
    return AxiosClient.post('email/verify-code', body)
  }

  static new_password(body: Inew_password) {
    return AxiosClient.post('service/password-reset', body)
  }

  static verification_code(body: Iverification_code) {
    return AxiosClient.patch('service/auth-code-verify', body)
  }

  static getdescription(body: any) {
    return AxiosClient.post('evaluation/type/text', body);
  }

  static badge_create(body: any) {
    return AxiosClient.post('badge/create', body);
  }

  static isValidUsername(username: string | undefined) {
    if (!username) {
      return;
    }

    return AxiosClient.get(`auth/valid-username/${username}`)
  }
}
