import * as React from 'react'
import { ChakraProvider, theme } from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import { Header } from './components/layout/header'
import Footer from './components/layout/footer'
import { Landing } from './components/landing'
import About from './components/About'
import Faq from './components/Faq'
import { Route, Routes } from 'react-router-dom'
import Login from './pages/login'
import { Equivalence } from './pages/equivalence'
import Dashboard from './pages/dashboard'
import Profile from './pages/profile'
import Register from './pages/register'
import Settings from './pages/settings'
import History from './pages/history'
import ConfirmBadge from './pages/confirm-badge'
import { Error_404_page } from './pages/Error_404_page/error_404_page'
import { Contact_us } from './pages/Contact_Us/contact_us'
import { Blog_page } from './pages/Blog_page/blog_page'
import { Certificate } from './pages/Certificate/certificate'
import { Blog_review_1 } from './pages/Blog_review_1/blog_review_1'
import { Blog_review_2 } from './pages/Blog_review_2/blog_review_2'
import { Blog_review_3 } from './pages/Blog_review_3/blog_review_3'
import { Blog_review_4 } from './pages/Blog_review_4/blog_review_4'
import { Blog_review_5 } from './pages/Blog_review_5/blog_review_5'
import Forgot from './pages/forgot_password'
import Verification from './pages/forgot_password/verification'
import New_password from './pages/forgot_password/new_password'
import { Account_activate } from './pages/Account_activate/Account_activate'
import axios from 'axios'
import { Upload_certificate } from './pages/Upload-certificate/Upload_certificate'
import { User_Guide } from './pages/User_Guide/User_Guide'
import Chat from './pages/Chat/Chat'
import { Privacy_Policy } from './pages/Privacy_Policy/Privacy_Policy'
import { Accreditation_page } from './pages/Accreditation_page/Accreditation_page'
import { Blog_review_6 } from './pages/Blog_review_6/blog_review_6'
import { Blog_review_7 } from './pages/Blog_review_7/blog_review_7';


export const App = () => {

  // React.useEffect(() => {
  //   // Create a script element
  //   const script = document.createElement('script');

  //   // Set the attributes for the Tawk.to script
  //   script.async = true;
  //   script.src = 'https://embed.tawk.to/6574492070c9f2407f7dcb1d/1hh73rakd';
  //   script.charset = 'UTF-8';
  //   script.setAttribute('crossorigin', '*');

  //   // Append the script to the body
  //   document.body.appendChild(script);

  //   // Clean up the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []); // The empty dependency array ensures the effect runs only once after the component mounts

  const token = localStorage.getItem('access-token')
  const [shouldHideHeaderFooter, setShouldHideHeaderFooter] = React.useState(false);

  return (
    <ChakraProvider theme={theme}>
      {!shouldHideHeaderFooter && <Header auth={token ? true : false} />}
      {token ? (
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/equivalence" element={<Equivalence />} />
          <Route path="/Verify-Certificate" element={<Upload_certificate />} />
          <Route path="/settings" element={<Settings setShouldHideHeaderFooter={setShouldHideHeaderFooter} />} />
          {/* <Route path="/profile" element={<Profile />} /> */}
          <Route path="/history" element={<History />} />
          <Route path='/confirm-badge' element={<ConfirmBadge />} />
          <Route path="/Certificate" element={<Certificate />} />
          <Route path="/Account-Activate" element={<Account_activate />} />

          <Route path="/" element={<Landing auth={token ? true : false} />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/accreditation" element={<Accreditation_page />} />
          <Route path="/Contact-us" element={<Contact_us />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/Blogs" element={<Blog_page />} />
          <Route path="/Blog-review-1" element={<Blog_review_1 />} />
          <Route path="/Blog-review-2" element={<Blog_review_2 />} />
          <Route path="/Blog-review-3" element={<Blog_review_3 />} />
          <Route path="/Blog-review-4" element={<Blog_review_4 />} />
          <Route path="/Blog-review-5" element={<Blog_review_5 />} />
          <Route path="/Blog-review-6" element={<Blog_review_6 />} />
          <Route path="/Blog-review-7" element={<Blog_review_7 />} />
          <Route path="/User-Guide" element={<User_Guide />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Privacy-Policy" element={<Privacy_Policy />} />
          <Route path="*" element={<Error_404_page />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Settings setShouldHideHeaderFooter={setShouldHideHeaderFooter} />} />
          <Route path="/Account-Activate" element={<Account_activate />} />

          <Route path="/" element={<Landing auth={token ? true : false} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/Verification" element={<Verification />} />
          <Route path="/New_password" element={<New_password />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/accreditation" element={<Accreditation_page />} />
          <Route path="/Contact-us" element={<Contact_us />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/Blogs" element={<Blog_page />} />
          <Route path="/Blog-review-1" element={<Blog_review_1 />} />
          <Route path="/Blog-review-2" element={<Blog_review_2 />} />
          <Route path="/Blog-review-3" element={<Blog_review_3 />} />
          <Route path="/Blog-review-4" element={<Blog_review_4 />} />
          <Route path="/Blog-review-5" element={<Blog_review_5 />} />
          <Route path="/Blog-review-6" element={<Blog_review_6 />} />
          <Route path="/Blog-review-7" element={<Blog_review_7 />} />
          <Route path="/Privacy-Policy" element={<Privacy_Policy />} />
          <Route path="/User-Guide" element={<User_Guide />} />
          <Route path="*" element={<Error_404_page />} />
        </Routes>
      )}
      {!shouldHideHeaderFooter && <Footer />}
      <Chat />
    </ChakraProvider>
  )

}
